import React, {useEffect, useState} from 'react'
import styles from '../../styles/Vacancies/VacanciesMain.module.scss'
import {Button, Form, Select} from "antd";
import {isEmpty} from "../../helpers";
import {useForm} from "antd/es/form/Form";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {getAllCompanies} from "../../redux/actions/CompaniesActions";
import {getAllDepartments} from "../../redux/actions/DepartmentsActions";

const VacanciesFilter = ({...props}) => {

    const [filterForm] = useForm()
    const dispatch = useAppDispatch()

    const {currentWorkspace} = useAppSelector(state => state.workspacesReducer)
    const {companies} = useAppSelector(state => state.companiesReducer)
    const {departments} = useAppSelector(state => state.departmentsReducer)

    const [selectedCompany, setSelectedCompany] = useState(null)

    useEffect(() => {
        if (props.resetFilter) {
            filterForm.resetFields()
        }
    }, [props.resetFilter])

    const onFilterSearch = (values: any) => {
        const filterParams = Object.fromEntries(Object.entries(values)
            .filter(([_, v]) => v !== undefined && v !== null)
        )
        // @ts-ignore
        if (!isEmpty(filterParams)) {
            const str = Object.fromEntries(Object.entries(filterParams)
                // @ts-ignore
                .map(([_, v]) => {
                    if (_.includes('Utc')) {
                        // @ts-ignore
                        return [_, v.toISOString()]
                    }
                    // @ts-ignore
                    else return [_, v.toString()]
                })
            )
            props.onFilterChange(str)
        }
    }

    const onFilterFormReset = () => {
        filterForm.resetFields()
        setSelectedCompany(null)
        props.onFilterChange({})
    }

    useEffect(() => {
        dispatch(getAllCompanies({pageNumber: 1, workspaceId: currentWorkspace?.id}))
    }, [])

    useEffect(() => {
        if (selectedCompany) {
            dispatch(getAllDepartments({
                workspaceId: currentWorkspace?.id,
                companyId: selectedCompany,
                pageNumber: 1
            }))
        }
    }, [selectedCompany]);


    return (
        <div className={styles.filterContainer + ` ${!props.showFilter ? styles.filterActive : ''}`}>
            <span className={styles.filterTitle}>Фильтры</span>

            <Form
                form={filterForm}
                layout='vertical'
                autoComplete='off'
                onFinish={(values) => onFilterSearch(values)}
                className={styles.filterForm}
            >
                <Form.Item
                    label={<span className='inputLabel'>Компания</span>}
                    name="CompanyId"
                >
                    <Select
                        showSearch
                        allowClear
                        optionFilterProp="label"
                        options={companies?.items?.map((item: any) => ({
                        value: item?.id,
                        label: item?.name
                    }))}
                            value={selectedCompany}
                            onChange={(value) => {
                                setSelectedCompany(value)
                            }}
                        filterOption={(input, option) =>
                            //@ts-ignore
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                    />
                </Form.Item>

                <Form.Item
                    label={<span className='inputLabel'>Отдел</span>}
                    name="DepartmentId"
                >
                    <Select
                        showSearch
                        allowClear
                        optionFilterProp="label"
                        options={departments?.items?.map((item: any) => ({
                        value: item?.id,
                        label: item?.name
                    }))}
                            disabled={!selectedCompany}
                            filterOption={(input, option) =>
                                //@ts-ignore
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                    />
                </Form.Item>

                <Form.Item
                    label={<span className='inputLabel'>Статус</span>}
                    name="VacancyStatuses"
                >
                    <Select
                        allowClear
                        mode="multiple"
                        optionFilterProp="label"
                        options={[
                            {value: 1, label: 'Открыто'},
                            {value: 2, label: 'На паузе'},
                            {value: 3, label: 'Завершено'},
                            {value: 4, label: 'Истек срок'},
                        ]}
                        filterOption={(input, option) =>
                            //@ts-ignore
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                    />
                </Form.Item>

                {/*<Form.Item*/}
                {/*    label={<span className='inputLabel'>Специализация</span>}*/}
                {/*    name="IndustryDirectionId"*/}
                {/*>*/}
                {/*    <Input disabled/>*/}
                {/*</Form.Item>*/}

                <div className={styles.formRow}>
                    <Form.Item>
                        <Button
                            onClick={() => onFilterFormReset()}
                        >
                            Сбросить
                        </Button>
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className={styles.loginButton}
                            // disabled={!selectedCompany}
                        >
                            Применить
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </div>
    )
}

export default VacanciesFilter
