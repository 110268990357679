import React from 'react'
import {Outlet} from "react-router-dom";
import styles from '../../styles/AuthLayout.module.css'
import {ConfigProvider} from "antd";
import ruRU from 'antd/es/locale/ru_RU'

const AuthLayout = () => {
    return (
        <ConfigProvider
            locale={ruRU}
            theme={{
                token: {
                    fontFamily: "Montserrat, sans-serif",
                    colorText: "#222222",
                },
                components: {
                    Input: {
                        borderRadius: 8,
                        colorBorder: "#AFB1B6",
                        colorPrimaryHover: "#4365E1",
                        hoverBorderColor: "#4365E1",
                        activeBorderColor: "#4365E1",
                        paddingBlock: 11,
                        fontSize: 16,
                        algorithm: true,
                    },
                    Button: {
                        colorPrimary: "#4365E1",
                        borderRadius: 5,
                        paddingBlock: 12,
                        controlHeight: 44,
                        fontSize: 16,
                        fontWeight: 500,
                        lineHeight: 20,
                        algorithm: true
                    },
                    Checkbox: {
                        colorPrimary: "#4365E1",
                        borderRadius: 4,
                        algorithm: true
                    }
                }
            }}
        >
            <div className={styles.container}>
                <div className={styles.innerContainer}>
                    <Outlet />
                </div>
            </div>
        </ConfigProvider>
    )
}

export default AuthLayout
