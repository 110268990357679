// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TaskPage_container__buV6v {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 64px 32px;
  max-width: 1620px;
  width: 100%;
  margin: 0 auto;
}

.TaskPage_titleContainer__LAQ4G {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.TaskPage_titleContainer__LAQ4G .TaskPage_titleInnerContainer__sJ24O {
  display: flex;
  gap: 8px;
  align-items: center;
}
.TaskPage_titleContainer__LAQ4G .TaskPage_titleInnerContainer__sJ24O h3 {
  font-size: 24px;
}
.TaskPage_titleContainer__LAQ4G .TaskPage_titleInnerContainer__sJ24O img {
  width: 28px;
  height: 28px;
}

.TaskPage_actionsContainer__IZTjq {
  display: flex;
  flex-wrap: nowrap;
  gap: 12px;
}
.TaskPage_actionsContainer__IZTjq img {
  width: 20px;
  height: 20px;
}

.TaskPage_tasksListSpinner__gVhfZ {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/styles/Tasks/TaskPage.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,kBAAA;EACA,iBAAA;EACA,WAAA;EACA,cAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,8BAAA;AACF;AACE;EACE,aAAA;EACA,QAAA;EACA,mBAAA;AACJ;AACI;EACE,eAAA;AACN;AAEI;EACE,WAAA;EACA,YAAA;AAAN;;AAKA;EACE,aAAA;EACA,iBAAA;EACA,SAAA;AAFF;AAIE;EACE,WAAA;EACA,YAAA;AAFJ;;AAMA;EACE,WAAA;AAHF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 32px;\n  padding: 64px 32px;\n  max-width: 1620px;\n  width: 100%;\n  margin: 0 auto;\n}\n\n.titleContainer {\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  justify-content: space-between;\n\n  .titleInnerContainer {\n    display: flex;\n    gap: 8px;\n    align-items: center;\n\n    h3 {\n      font-size: 24px;\n    }\n\n    img {\n      width: 28px;\n      height: 28px;\n    }\n  }\n}\n\n.actionsContainer {\n  display: flex;\n  flex-wrap: nowrap;\n  gap: 12px;\n\n  img {\n    width: 20px;\n    height: 20px;\n  }\n}\n\n.tasksListSpinner {\n  width: 100%\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `TaskPage_container__buV6v`,
	"titleContainer": `TaskPage_titleContainer__LAQ4G`,
	"titleInnerContainer": `TaskPage_titleInnerContainer__sJ24O`,
	"actionsContainer": `TaskPage_actionsContainer__IZTjq`,
	"tasksListSpinner": `TaskPage_tasksListSpinner__gVhfZ`
};
export default ___CSS_LOADER_EXPORT___;
