import {IRegisterUser} from "../../Components/models/IRegisterUser";
import {createSlice} from "@reduxjs/toolkit";

interface AuthSlice {
    registerData: IRegisterUser;
    passwordResetData: any,
    isLoading: boolean;
    isTokenPending: boolean
    error: any;
    success: any;
    loginError: any;
    isRegistered: boolean;
    isPasswordResetted: boolean;
    user: any,
    userInfo: any
}


const initialState: AuthSlice = {
    registerData: {},
    // @ts-ignore
    passwordResetData: JSON.parse(localStorage.getItem('registerUserData'))?.passwordResetData,
    isLoading: false,
    isTokenPending: false,
    error: null,
    success: null,
    loginError: null,
    isRegistered: false,
    isPasswordResetted: false,
    // @ts-ignore
    user: JSON.parse(localStorage.getItem("user") || null),
    // @ts-ignore
    userInfo: JSON.parse(localStorage.getItem("userInfo") || null)
}

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {

        isFetching: (state) => {
            state.isLoading = true
            state.loginError = null
            state.error = null
            state.success = null
        },

        isLoginFailure: (state, action) => {
            state.isLoading = false;
            state.loginError = action.payload;
        },

        isFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload
            state.loginError = action.payload;
        },
        isSuccess: (state, action) => {
          state.isLoading = false
          state.success = action.payload
        },
        clearSuccess: (state) => {
          state.success = null
        },
        addResetPasswordData: (state, action) => {
            state.passwordResetData = action.payload
        },
        passwordResetFetching: (state) => {
            state.isLoading = true;
            state.error = null
        },
        passwordResetSuccess: (state, action) => {
            state.isLoading = false;
            state.success = action.payload
            state.passwordResetData = {}
            state.isTokenPending = false
            state.isPasswordResetted = true

        },
        passwordResetFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        addRegisterData: (state, action) => {
            state.registerData = action.payload
        },
        sendConfirmationTokenFetching: (state) => {
            state.isLoading = true
            state.error = null
        },
        sendConfirmationTokenSuccess: (state, action) => {
            state.isLoading = false;
            state.isTokenPending = true;
            state.success = action.payload;
            localStorage.setItem('registerUserData', JSON.stringify(state))
        },
        sendConfirmationTokenFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
            state.registerData = {}
        },

        registerUserFetching: (state) => {
            state.isLoading = true;
            state.error = null
        },

        registerUserSuccess: (state) => {
            state.isLoading = false;
            state.registerData = {}
            state.isTokenPending = false
            state.isRegistered = true
        },

        registerUserFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },

        setCredentialsSuccess: (state, action) => {
            state.isLoading = false;
            state.user = action.payload
            localStorage.setItem("user", JSON.stringify(action.payload))
            localStorage.setItem("access_token", action.payload.access_token)
            localStorage.setItem("refresh_token", JSON.stringify(action.payload.refresh_token))
        },

        setCredentialsFailure: (state, action: { payload: string }) => {
            state.isLoading = false;
            state.loginError = action.payload;
        },
        removeCredentials: (state) => {
            state.isLoading = false;
            state.user = null
            state.userInfo = null
            localStorage.removeItem("user")
            localStorage.removeItem("userInfo")
            localStorage.removeItem("currentWorkspace")
            localStorage.removeItem("access_token")
            localStorage.removeItem("refresh_token")
        },
        setUserInfo: (state, action) => {
            state.isLoading = false;
            state.userInfo = action.payload
            localStorage.setItem("userInfo", JSON.stringify(action.payload))
        }
    }
})

export default authSlice.reducer
