import {createSlice} from "@reduxjs/toolkit";

interface VacanciesSlice {
    vacancies: any;
    currentVacancy: any;
    isLoading: boolean;
    error: any;
    success: any;
}

const initialState: VacanciesSlice = {
    vacancies: [],
    currentVacancy: null,
    isLoading: false,
    error: null,
    success: null
}

export const vacanciesSlice = createSlice({
    name: "vacancies",
    initialState,
    reducers: {
        isLoading: (state) => {
            state.isLoading = true
            state.error = null
            state.success = null
            state.currentVacancy = null
            state.vacancies = null
        },
        isFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload
        },
        isSuccess: (state, action) => {
            state.isLoading = false
            state.success = action.payload
        },

        getVacancies: (state, action) => {
            state.isLoading = false;
            state.vacancies = action.payload
        },
        setCurrentVacancy: (state, action) => {
            state.isLoading = false;
            state.currentVacancy = action.payload
        }
    }
})

export default vacanciesSlice.reducer
