import React, {useEffect, useState} from 'react';
import styles from '../../../styles/Tasks/TasksMain.module.scss'
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {Dropdown, Empty, MenuProps, message, Modal, Pagination, Spin} from "antd";
import {getInvites, resendInvite, revokeInvite} from "../../../redux/actions/ParticipantsActions";
import {formatDate} from "../../../helpers";
import {CloseOutlined, DownOutlined, RedoOutlined} from "@ant-design/icons";

const InvitesList = () => {

    const dispatch = useAppDispatch()
    const [messageApi, contextHolder] = message.useMessage();
    const {currentWorkspace} = useAppSelector(state => state.workspacesReducer)
    const {invites, error, success, isLoading} = useAppSelector(state => state.participantsReducer)
    const [isRevokeInviteModal, setIsRevokeInviteModal] = useState({open: false, id: null})
    const [isResendInviteModal, setIsResendInviteModal] = useState({open: false, id: null})

    const invitesActions: MenuProps['items'] = [
        {
            label: <span className={styles.actionItem}><RedoOutlined/>Переотправить</span>,
            key: '1',
        },
        {
            label: <span className={styles.actionItem}><CloseOutlined/>Отменить приглашение</span>,
            key: '2',
        },
    ];

    const handleActions = (key: any, id: any) => {
        switch (key) {
            case '1':
                setIsResendInviteModal({open: true, id: id})
                break;
            case '2':
                setIsRevokeInviteModal({open: true, id: id})
                break;
            default:
                break;
        }
    }

    const onPageNumberChange = (value: any) => {
        dispatch(getInvites({workspaceId: currentWorkspace?.id, pageNumber: value}))
    }

    useEffect(() => {
        dispatch(getInvites({workspaceId: currentWorkspace?.id}))
    }, []);

    useEffect(() => {
        if (error) {
            messageApi.open({
                type: 'error',
                content: error,
            });
        }

        if (success) {
            messageApi.open({
                type: 'success',
                content: success,
            });
        }
    }, [error, success])

    return (
        <>
            {contextHolder}
            <Spin spinning={isLoading} wrapperClassName={styles.tasksListSpinner}>
                <div className={styles.listContainer}>
                    <div className={styles.listInnerContainer}>
                        {
                            invites?.items?.length !== 0 ?
                                <>
                                    {
                                        invites?.items?.filter((item: any) => item.acceptedAtUtc === null).map((item: any) => (
                                            <div className={styles.listItem} key={item?.id}>
                                                <div className={styles.listItemHeading}>
                                                    <div className={styles.listItemTitleContainer}>
                                                        <div>{item?.emailAddress?.address}</div>
                                                    </div>

                                                    <div className={styles.listItemActionsContainer} style={{alignItems: "flex-start", gap: "24px"}}>
                                                        <div className={styles.listItemDate}>
                                                            <span>Создано: {formatDate(item?.createdAtUtc)}</span>
                                                            <span>Истекает: {formatDate(item?.expiredAtUtc)}</span>
                                                        </div>

                                                        <div className={styles.listItemActions}>
                                                            {/*@ts-ignore*/}
                                                            <Dropdown menu={{
                                                                items: invitesActions, onClick: ({key}) => {
                                                                    handleActions(key, item?.id)
                                                                }
                                                            }}>
                                                                <a onClick={(e) => {
                                                                    e.preventDefault()
                                                                    e.stopPropagation()

                                                                }}>
                                                                    Действия
                                                                    <DownOutlined/>
                                                                </a>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={styles.listItemBody}>
                                                    <div className={styles.listItemInfoContainer}>
                                                        {/*{item?.createdBy?.user?.fullName &&*/}
                                                        {/*    <div className={styles.listItemInfoItem}>*/}
                                                        {/*        <img src="/mail_icon.svg" alt="Mail"/>*/}
                                                        {/*        <span>{`${item?.createdBy?.user?.fullName?.last} ${item?.createdBy?.user?.fullName?.first}`}</span>*/}
                                                        {/*    </div>*/}
                                                        {/*}*/}


                                                        {item?.comment &&
                                                            <div className={styles.listItemInfoItem}>
                                                                <span>{item?.comment}</span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                    {
                                        invites?.totalCount > 8 &&
                                        <Pagination
                                            onChange={onPageNumberChange}
                                            defaultPageSize={8}
                                            current={invites?.pageNumber}
                                            total={invites?.totalCount}
                                        />
                                    }
                                </> :
                                <div>
                                    <Empty description='Приглашения отсутствуют'/>
                                </div>
                        }

                    </div>
                </div>
            </Spin>


            <Modal
                title='Подтверждение операции'
                open={isResendInviteModal.open}
                okText="Да"
                cancelText="Отмена"

                onOk={() => {
                    dispatch(resendInvite({
                        inviteId: isResendInviteModal.id
                    }))
                        .then(() => dispatch(getInvites({workspaceId: currentWorkspace?.id})))
                        .then(() => setIsResendInviteModal(() => ({open: false, id: null})))
                }}
                onCancel={() => setIsResendInviteModal(() => ({open: false, id: null}))}
            >
                Вы уверены, что хотите переотправить запрос на добавление в рабочее пространство?
            </Modal>


            <Modal
                title='Подтверждение операции'
                open={isRevokeInviteModal.open}
                okText="Да"
                cancelText="Отмена"

                onOk={() => {
                    dispatch(revokeInvite({
                        inviteId: isRevokeInviteModal.id
                    }))
                        .then(() => dispatch(getInvites({workspaceId: currentWorkspace?.id})))
                        .then(() => setIsRevokeInviteModal(() => ({open: false, id: null})))
                }}
                onCancel={() => setIsRevokeInviteModal(() => ({open: false, id: null}))}
            >
                Вы уверены, что хотите отменить приглашение в рабочее пространство?
            </Modal>
        </>
    )

}

export default InvitesList
