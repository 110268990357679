import React from 'react';

function App() {
  return (
    <div className="App" style={{padding: "32px"}}>
      Главная страница
    </div>
  );
}

export default App;
