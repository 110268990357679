import {AppDispatch} from "../index";
import {getErrorMessage} from "../../helpers";
import {vacanciesSlice} from "../reducers/vacanciesSlice";
import {IVacancy} from "../../Components/models/IVacancy";
import api from "../../helpers/api";

export const getAllVacancies = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(vacanciesSlice.actions.isLoading())
        let params = new URLSearchParams(data)

        if (!!data.VacancyStatuses) {
            data = {...data, VacancyStatuses: data.VacancyStatuses.split(',')}
            params.delete('VacancyStatuses')

            data.VacancyStatuses.map((item: string) => {
                params.append('VacancyStatuses', item)
            })

            console.log(params)
        }

        const response = await api.get(`${process.env.REACT_APP_API_URI_HTTPS}/api/vacancies`, {
            params: params
        })

        if (response.data.succeeded) {
            dispatch(vacanciesSlice.actions.getVacancies(response.data.value))
        } else {
            dispatch(vacanciesSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(vacanciesSlice.actions.isFailure(message))
    }
}

export const getFilteredVacancies = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(vacanciesSlice.actions.isLoading())

        const response = await api.get(`${process.env.REACT_APP_API_URI_HTTPS}/api/vacancies?WorkspaceId=${data.workspaceId}&SearchString=${data.searchString}&PageNumber=${data.pageNumber}&PageSize=${data.pageSize}`)

        if (response.data.succeeded) {
            dispatch(vacanciesSlice.actions.getVacancies(response.data.value))
        } else {
            dispatch(vacanciesSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(vacanciesSlice.actions.isFailure(message))
    }
}

export const createVacancy = (vacancy: IVacancy) => async (dispatch: AppDispatch) => {
    try {
        dispatch(vacanciesSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/vacancies/create`, vacancy)

        if (response.data.succeeded) {
            dispatch(vacanciesSlice.actions.isSuccess("Вакансия успешно создана"))
        } else {
            dispatch(vacanciesSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(vacanciesSlice.actions.isFailure(message))
    }
}

export const updateVacancy = (vacancy: IVacancy) => async (dispatch: AppDispatch) => {
    try {
        dispatch(vacanciesSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/vacancies/update`, vacancy)

        if (response.data.succeeded) {
            dispatch(vacanciesSlice.actions.isSuccess("Вакансия успешно изменена"))
        } else {
            dispatch(vacanciesSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(vacanciesSlice.actions.isFailure(message))
    }
}

export const completeVacancy = (data: { vacancyStatus: number; workspaceId: any; vacancyId: null }) => async (dispatch: AppDispatch) => {
    try {
        dispatch(vacanciesSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/vacancies/update-status`, data)

        if (response.data.succeeded) {
            dispatch(vacanciesSlice.actions.isSuccess("Вакансия успешно закрыта"))
        } else {
            dispatch(vacanciesSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(vacanciesSlice.actions.isFailure(message))
    }
}

export const archiveVacancy = (data: {
    workspaceId: any;
    vacancyId: null | undefined
}) => async (dispatch: AppDispatch) => {
    try {
        dispatch(vacanciesSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/vacancies/archive`, data)

        if (response.data.succeeded) {
            dispatch(vacanciesSlice.actions.isSuccess("Вакансия успешно архивирована"))
        } else {
            dispatch(vacanciesSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(vacanciesSlice.actions.isFailure(message))
    }
}

export const getVacancyById = (data: {workspaceId: string, vacancyId: string}) => async (dispatch: AppDispatch) => {
    try {
        dispatch(vacanciesSlice.actions.isLoading())

        const response = await api.get(`${process.env.REACT_APP_API_URI_HTTPS}/api/vacancies/details?VacancyId=${data.vacancyId}&WorkspaceId=${data.workspaceId}`)


        if (response.data.succeeded) {
            dispatch(vacanciesSlice.actions.setCurrentVacancy(response.data.value))
        } else {
            dispatch(vacanciesSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(vacanciesSlice.actions.isFailure(message))
    }
}
