import React from 'react'
import styles from "../../styles/WorkspaceListItemBig.module.scss";
import {colors} from "../../const/colors";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {setCurrentWorkspace} from "../../redux/actions/WorkspaceActions";
import {getUserInfo} from "../../redux/actions/AuthActions";

type itemProps = {
    id: string,
    name: string
}

const WorkspaceListItemBig = ({id, name}:itemProps) => {

    const {user} = useAppSelector(state => state.authReducer)
    const navigate = useNavigate()
    const dispatch = useAppDispatch();

    return (
        <div className={styles.workspaceItem} key={id} onClick={() => {
            dispatch(setCurrentWorkspace(id))
                .then(() => dispatch(getUserInfo(user?.access_token)))
                .then(() => navigate("/") )

        }}>
            <div className={styles.workspaceImgContainer}>
                <div style={{background: `${colors[0]}`}} className={styles.workspaceBg}/>
                <img src="/workspace_img_figures.svg" alt=""/>
                <span>ЭЦ</span>
            </div>

            <span>{name}</span>
        </div>
    )
}

export default WorkspaceListItemBig
