import {AppDispatch} from "../index";
import {getErrorMessage} from "../../helpers";
import {participantsSlice} from "../reducers/participantsSlice";
import api from "../../helpers/api";

export const getParticipants = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(participantsSlice.actions.isLoading())
        const response = await api.get(`${process.env.REACT_APP_API_URI_HTTPS}/api/participations/workspace/list?WorkspaceId=${data.workspaceId}&isActive=true&PageNumber=${data.pageNumber}&PageSize=8`)
        if (response.data.succeeded) {
            dispatch(participantsSlice.actions.getParticipants(response.data.value))
            return response.data.value.items
        } else {
            dispatch(participantsSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }

    } catch (error: any) {
        const message = getErrorMessage(error)
        dispatch(participantsSlice.actions.isFailure(message))
    }
}

export const getParticipantsList = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(participantsSlice.actions.isLoading())
        const response = await api.get(`${process.env.REACT_APP_API_URI_HTTPS}/api/participations/workspace/list?WorkspaceId=${data.workspaceId}&isActive=true&PageNumber=1&PageSize=100`)
        if (response.data.succeeded) {
            dispatch(participantsSlice.actions.getParticipants(response.data.value))
            return response.data.value.items
        } else {
            dispatch(participantsSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }

    } catch (error: any) {
        const message = getErrorMessage(error)
        dispatch(participantsSlice.actions.isFailure(message))
    }
}

export const inviteNewUser = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(participantsSlice.actions.isLoading())
        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/invites/create`, data)
        if (response.data.succeeded) {
            dispatch(participantsSlice.actions.isSuccess('Пользователю отправлено приглашение'))
        } else {
            dispatch(participantsSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error: any) {
        const message = getErrorMessage(error)
        dispatch(participantsSlice.actions.isFailure(message))
    }
}

export const addUserToWorkspace = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(participantsSlice.actions.isLoading())
        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/participations/workspace/add`, data)
        if (response.data.succeeded) {
            dispatch(participantsSlice.actions.isSuccess('Пользователь добавлен к рабочему пространству'))
        } else {
            dispatch(participantsSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error: any) {
        const message = getErrorMessage(error)
        dispatch(participantsSlice.actions.isFailure(message))
    }
}

export const getRequests = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(participantsSlice.actions.isLoading())
        const response = await api.get(`${process.env.REACT_APP_API_URI_HTTPS}/api/requests/workspace/list?WorkspaceId=${data.workspaceId}&PageNumber=1&PageSize=100`)
        if (response.data.succeeded) {
            dispatch(participantsSlice.actions.getRequests(response.data.value))
        } else {
            dispatch(participantsSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error: any) {
        const message = getErrorMessage(error)
        dispatch(participantsSlice.actions.isFailure(message))
    }
}


export const processRequest = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(participantsSlice.actions.isLoading())
        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/requests/process`, data)
        if (response.data.succeeded) {
            dispatch(participantsSlice.actions.isSuccess('Пользователь добавлен к рабочему пространству'))
        } else {
            dispatch(participantsSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error: any) {
        const message = getErrorMessage(error)
        dispatch(participantsSlice.actions.isFailure(message))
    }
}

export const revokeRequest = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(participantsSlice.actions.isLoading())
        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/requests/revoke`, data)
        if (response.data.succeeded) {
            dispatch(participantsSlice.actions.isSuccess('Запрос пользователя отклонен'))
        } else {
            dispatch(participantsSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error: any) {
        const message = getErrorMessage(error)
        dispatch(participantsSlice.actions.isFailure(message))
    }
}

export const removeUserFromWorkspace = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(participantsSlice.actions.isLoading())
        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/participations/workspace/remove`, data)
        if (response.data.succeeded) {
            dispatch(participantsSlice.actions.isSuccess('Пользователь удален из рабочего пространства'))
        } else {
            dispatch(participantsSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error: any) {
        const message = getErrorMessage(error)
        dispatch(participantsSlice.actions.isFailure(message))
    }
}


export const getInvites = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(participantsSlice.actions.isLoading())
        const response = await api.get(`${process.env.REACT_APP_API_URI_HTTPS}/api/invites/list?WorkspaceId=${data.workspaceId}&PageNumber=${data?.pageNumber || 1}&PageSize=8`)
        if (response.data.succeeded) {
            dispatch(participantsSlice.actions.getInvites(response.data.value))
        } else {
            dispatch(participantsSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error: any) {
        const message = getErrorMessage(error)
        dispatch(participantsSlice.actions.isFailure(message))
    }
}

export const createInvite = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(participantsSlice.actions.isLoading())
        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/invites/create`, data)
        if (response.data.succeeded) {
            dispatch(participantsSlice.actions.isSuccess('Пользователю отправлено приглашение'))
        } else {
            dispatch(participantsSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error: any) {
        const message = getErrorMessage(error)
        dispatch(participantsSlice.actions.isFailure(message))
    }
}

export const revokeInvite = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(participantsSlice.actions.isLoading())
        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/invites/revoke`, data)
        if (response.data.succeeded) {
            dispatch(participantsSlice.actions.isSuccess('Приглашение отменено'))
        } else {
            dispatch(participantsSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error: any) {
        const message = getErrorMessage(error)
        dispatch(participantsSlice.actions.isFailure(message))
    }
}

export const resendInvite = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(participantsSlice.actions.isLoading())
        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/invites/resend`, data)
        if (response.data.succeeded) {
            dispatch(participantsSlice.actions.isSuccess('Приглашение переотправлено'))
        } else {
            dispatch(participantsSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error: any) {
        const message = getErrorMessage(error)
        dispatch(participantsSlice.actions.isFailure(message))
    }
}
