import React, {cloneElement, useState} from 'react'
import {Dropdown, Input, Menu, MenuProps} from "antd";
import styles from '../../styles/Header.module.scss'
import {BellOutlined, SearchOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {removeCurrentWorkspace} from "../../redux/actions/WorkspaceActions";
import {logout} from "../../redux/actions/AuthActions";

const Header = () => {
    const [current, setCurrent] = useState('mail');
    const itemsMenu: MenuProps['items'] = [
        {
            label: 'Вакансии',
            key: 'vacancies',
        },
        {
            label: 'Кандидаты',
            key: 'candidates',
        },
        {
            label: 'Задания',
            key: 'tasks',
        },
        {
            label: 'Календарь',
            key: 'calendar',
            disabled: true
        },
        {
            label: 'Настройки',
            key: 'settings',
        },
    ];
    const items: MenuProps['items'] = [
        {
            label: (
                <div className={styles.dropdownLabel} onClick={() => {
                    navigate('/profile')
                }}>
                    <img src="/profile_icon.svg" alt="profile"/>
                    <span>Профиль</span>
                </div>
            ),
            key: '1',
        },
        {
            label: (
                <div className={styles.dropdownLabel} onClick={() => {
                    dispatch(removeCurrentWorkspace())
                    navigate("/selectWorkspace")
                }}>
                    <img src="/change_icon.svg" alt="change"/>
                    <span>Сменить рабочее пространство</span>
                </div>
            ),
            key: '2',
        },
        {
            label: (
                <div className={styles.dropdownLabel} onClick={() => {
                    dispatch(logout())
                    navigate('/login')
                }}>
                    <img src="/logout_icon.svg" alt="change"/>
                    <span>Выход</span>
                </div>
            ),
            key: '3',
        }
    ];
    const navigate = useNavigate();

    const {userInfo} = useAppSelector(state => state.authReducer)
    const {currentWorkspace} = useAppSelector(state => state.workspacesReducer);
    const dispatch = useAppDispatch();


    const onClick: MenuProps['onClick'] = (e) => {
        setCurrent(e.key);
        navigate(`/${e.key}`)
    };

    return (
        <div className={styles.container}>
            <div className={styles.containerInner}>
                <img src="/exp_logo_text_sm.svg" alt="logo"/>

                <Input className={styles.input}
                       placeholder='Введите запрос...'
                       prefix={<SearchOutlined width={16} height={16}
                                               style={{color: "#AFB1B6", marginInlineEnd: "8px"}}/>}
                       suffix={<img src="/button_icon.svg" alt="button_icon"/>}
                       variant='borderless'
                />
            </div>

            <div className={styles.containerInner}>
                <Menu onClick={onClick}
                      rootClassName={styles.menu}
                      selectedKeys={[current]}
                      disabledOverflow={true}
                      mode="horizontal" items={itemsMenu}/>

                <div className={styles.profileContainer}>
                    <BellOutlined style={{fontSize: "22px"}}/>
                    <Dropdown
                        arrow={true}
                        menu={{items}}
                        dropdownRender={(menu) => (
                            <div className={styles.dropdownContainer} onClick={(e) => {
                                e.preventDefault()
                            }}>
                                <div className={styles.workspaceContainer}>
                                    <div className={styles.workspaceInnerContainer}>
                                        <img src="/icon_sm_1.svg" alt="icon_sm_1"/>
                                        <span>{currentWorkspace?.name}</span>
                                    </div>

                                    {cloneElement(menu as React.ReactElement, {style: {boxShadow: "none"}})}
                                </div>
                            </div>
                        )}
                    >
                        <div className={styles.profileInnerContainer}>
                            <div className={styles.avatarWrapper}>
                                <img src="/avatar_icon.svg" alt="avatar"/>
                                <div className={styles.namesContainer}>
                                    <span
                                        className={styles.username}>{`${userInfo?.lastName} ${userInfo?.firstName}`}</span>
                                    <span className={styles.workspace}>{currentWorkspace?.name}</span>
                                </div>
                            </div>
                            <img src="/arrow_down.svg" alt="arrow_down"/>
                        </div>
                    </Dropdown>
                </div>
            </div>


        </div>
    )
}

export default Header;
