// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MyJoinRequestsList_container__KYvTN {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 54px;
}
.MyJoinRequestsList_container__KYvTN h2 {
  font-size: 16px;
  font-weight: 600;
}

.MyJoinRequestsList_itemsContainer__ZoeBq {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.MyJoinRequestsList_itemContainer__H4yaE {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
}
.MyJoinRequestsList_itemContainer__H4yaE .MyJoinRequestsList_itemInnerContainer__5diho {
  display: flex;
  gap: 16px;
  align-items: center;
}
.MyJoinRequestsList_itemContainer__H4yaE .MyJoinRequestsList_itemInnerContainer__5diho span {
  font-size: 16px;
  font-weight: 600;
  color: #222222;
}
.MyJoinRequestsList_itemContainer__H4yaE button {
  border: none;
  background-color: transparent;
  color: var(--primary);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/styles/MyJoinRequestsList.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,gBAAA;AACF;AACE;EACE,eAAA;EACA,gBAAA;AACJ;;AAIA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AADF;;AAIA;EACE,WAAA;EACA,aAAA;EACA,8BAAA;EACA,eAAA;AADF;AAGE;EAEE,aAAA;EACA,SAAA;EACA,mBAAA;AAFJ;AAII;EACE,eAAA;EACA,gBAAA;EACA,cAAA;AAFN;AAQE;EACE,YAAA;EACA,6BAAA;EACA,qBAAA;EACA,eAAA;EACA,gBAAA;EACA,eAAA;AANJ","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n  margin-top: 54px;\n\n  h2 {\n    font-size: 16px;\n    font-weight: 600;\n  }\n}\n\n\n.itemsContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n}\n\n.itemContainer {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  padding: 12px 0;\n\n  .itemInnerContainer {\n\n    display: flex;\n    gap: 16px;\n    align-items: center;\n\n    span {\n      font-size: 16px;\n      font-weight: 600;\n      color: #222222;\n    }\n  }\n\n\n\n  button {\n    border: none;\n    background-color: transparent;\n    color: var(--primary);\n    font-size: 14px;\n    font-weight: 500;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MyJoinRequestsList_container__KYvTN`,
	"itemsContainer": `MyJoinRequestsList_itemsContainer__ZoeBq`,
	"itemContainer": `MyJoinRequestsList_itemContainer__H4yaE`,
	"itemInnerContainer": `MyJoinRequestsList_itemInnerContainer__5diho`
};
export default ___CSS_LOADER_EXPORT___;
