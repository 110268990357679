import React, {useEffect, useState} from 'react'
import styles from '../../../styles/Settings/FunnelTemplates/FunnelTemplates.module.scss'
import {Button, Dropdown, Empty, MenuProps, message, Modal, Spin} from "antd";
import {DeleteOutlined, DownOutlined, PlusOutlined} from "@ant-design/icons";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getTemplates, removeTemplate} from "../../../redux/actions/TemplatesActions";
import {useNavigate} from "react-router-dom";


const FunnelTemplatesMain = () => {


    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [messageApi, contextHolder] = message.useMessage();
    const [isDeleteFunnelModalOpen, setIsDeleteFunnelModalOpen] = useState({open: false, funnel: null})

    const {currentWorkspace} = useAppSelector(state => state.workspacesReducer)
    const {templates, isLoading, error, success} = useAppSelector(state => state.templatesReducer)

    const funnelActions: MenuProps['items'] = [
        {
            label: <span className={styles.actionItem}><img src="/edit_icon.svg" alt="edit"/>Редактировать</span>,
            key: '1',
        },
        {
            label: <span className={styles.actionItem}><DeleteOutlined/>Удалить</span>,
            key: '2',
        }
    ];

    const handleActions = (key: any, data?: any) => {
        switch (key) {
            case '1':
                navigate(`/settings/templates/edit/${data?.id}`)
                break;
            case '2':
                setIsDeleteFunnelModalOpen({open: true, funnel: data})
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        dispatch(getTemplates({workspaceId: currentWorkspace?.id}))
    }, [])

    useEffect(() => {
        if (error) {
            messageApi.open({
                type: 'error',
                content: error,
            });
        }

        if (success) {
            messageApi.open({
                type: 'success',
                content: success,
            });
        }
    }, [error, success])

    return (
        <>
            {contextHolder}
            <Spin spinning={isLoading} wrapperClassName={styles.spinnerWrapper}>
                <div className={styles.container}>
                    <div className={styles.listContainer}>
                        <div className={styles.pageTitleWrapper}>
                            <span className={styles.listHeader}>Шаблоны воронок</span>
                            <Button type='primary' icon={<PlusOutlined/>}
                                    onClick={() => navigate('/settings/templates/create')}>Добавить
                                шаблон</Button>
                        </div>
                        <div className={styles.innerListContainer}>
                            {
                                templates?.items?.length !== 0 ?
                                    <>
                                        {
                                            templates?.items?.map((item: any) => (
                                                <div className={styles.listItem} key={item?.id}>
                                                    <div className={styles.listItemHeading}>
                                                        <div className={styles.listItemTitleContainer}
                                                             onClick={() => navigate(`/settings/templates/${item?.id}`)}>
                                                            <div>{item?.name}</div>
                                                            {/*{getGroupStatus(group?.isArchived)}*/}
                                                        </div>

                                                        <div className={styles.listItemActionsContainer}>
                                                            <div className={styles.listItemActions}>
                                                                {/*@ts-ignore*/}
                                                                <Dropdown menu={{
                                                                    items: funnelActions, onClick: ({key}) => {
                                                                        handleActions(key, item)
                                                                    }
                                                                }}>
                                                                    <a onClick={(e) => {
                                                                        e.preventDefault()
                                                                        e.stopPropagation()

                                                                    }}>
                                                                        Действия
                                                                        <DownOutlined/>
                                                                    </a>
                                                                </Dropdown>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={styles.listItemBody}>
                                                        <div className={styles.listItemInfoContainer}>
                                                            <div className={styles.listItemInfoItem}>
                                                                <span>Количество этапов: {item?.stagesCount}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </> :
                                    <div>
                                        <Empty description='Шаблоны отсутствуют'/>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </Spin>

            <Modal
                title='Подтверждение операции'
                open={isDeleteFunnelModalOpen.open}
                okText="Да"
                cancelText="Отмена"

                onOk={() => {
                    dispatch(removeTemplate({
                        workspaceId: currentWorkspace?.id,
                        //@ts-ignore
                        recruitingProcessTemplateId: isDeleteFunnelModalOpen?.funnel?.id
                    }))
                        .then(() => dispatch(getTemplates({workspaceId: currentWorkspace?.id})))
                        .then(() => setIsDeleteFunnelModalOpen(() => ({open: false, funnel: null})))

                }}
                onCancel={() => setIsDeleteFunnelModalOpen(() => ({open: false, funnel: null}))}
            >
                Вы уверены, что хотите удалить шаблон?
            </Modal>
        </>
    )
}

//@ts-ignore
export default FunnelTemplatesMain;
//@ts-ignore
