import React, {useEffect, useState} from 'react'
import styles from '../../styles/Tasks/TaskCandidatesPanel.module.scss'
import {
    Button,
    DatePicker,
    Empty,
    Form,
    Input, Menu,
    message,
    Modal,
    Rate,
    Result,
    Select,
    Spin,
    Steps,
    Timeline,
    Upload
} from "antd";
import {
    ClockCircleOutlined,
    CommentOutlined,
    DeleteOutlined, MenuFoldOutlined,
    MenuUnfoldOutlined,
    PaperClipOutlined,
    UserOutlined
} from "@ant-design/icons";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {
    addAssessment,
    addComment,
    completeStage, getAssessments, getAttachmentFile,
    getAttachments,
    getComments,
    getRecruitingProcesses,
    getRecruitmentStages,
    getStageDetails,
    rejectCandidateInStage,
    removeAttachment,
    removeComment,
    removeRecruitment, setCurrentProcess,
    startRecruitment,
    startRecruitmentStage
} from "../../redux/actions/RecruitmentActions";
import {useForm} from "antd/es/form/Form";
import {useImprovedUXValidation} from "../../hooks/useImprovedUXValidation";
import axios from "axios";
import {getAllRejectionReasons} from "../../redux/actions/RejectionReasonsActions";
import {getAllCandidates, getCandidateById} from "../../redux/actions/CandidatesActions";
import {getParticipantsList} from "../../redux/actions/ParticipantsActions";

const {TextArea} = Input;

const TaskCandidatesPanel = (props: any) => {

    const dispatch = useAppDispatch()
    const [recruitingForm] = useForm()
    const [rejectForm] = useForm()
    const [stageStartForm] = useForm()
    const {onFinishFailed, validationMode, onBlur} = useImprovedUXValidation(['candidateId', 'rejectionReasonId', 'comment'])
    const {
        allRecruitments,
        currentProcess,
        stages,
        stageDetails,
        stageAssessments,
        stageComments,
        stageAttachments,
        error,
        success,
        isLoading,
        isFileLoading
    } = useAppSelector(state => state.recruitmentReducer)
    const {currentWorkspace} = useAppSelector(state => state.workspacesReducer)
    const {candidates, candidateDetails} = useAppSelector(state => state.candidatesReducer)
    const {participants} = useAppSelector(state => state.participantsReducer)
    const {reasons} = useAppSelector(state => state.rejectionReasonsReducer)

    const [messageApi, contextHolder] = message.useMessage();
    const [currentStep, setCurrentStep] = useState(null)
    const [recruitingStartModal, setRecruitingStartModal] = useState(false)
    const [stageStartModal, setStageStartModal] = useState(false)
    const [removeAttachmentModal, setRemoveAttachmentModal] = useState({open: false, id: null})
    const [removeCommentModal, setRemoveCommentModal] = useState({open: false, id: null})
    const [addCommentForm] = useForm()
    const [defaultFileList, setDefaultFileList] = useState([]);
    const [isArchiveRecruitmentModalOpen, setIsArchiveRecruitmentModalOpen] = useState({open: false, id: null})
    const [isRejectCandidateModalOpen, setIsRejectCandidateModalOpen] = useState({open: false, id: null})
    const [collapsed, setCollapsed] = useState(false);

    useEffect(() => {
        if (error) {
            messageApi.open({
                type: 'error',
                content: error,
            });
        }

        if (success) {
            messageApi.open({
                type: 'success',
                content: success,
            });
        }
    }, [error, success])

    useEffect(() => {

        dispatch(getAllCandidates({
            workspaceId: currentWorkspace?.id,
            pageNumber: 1,
            pageSize: 100
        }))

        dispatch(getParticipantsList({
            workspaceId: currentWorkspace?.id
        }))

        dispatch(getRecruitingProcesses({
            workspaceId: currentWorkspace?.id,
            workTaskId: props.workTaskId
        }))
            .then(items => {
                //@ts-ignore
                if (items?.length > 0) {
                    dispatch(setCurrentProcess(items[0] || null))
                    dispatch(getRecruitmentStages({
                            workspaceId: currentWorkspace?.id,
                            //@ts-ignore
                            recruitmentProcessId: items[0]?.id
                        }
                    ))
                }
                else {
                    dispatch(setCurrentProcess(null))
                }
            })

    }, []);

    useEffect(() => {
        //@ts-ignore
        if (currentProcess?.id) {
            dispatch(getRecruitmentStages({
                    workspaceId: currentWorkspace?.id,
                    //@ts-ignore
                    recruitmentProcessId: currentProcess?.id
                }
            ))
        }
    }, [currentProcess])

    useEffect(() => {
        let startedStep = null
        let completedStep: { sequenceNumber: number; } | null = null

        stages.map((stage: any) => {
            if (stage.startedAtUtc !== null) {
                startedStep = stage
                completedStep = null
            }
            if (stage.completedAtUtc !== null) {
                completedStep = stage
                startedStep = null
            }
        })

        if (startedStep === null && !!completedStep) {
            // @ts-ignore
            setCurrentStep(stages.filter((stage) => stage.sequenceNumber === completedStep.sequenceNumber + 1)[0])
        } else if (!!startedStep && (completedStep === null)) {
            // @ts-ignore
            setCurrentStep(startedStep)
        } else {
            // @ts-ignore
            setCurrentStep(stages[0])
        }
    }, [stages])

    useEffect(() => {
        if (!!currentStep) {
            //@ts-ignore
            if (!!currentProcess?.candidateId) {
                dispatch(getCandidateById({
                    workspaceId: currentWorkspace?.id,
                    //@ts-ignore
                    id: currentProcess?.candidateId
                }))
            }

            dispatch(getStageDetails({
                workspaceId: currentWorkspace?.id,
                //@ts-ignore
                recruitmentProcessStageId: currentStep?.id
            }))

            dispatch(getAssessments({
                workspaceId: currentWorkspace?.id,
                //@ts-ignore
                recruitmentProcessStageId: currentStep?.id
            }))

            dispatch(getComments({
                workspaceId: currentWorkspace?.id,
                //@ts-ignore
                recruitmentProcessStageId: currentStep?.id
            }))

            dispatch(getAttachments({
                workspaceId: currentWorkspace?.id,
                //@ts-ignore
                recruitmentProcessStageId: currentStep?.id
            }))

            dispatch(getAllRejectionReasons({
                workspaceId: currentWorkspace?.id,
                pageNumber: 1
            }))
        }
    }, [currentStep])

    const uploadImage = async (options: any) => {
        const {onSuccess, onError, file, onProgress} = options;

        const fmData = new FormData();
        const token = localStorage.getItem('access_token');
        const config = {
            headers: {"content-type": "multipart/form-data", "Authorization": `Bearer ${token}`},
            onUploadProgress: (event: { loaded: number; total: number; }) => {
                const percent = Math.floor((event.loaded / event.total) * 100);
                onProgress({percent: (event.loaded / event.total) * 100});
            }
        };
        fmData.append("file", file);
        try {

            const res = await axios.post(
                // @ts-ignore
                `${process.env.REACT_APP_API_URI_HTTPS}/api/recruiting-processes/stages/${currentStep?.id}/attachments/upload?workspaceId=${currentWorkspace?.id}`,
                fmData,
                // @ts-ignore
                config
            )
                .then(() => {
                    dispatch(getAttachments({
                        workspaceId: currentWorkspace?.id,
                        //@ts-ignore
                        recruitmentProcessStageId: currentStep?.id
                    }))
                })

            onSuccess("Ok");
        } catch (err) {
            const error = new Error("Some error");
            onError({err});
        }
    }

    //@ts-ignore
    const handleOnChange = ({file, fileList, event}) => {
        setDefaultFileList(fileList);
    };

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    const handleCandidatesMenu = (key: string) => {
        if (key === 'add') {
            setRecruitingStartModal(true)
        }
        else {
            //@ts-ignore
            dispatch(setCurrentProcess(allRecruitments?.items?.filter((item: any) => item?.id === key)[0]))
        }
    }

    const checkDisabled = (item: any) => {
        if (item?.completionResult === 1 && !!item?.completedAtUtc) {
            return true
        }
        else if (item?.completionResult === 0 && !item?.startedAtUtc) {
            const prevItem = stages.filter((stage: any) => stage.sequenceNumber + 1 === item.sequenceNumber)
            //@ts-ignore
            if (prevItem[0]?.completionResult === 0 && !prevItem[0].startedAtUtc) {
                return true
            }
            //@ts-ignore
            else if (prevItem[0]?.completionResult === 0 && !!prevItem[0].startedAtUtc && !prevItem[0].completedAtUtc) {
                return true
            }
            else return false
        }
    }

    // @ts-ignore
    return (
        <>
            {contextHolder}
            <Spin spinning={isLoading} wrapperClassName={styles.tasksListSpinner}>
                {
                    currentProcess ?
                        <div className={styles.container}>
                            <div className={styles.menu}>
                                <Button type="primary" onClick={toggleCollapsed} style={{marginBottom: 16}}>
                                    {collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
                                </Button>
                                <Menu
                                    //@ts-ignore
                                    defaultSelectedKeys={[currentProcess?.id]}
                                    //@ts-ignore
                                    selectedKeys={[currentProcess?.id]}
                                    mode="inline"
                                    inlineCollapsed={collapsed}
                                    //@ts-ignore
                                    items={allRecruitments?.items?.map((item: any) => ({
                                        key: item?.id,
                                        label: `${item?.candidateInfo?.lastName} ${item?.candidateInfo?.firstName} ${item?.candidateInfo?.middleName}`,
                                    })).concat({
                                        key: 'add',
                                        label: 'Добавить кандидата'
                                    })}
                                    onClick={({key}) => handleCandidatesMenu(key)}
                                />
                            </div>

                            {
                                currentProcess &&
                                //@ts-ignore
                                currentProcess?.isCompletedSuccessfully ?
                                    <div style={{width: "100%"}}>
                                        <Result
                                            status="success"
                                            subTitle="Рекрутинг по данному кандидату успешно завершен"
                                            extra={[
                                                <Button icon={<img src="/archive_icon.svg" alt="edit"/>}
                                                    //@ts-ignore
                                                        onClick={() => setIsArchiveRecruitmentModalOpen(() => ({
                                                            open: true,
                                                            //@ts-ignore
                                                            id: currentStep?.id
                                                        }))}>Архивировать процесс рекрутинга</Button>
                                            ]}
                                        />
                                    </div>
                                    :
                                    //@ts-ignore
                                    currentProcess?.isRejected ?
                                        <div style={{width: "100%"}}>
                                            <Result
                                                status="error"
                                                subTitle="Данному кандидату было отказано"
                                                extra={[
                                                    <Button icon={<img src="/archive_icon.svg" alt="edit"/>}
                                                        //@ts-ignore
                                                            onClick={() => setIsArchiveRecruitmentModalOpen(() => ({
                                                                open: true,
                                                                //@ts-ignore
                                                                id: currentStep?.id
                                                            }))}>Архивировать процесс рекрутинга</Button>
                                                ]}
                                            />
                                        </div>
                                        :
                                        <div className={styles.containerWrapper}>
                                            <div className={styles.stepsContainer}>
                                                <Steps
                                                    //@ts-ignore
                                                    current={currentStep?.sequenceNumber - 1}
                                                    size="small"
                                                    labelPlacement="vertical"
                                                    onChange={(number: number) => setCurrentStep(stages[number])}
                                                    items={stages.map((item: any, i: number) => {
                                                        if (item.completionResult === 1) {
                                                            return {...item, status: 'finish'}
                                                        }
                                                        //@ts-ignore
                                                        else if (item.sequenceNumber === currentStep?.sequenceNumber) {
                                                            return {...item, status: 'process'}
                                                        } else return {...item, status: 'wait'}
                                                    })}
                                                />
                                                <Button type='primary'
                                                    //@ts-ignore
                                                        disabled={checkDisabled(currentStep)}
                                                        onClick={() => {
                                                            //@ts-ignore
                                                            if (!currentStep?.startedAtUtc) {
                                                                //@ts-ignore
                                                                if (!stageDetails?.hasScheduledDateTime && !stageDetails?.hasParticipants) {
                                                                    dispatch(startRecruitmentStage({
                                                                        workspaceId: currentWorkspace?.id,
                                                                        //@ts-ignore
                                                                        recruitmentProcessId: currentProcess?.id,
                                                                        //@ts-ignore
                                                                        recruitmentProcessStageId: currentStep?.id,
                                                                    }))
                                                                        .then(() => dispatch(getRecruitingProcesses({
                                                                            workspaceId: currentWorkspace?.id,
                                                                            workTaskId: props.workTaskId
                                                                        }))
                                                                            .then(item => {
                                                                                //@ts-ignore
                                                                                //dispatch(setCurrentProcess(allRecruitments?.items?.filter((i: any) => i?.id === item?.id)[0]))
                                                                                dispatch(
                                                                                    getRecruitmentStages({
                                                                                            workspaceId: currentWorkspace?.id,
                                                                                            //@ts-ignore
                                                                                            recruitmentProcessId: currentProcess?.id
                                                                                        }
                                                                                    ))
                                                                            }))
                                                                } else {
                                                                    setStageStartModal(true)
                                                                }
                                                            } else {
                                                                dispatch(completeStage({
                                                                    workspaceId: currentWorkspace?.id,
                                                                    //@ts-ignore
                                                                    recruitmentProcessId: currentProcess?.id,
                                                                    //@ts-ignore
                                                                    recruitmentProcessStageId: currentStep?.id,
                                                                }))
                                                                    .then(() => {
                                                                        dispatch(getRecruitmentStages({
                                                                                workspaceId: currentWorkspace?.id,
                                                                                //@ts-ignore
                                                                                recruitmentProcessId: currentProcess?.id,
                                                                            }
                                                                        ))
                                                                    })
                                                                    .then(() => {
                                                                        dispatch(getRecruitingProcesses({
                                                                                workspaceId: currentWorkspace?.id,
                                                                                workTaskId: props.workTaskId
                                                                            }
                                                                        ))
                                                                            .then(items => {
                                                                                //@ts-ignore
                                                                                if (!!items?.filter((item: any) => item?.id === currentProcess?.id && !!item?.isCompletedSuccessfully)[0]) {
                                                                                    //@ts-ignore
                                                                                    dispatch(setCurrentProcess(items?.filter((item: any) => item?.id === currentProcess?.id && !!item?.isCompletedSuccessfully)[0]))
                                                                                }
                                                                                dispatch(
                                                                                    getRecruitmentStages({
                                                                                            workspaceId: currentWorkspace?.id,
                                                                                            //@ts-ignore
                                                                                            recruitmentProcessId: currentProcess?.id
                                                                                        }
                                                                                    ))
                                                                            })
                                                                    })

                                                            }
                                                        }}

                                                >
                                                    {//@ts-ignore
                                                        !currentStep?.startedAtUtc ? `Начать этап ${currentStep?.title}` : `Завершить этап ${currentStep?.title}`}
                                                </Button>

                                            </div>

                                            <div className={styles.innerContainer}>
                                                <div className={styles.leftColumn}>

                                                    <div className={styles.candidate}>
                                                        <div className={styles.candidateBlock}>
                                                            <UserOutlined style={{fontSize: "36px"}}/>
                                                            <div className={styles.candidateInner}>
                                                <span
                                                    className={styles.title}>{candidateDetails?.lastName} {candidateDetails?.firstName} {candidateDetails?.middleName ? candidateDetails?.middleName : ''}</span>
                                                                <span
                                                                    className={styles.description}>{candidateDetails?.qualification} / {candidateDetails?.dateOfBirth}</span>
                                                            </div>
                                                        </div>

                                                        <Button type='primary'
                                                            //@ts-ignore
                                                                disabled={!(currentStep?.completionResult === 0 && !!currentStep?.startedAtUtc)}
                                                                onClick={() => setIsRejectCandidateModalOpen(() => ({
                                                                    open: true,
                                                                    //@ts-ignore
                                                                    id: currentProcess?.candidateId
                                                                }))}
                                                        >Отказ</Button>
                                                        <Button icon={<img src="/archive_icon.svg" alt="edit"/>}
                                                            //@ts-ignore
                                                            //disabled={!(currentStep?.completionResult === 0 && !!currentStep?.startedAtUtc)}
                                                                onClick={() => setIsArchiveRecruitmentModalOpen(() => ({
                                                                    open: true,
                                                                    //@ts-ignore
                                                                    id: currentStep?.id
                                                                }))}>Архивировать процесс рекрутинга</Button>
                                                    </div>

                                                    {
                                                        //@ts-ignore
                                                        stageDetails?.recruitmentProcessStageAssessments.length > 0 &&
                                                        <>
                                                            <div className={styles.ratingBlock}>
                                                                <h3>Цифровые оценки этапа</h3>

                                                                <div className={styles.ratingInnerContainer}>

                                                                    {
                                                                        //@ts-ignore
                                                                        stageDetails?.recruitmentProcessStageAssessments?.map((assessment: any) => (
                                                                            <div className={styles.ratingItem}
                                                                                 key={assessment?.metricId}>
                                                                                <span>{assessment?.metricName}</span>
                                                                                <Rate
                                                                                    onChange={(value) => dispatch(addAssessment({
                                                                                        workspaceId: currentWorkspace?.id,
                                                                                        //@ts-ignore
                                                                                        recruitmentProcessStageId: currentStep?.id,
                                                                                        assessment: {
                                                                                            metricId: assessment?.metricId,
                                                                                            value: value
                                                                                        }
                                                                                    }))
                                                                                        .then(() => dispatch(getStageDetails({
                                                                                            workspaceId: currentWorkspace?.id,
                                                                                            //@ts-ignore
                                                                                            recruitmentProcessStageId: currentStep?.id
                                                                                        })))
                                                                                    }/>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>

                                                            <div className={styles.ratingBlock}>
                                                                <h3>Оценки участников</h3>
                                                                {
                                                                    //@ts-ignore
                                                                    stageAssessments?.items?.length > 0 ?
                                                                        <div className={styles.ratingInnerContainer}>

                                                                            {
                                                                                //@ts-ignore
                                                                                stageDetails?.recruitmentProcessStageAssessments?.map((assessment: any) => (
                                                                                    <div className={styles.ratingItem}
                                                                                         key={assessment?.metricId}>
                                                                                        <span>{assessment?.metricName}</span>
                                                                                        <Rate
                                                                                            onChange={(value) => dispatch(addAssessment({
                                                                                                workspaceId: currentWorkspace?.id,
                                                                                                //@ts-ignore
                                                                                                recruitmentProcessStageId: currentStep?.id,
                                                                                                assessment: {
                                                                                                    metricId: assessment?.metricId,
                                                                                                    value: value
                                                                                                }
                                                                                            }))
                                                                                                .then(() => dispatch(getStageDetails({
                                                                                                    workspaceId: currentWorkspace?.id,
                                                                                                    //@ts-ignore
                                                                                                    recruitmentProcessStageId: currentStep?.id
                                                                                                })))
                                                                                            }/>
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                        </div> :
                                                                        <div>Никто еще не поставил оценку</div>
                                                                }
                                                            </div>
                                                        </>
                                                    }

                                                    {
                                                        <div className={styles.ratingBlock}>
                                                            <h3>Вложения</h3>

                                                            {
                                                                //@ts-ignore
                                                                (currentStep?.startedAtUtc && !currentStep?.completedAtUtc) &&
                                                                <Upload
                                                                    disabled={checkDisabled(currentStep)}
                                                                    customRequest={uploadImage}
                                                                    //@ts-ignore
                                                                    onChange={handleOnChange}
                                                                    listType="picture-card"
                                                                    defaultFileList={defaultFileList}
                                                                    className="image-upload-grid"
                                                                >
                                                                    {defaultFileList.length >= 1 ? null :
                                                                        <span>Добавить файл</span>}

                                                                </Upload>
                                                            }
                                                            <Spin spinning={isFileLoading}>
                                                                {
                                                                    //@ts-ignore
                                                                    stageAttachments?.items?.length > 0 ?
                                                                        <div className={styles.attachmentsContainer}>
                                                                            {
                                                                                //@ts-ignore
                                                                                stageAttachments?.items?.map((attachment: any) => (
                                                                                    <div
                                                                                        className={styles.attachmentItem}
                                                                                        key={attachment?.id}>
                                                                                        {/*@ts-ignore*/}
                                                                                        <div
                                                                                            className={styles.attachmentItemInner}>
                                                                                            <PaperClipOutlined/>
                                                                                            <p key={attachment?.id}
                                                                                               onClick={() => dispatch(getAttachmentFile({
                                                                                                   workspaceId: currentWorkspace?.id,
                                                                                                   //@ts-ignore
                                                                                                   recruitmentProcessStageId: currentStep?.id,
                                                                                                   attachmentId: attachment?.id
                                                                                               }))
                                                                                               }>{attachment?.fileName}</p>
                                                                                        </div>
                                                                                        <DeleteOutlined
                                                                                            style={{color: "red"}}
                                                                                            disabled={checkDisabled(currentStep)}
                                                                                            onClick={() => setRemoveAttachmentModal({
                                                                                                open: true,
                                                                                                id: attachment?.id
                                                                                            })}/>
                                                                                    </div>

                                                                                ))
                                                                            }
                                                                        </div> : <div>Вложения отсутствуют</div>
                                                                }
                                                            </Spin>

                                                        </div>
                                                    }


                                                    <div className={styles.commentBlock}>
                                                        <h3>Активность</h3>

                                                        <div className={styles.newCommentBlock}>
                                                            <Form
                                                                form={addCommentForm}
                                                                requiredMark='optional'
                                                                layout='vertical'
                                                                autoComplete='off'
                                                                onFinish={(values) => {
                                                                    dispatch(addComment({
                                                                        workspaceId: currentWorkspace?.id,
                                                                        //@ts-ignore
                                                                        recruitmentProcessStageId: currentStep?.id,
                                                                        comment: values?.comment
                                                                    }))
                                                                        .then(() => dispatch(getComments({
                                                                            workspaceId: currentWorkspace?.id,
                                                                            //@ts-ignore
                                                                            recruitmentProcessStageId: currentStep?.id
                                                                        })))
                                                                        .then(() => addCommentForm.resetFields())
                                                                }}
                                                                style={{marginBottom: "16px", width: "100%"}}
                                                            >
                                                                <Form.Item
                                                                    name="comment"
                                                                    validateTrigger={validationMode.comment}
                                                                    rules={[
                                                                        {required: true, message: 'Заполните поле'},
                                                                    ]}
                                                                >
                                                                    <TextArea
                                                                        //@ts-ignore
                                                                        disabled={!(currentStep?.completionResult === 0 && !!currentStep?.startedAtUtc)}
                                                                        showCount
                                                                        placeholder="Введите свой комментарий"
                                                                        autoSize={{minRows: 1, maxRows: 6}}
                                                                        maxLength={500}
                                                                        onBlur={onBlur('comment')}
                                                                    />
                                                                </Form.Item>
                                                            </Form>

                                                            <Button type='primary' style={{height: "36px"}}
                                                                //@ts-ignore
                                                                    disabled={!(currentStep?.completionResult === 0 && !!currentStep?.startedAtUtc)}
                                                                    onClick={() => {
                                                                        addCommentForm.submit();
                                                                        //@ts-ignore
                                                                    }}>Добавить</Button>
                                                        </div>

                                                        {
                                                            //@ts-ignore
                                                            stageComments?.items?.length > 0 ?
                                                                <Timeline
                                                                    //@ts-ignore
                                                                    items={stageComments?.items?.map((item: any, i: number) => (
                                                                        {
                                                                            color: 'gray',
                                                                            dot: <CommentOutlined/>,
                                                                            children: <div
                                                                                className={styles.commentItem}
                                                                                key={i}>
                                                                                <div
                                                                                    className={styles.commentItemInner}>
                                                                                    <span><b>{item?.createdByUserInfo?.firstName} {item?.createdByUserInfo?.lastName}</b> прокомментировал(-а) {`${item?.createdAtUtc.split('T')[0]} ${item?.createdAtUtc.split('T')[1].slice(0, 8)}`}</span>
                                                                                    <span>{item?.message}</span>
                                                                                </div>
                                                                                <DeleteOutlined
                                                                                    style={{
                                                                                        color: "red",
                                                                                        minWidth: "14px"
                                                                                    }}
                                                                                    //@ts-ignore
                                                                                    disabled={!(currentStep?.completionResult === 0 && !!currentStep?.startedAtUtc)}
                                                                                    onClick={() => setRemoveCommentModal({
                                                                                        open: true,
                                                                                        id: item?.id
                                                                                    })}/>
                                                                            </div>,
                                                                        }
                                                                    ))}
                                                                /> :
                                                                <Empty description='Комментарии отсутствуют'/>
                                                        }


                                                    </div>
                                                </div>

                                                <div className={styles.rightColumn}>
                                                    {
                                                        //@ts-ignore
                                                        stageDetails?.recruitmentProcessStageParticipants.length > 0 &&
                                                        <div className={styles.participantsBlock}>
                                                            <h3>Участники</h3>

                                                            <div className={styles.participantsInnerContainer}>
                                                                {
                                                                    //@ts-ignore
                                                                    stageDetails?.recruitmentProcessStageParticipants?.map((item: any) => (
                                                                        <span
                                                                            className="participantItem">{item?.userInfo?.lastName} {item?.userInfo?.firstName}</span>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                    }

                                                    {
                                                        //@ts-ignore
                                                        !!stageDetails?.recruitmentProcessStageScheduledDate &&
                                                        <div className={styles.meetingsBlock}>
                                                            <div className={styles.meetingsInnerContainer}>
                                                                <div className={styles.meetingItem}>
                                                                    <ClockCircleOutlined
                                                                        style={{fontSize: "16px", paddingTop: "4px"}}/>

                                                                    <div className={styles.meetingItemInner}>
                                                                        {/*@ts-ignore*/}
                                                                        <span className={styles.date}>{stageDetails?.recruitmentProcessStageScheduledDate?.scheduledAtUtc.split('T')[0]}</span>
                                                                        {/*@ts-ignore*/}
                                                                        <span className={styles.time}>{stageDetails?.recruitmentProcessStageScheduledDate?.scheduledAtUtc.split('T')[1].slice(0, 5)}</span>
                                                                        {/*@ts-ignore*/}
                                                                        <span className={styles.description}>{stageDetails?.recruitmentProcessStageScheduledDate?.name}</span>
                                                                        {/*@ts-ignore*/}
                                                                        <span className={styles.description}>{stageDetails?.recruitmentProcessStageScheduledDate?.description}</span>
                                                                        {/*@ts-ignore*/}
                                                                        <span className={styles.description}>{stageDetails?.recruitmentProcessStageScheduledDate?.location}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>

                                            </div>
                                        </div>
                            }
                        </div> :
                        <div>
                            <Empty/>
                            <Button type='primary' onClick={() => setRecruitingStartModal(true)}>Начать процесс
                                рекрутинга</Button>
                        </div>
                }
            </Spin>

            <Modal
                title='Начать процесс рекрутинга'
                okText="Добавить"
                cancelText="Отменить"
                open={recruitingStartModal}
                onOk={() => {
                    recruitingForm.submit()
                }}
                onCancel={() => setRecruitingStartModal(false)}
            >
                <Form
                    form={recruitingForm}
                    layout='vertical'
                    autoComplete='off'
                    requiredMark='optional'
                    onFinishFailed={onFinishFailed}
                    onFinish={(values) => {
                        dispatch(startRecruitment({
                            workspaceId: currentWorkspace?.id,
                            workTaskId: props?.workTaskId,
                            candidateId: values?.candidateId
                        }))
                            .then(() => {
                                dispatch(getRecruitingProcesses({
                                    workspaceId: currentWorkspace?.id,
                                    workTaskId: props.workTaskId
                                }))
                                    .then(items => {
                                        //@ts-ignore
                                        if (!currentProcess) {
                                            dispatch(setCurrentProcess(items[0] || null))
                                        }
                                        dispatch(
                                            getRecruitmentStages({
                                                    workspaceId: currentWorkspace?.id,
                                                    //@ts-ignore
                                                    recruitmentProcessId: currentProcess?.id
                                                }
                                            ))
                                    })
                            })
                            .then(() => recruitingForm.resetFields())
                            .then(() => setRecruitingStartModal(false))
                    }}
                >
                    <Form.Item
                        label={<span className='inputLabel'>Кандидат</span>}
                        name="candidateId"
                    >
                        <Select options={candidates?.items?.map((item: any) => ({
                            label: <span>{item?.lastName} {item?.firstName} {item?.middleName}</span>,
                            value: item?.id
                        }))} placeholder='Выберите кандидата'/>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title='Начать этап рекрутинга'
                okText="Перейти"
                cancelText="Отменить"
                open={stageStartModal}
                onOk={() => {
                    stageStartForm.submit()
                }}
                onCancel={() => setStageStartModal(false)}
            >
                <Form
                    form={stageStartForm}
                    layout='vertical'
                    autoComplete='off'
                    requiredMark='optional'
                    onFinishFailed={onFinishFailed}
                    onFinish={(values) => {

                        dispatch(startRecruitmentStage({
                            ...values,
                            workspaceId: currentWorkspace?.id,
                            //@ts-ignore
                            recruitmentProcessId: currentProcess?.id,
                            //@ts-ignore
                            recruitmentProcessStageId: currentStep?.id,
                            //stageParticipantUserIds: values.stageParticipantUserIds
                        }))
                            .then(() => dispatch(getRecruitingProcesses({
                                workspaceId: currentWorkspace?.id,
                                workTaskId: props.workTaskId
                            }))
                                .then((item) => {
                                    dispatch(
                                        getRecruitmentStages({
                                                workspaceId: currentWorkspace?.id,
                                                //@ts-ignore
                                                recruitmentProcessId: currentProcess?.id
                                            }
                                        ))
                                }))
                        stageStartForm.resetFields()
                        setStageStartModal(false)
                    }}
                >
                    {
                        //@ts-ignore
                        stageDetails?.hasParticipants &&
                        <Form.Item
                            label={<span className='inputLabel'>Участники</span>}
                            name="stageParticipantUserIds"
                            validateTrigger={validationMode.stageParticipantUserIds}
                            rules={[{required: true, message: 'Выберите участников'}]}
                        >
                            <Select mode="multiple" onBlur={onBlur('stageParticipantUserIds')}
                                    options={participants?.items?.map((item: any) => ({
                                        label:
                                            <span>{` ${item?.user?.fullName?.last} ${item?.user?.fullName?.first}`}</span>,
                                        value: item?.userId
                                    }))}/>
                        </Form.Item>
                    }


                    {
                        //@ts-ignore
                        stageDetails?.hasScheduledDateTime &&
                        <>
                            <span className='inputLabel' style={{fontSize: "16px", fontWeight: "600"}}>Дата/время</span>

                            <Form.Item
                                style={{marginTop: "16px"}}
                                label={<span className='inputLabel'>Название</span>}
                                name={['stageScheduleDateTime', 'name']}
                                validateTrigger={validationMode.name}
                                rules={[{required: true, message: 'Введите название'}]}
                            >
                                <Input placeholder='Созвон' onBlur={onBlur('name')}/>
                            </Form.Item>

                            <Form.Item
                                label={<span className='inputLabel'>Описание</span>}
                                name={['stageScheduleDateTime', 'description']}
                                // validateTrigger={validationMode.description}
                                // rules={[{required: true, message: 'Введите описание'}]}
                            >
                                <Input placeholder='Первичный скрининг кандидата' onBlur={onBlur('description')}/>
                            </Form.Item>

                            <Form.Item
                                label={<span className='inputLabel'>Дата и время</span>}
                                name={['stageScheduleDateTime', 'scheduledAtUtc']}
                                validateTrigger={validationMode.scheduledAtUtc}
                                rules={[{required: true, message: 'Выберите дату и время'}]}
                            >
                                <DatePicker showTime onBlur={onBlur('scheduledAtUtc')} style={{width: "100%"}}/>
                            </Form.Item>

                            <Form.Item
                                label={<span className='inputLabel'>Место</span>}
                                name={['stageScheduleDateTime', 'location']}
                                // validateTrigger={validationMode.location}
                                // rules={[{required: true, message: 'Введите место'}]}
                            >
                                <Input onBlur={onBlur('location')}/>
                            </Form.Item>
                        </>
                    }
                </Form>
            </Modal>


            <Modal
                title='Удалить вложение'
                okText="Удалить"
                cancelText="Отменить"
                open={removeAttachmentModal?.open}
                onOk={() => {
                    dispatch(removeAttachment({
                        workspaceId: currentWorkspace?.id,
                        //@ts-ignore
                        recruitmentProcessStageId: currentStep?.id,
                        //@ts-ignore
                        attachmentId: removeAttachmentModal?.id
                    }))
                        .then(() => {
                            dispatch(getAttachments({
                                workspaceId: currentWorkspace?.id,
                                //@ts-ignore
                                recruitmentProcessStageId: currentStep?.id
                            }))
                        })
                        .then(() => setRemoveAttachmentModal({open: false, id: null}))
                }}
                onCancel={() => setRemoveAttachmentModal({open: false, id: null})}
            >
                Вы точно хотите удалить данное вложение?
            </Modal>

            <Modal
                title='Удалить комментарий'
                okText="Удалить"
                cancelText="Отменить"
                open={removeCommentModal?.open}
                onOk={() => {
                    dispatch(removeComment({
                        workspaceId: currentWorkspace?.id,
                        //@ts-ignore
                        recruitmentProcessStageId: currentStep?.id,
                        //@ts-ignore
                        commentId: removeCommentModal?.id
                    }))
                        .then(() => {
                            dispatch(getComments({
                                workspaceId: currentWorkspace?.id,
                                //@ts-ignore
                                recruitmentProcessStageId: currentStep?.id
                            }))
                        })
                        .then(() => setRemoveCommentModal({open: false, id: null}))
                }}
                onCancel={() => setRemoveCommentModal({open: false, id: null})}
            >
                Вы точно хотите удалить данный комментарий?
            </Modal>

            <Modal
                title='Подтверждение операции'
                open={isArchiveRecruitmentModalOpen.open}
                okText="Да"
                cancelText="Отмена"

                onOk={() => {
                    dispatch(removeRecruitment({
                        workspaceId: currentWorkspace?.id,
                        //@ts-ignore
                        recruitmentProcessId: currentProcess?.id
                    }))
                        .then(() => {
                            dispatch(getRecruitingProcesses({
                                    workspaceId: currentWorkspace?.id,
                                    workTaskId: props.workTaskId
                                }
                            ))
                                .then(items => {
                                    if (items.length > 0) {
                                        //@ts-ignore
                                        dispatch(setCurrentProcess(items[0] || null))
                                    }

                                    dispatch(
                                        getRecruitmentStages({
                                                workspaceId: currentWorkspace?.id,
                                                //@ts-ignore
                                                recruitmentProcessId: items[0]?.id
                                            }
                                        ))
                                })
                        })
                        .then(() => setIsArchiveRecruitmentModalOpen(() => ({open: false, id: null})))
                }}
                onCancel={() => setIsArchiveRecruitmentModalOpen(() => ({open: false, id: null}))}
            >
                Вы уверены, что хотите архивировать процесс рекрутинга?
            </Modal>

            <Modal
                title='Подтверждение операции'
                open={isRejectCandidateModalOpen.open}
                okText="Да"
                cancelText="Отмена"

                onOk={() => {
                    rejectForm.submit()
                }}
                onCancel={() => {
                    setIsRejectCandidateModalOpen(() => ({open: false, id: null}))
                    rejectForm.resetFields()
                }}
            >
                <Form
                    form={rejectForm}
                    layout='vertical'
                    autoComplete='off'
                    requiredMark='optional'
                    onFinishFailed={onFinishFailed}
                    onFinish={(values) => {
                        dispatch(rejectCandidateInStage({
                            workspaceId: currentWorkspace?.id,
                            //@ts-ignore
                            recruitmentProcessId: currentProcess?.id,
                            //@ts-ignore
                            recruitmentProcessStageId: currentStep?.id,
                            rejectionReasonId: values?.rejectionReasonId
                        }))
                            .then(() => {
                                dispatch(getRecruitingProcesses({
                                        workspaceId: currentWorkspace?.id,
                                        workTaskId: props.workTaskId
                                    }
                                ))
                                    .then(items => {
                                        //@ts-ignore
                                        // if (!!items?.filter((item: any) => item?.id === currentProcess?.id && !!item?.isCompletedSuccessfully)[0]) {
                                        //     //@ts-ignore
                                        //     dispatch(setCurrentProcess(items?.filter((item: any) => item?.id === currentProcess?.id && !!item?.isCompletedSuccessfully)[0]))
                                        // }
                                        if (items.length > 0) {
                                            dispatch(setCurrentProcess(items[0] || null))
                                        }
                                        dispatch(
                                            getRecruitmentStages({
                                                    workspaceId: currentWorkspace?.id,
                                                    //@ts-ignore
                                                    recruitmentProcessId: currentProcess?.id
                                                }
                                            ))
                                    })
                            })
                            .then(() => rejectForm.resetFields())
                            .then(() => setIsRejectCandidateModalOpen(() => ({open: false, id: null})))
                    }}
                >
                    <Form.Item
                        label={<span className='inputLabel'>Причина отказа</span>}
                        name="rejectionReasonId"
                        validateTrigger={validationMode.rejectionReasonId}
                        rules={[{required: true, message: 'Выберите причину отказа'}]}
                    >
                        <Select
                            showSearch
                            allowClear
                            optionFilterProp="label"
                            onBlur={onBlur('rejectionReasonId')}
                            options={reasons?.items?.map((item: any) => (
                                {
                                    value: item?.id,
                                    label: item?.name
                                }))}
                            filterOption={(input, option) =>
                                //@ts-ignore
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default TaskCandidatesPanel
