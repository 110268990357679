import React, {useEffect, useState} from 'react'
import styles from '../../styles/Vacancies/CreateVacancy.module.scss'
import {useNavigate, useParams} from "react-router-dom";
import {useForm} from "antd/es/form/Form";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {Button, DatePicker, Form, Input, InputNumber, Select, Spin} from "antd";
import dayjs from "dayjs";
import {getAllVacancies, getVacancyById, updateVacancy} from "../../redux/actions/VacanciesActions";
import {useImprovedUXValidation} from "../../hooks/useImprovedUXValidation";
import {getAllCompanies} from "../../redux/actions/CompaniesActions";
import {getAllDepartments} from "../../redux/actions/DepartmentsActions";
import {getTemplates} from "../../redux/actions/TemplatesActions";
import KladrSearch from "../KladrSearch/KladrSearch";

const EditVacancy = () => {

    const {onFinishFailed, validationMode, onBlur} = useImprovedUXValidation(['name', 'hireQuantity', 'estimatedCompletedAtUtc', 'salaryFrom', 'salaryTo', 'responsibilities', 'requirements', 'workTerms'])
    const {id} = useParams<{ id: string }>()
    const navigate = useNavigate()
    const [form] = useForm()
    const dispatch = useAppDispatch()


    const {currentWorkspace} = useAppSelector(state => state.workspacesReducer)
    const {currentVacancy, isLoading} = useAppSelector(state => state.vacanciesReducer)
    const {companies} = useAppSelector(state => state.companiesReducer)
    const {templates} = useAppSelector(state => state.templatesReducer)
    const {departments} = useAppSelector(state => state.departmentsReducer)

    const [selectedCompany, setSelectedCompany] = useState(null)
    const [selectedLocations, setSelectedLocations] = useState([])

    useEffect(() => {
        //@ts-ignore
        dispatch(getVacancyById({workspaceId: currentWorkspace?.id, vacancyId: id}))
        dispatch(getAllCompanies({pageNumber: 1, workspaceId: currentWorkspace?.id}))
        dispatch(getTemplates({workspaceId: currentWorkspace?.id}))
    }, [])

    useEffect(() => {

        form.setFieldsValue({
            departmentId: null
        })

        if (!!selectedCompany) {
            dispatch(getAllDepartments({
                workspaceId: currentWorkspace?.id,
                companyId: selectedCompany,
                pageNumber: 1
            }))
        }
    }, [selectedCompany]);

    useEffect(() => {

        dispatch(getAllDepartments({
            workspaceId: currentWorkspace?.id,
            companyId: currentVacancy?.companyId,
            pageNumber: 1
        }))

        form.setFieldsValue({
            name: currentVacancy?.name,
            companyId: currentVacancy?.companyId,
            departmentId: currentVacancy?.departmentId,
            industryDirectionId: currentVacancy?.industryDirectionId,
            hireQuantity: currentVacancy?.hireQuantity,
            estimatedCompletedAtUtc: dayjs(currentVacancy?.estimatedCompletedAtUtc),
            salaryFrom: currentVacancy?.salaryFrom,
            salaryTo: currentVacancy?.salaryTo,
            responsibilities: currentVacancy?.responsibilities,
            requirements: currentVacancy?.requirements,
            workTerms: currentVacancy?.workTerms,
            comment: currentVacancy?.comment
        })
    }, [currentVacancy]);


    return (
        <div className={styles.container}>

            <div className={styles.titleInnerContainer}>
                <h3>Редактирование вакансии</h3>
            </div>

            <Spin spinning={isLoading} wrapperClassName={styles.spinnerWrapper}>
                <div>
                    <Form
                        layout='vertical'
                        autoComplete='off'
                        requiredMark='optional'
                        form={form}
                        onFinishFailed={onFinishFailed}
                        onFinish={(values) => {
                            dispatch(updateVacancy({
                                ...values,
                                locations: selectedLocations,
                                workspaceId: currentWorkspace?.id,
                                vacancyId: id
                            }))
                                .then(() => dispatch(getAllVacancies({
                                    workspaceId: currentWorkspace?.id,
                                    pageNumber: 1,
                                    pageSize: 8
                                })))
                                .then(() => navigate(-1))
                        }}
                        className={styles.filterForm}
                    >
                        <div className={styles.descriptionContainer}>
                            <span className={styles.columnTitle}>Основная информация</span>

                            <div className={styles.innerFormContainer}>
                                <Form.Item
                                    label={<span className='inputLabel'>Название вакансии</span>}
                                    name="name"
                                    validateTrigger={validationMode.name}
                                    rules={[{required: true, message: 'Введите название вакансии'}]}
                                >
                                    <Input placeholder='Разработчик ПО' maxLength={50} onBlur={onBlur('name')}/>
                                </Form.Item>

                                <Form.Item
                                    label={<span className='inputLabel'>Компания</span>}
                                    name="companyId"
                                >
                                    <Select
                                        allowClear
                                        showSearch
                                        optionFilterProp="label"
                                        options={companies?.items?.map((item: any) => ({
                                        value: item?.id,
                                        label: item?.name
                                    }))}
                                        onChange={(value) => setSelectedCompany(value)}
                                        filterOption={(input, option) =>
                                            //@ts-ignore
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                    />
                                </Form.Item>

                                <Form.Item
                                    label={<span className='inputLabel'>Отдел</span>}
                                    name="departmentId"
                                >
                                    <Select
                                        allowClear
                                        showSearch
                                        optionFilterProp="label"
                                        disabled={!selectedCompany}
                                        options={departments?.items?.map((item: any) => ({
                                            value: item?.id,
                                            label: item?.name
                                        }))}
                                        filterOption={(input, option) =>
                                            //@ts-ignore
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                    />
                                </Form.Item>

                                <Form.Item
                                    label={<span className='inputLabel'>Специализация</span>}
                                    name="industryDirectionId"
                                >
                                    <Input placeholder='' disabled/>
                                </Form.Item>

                                <div className={styles.formRow}>
                                    <Form.Item
                                        label={<span className='inputLabel'>Количество кандидатов</span>}
                                        name="hireQuantity"
                                        validateTrigger={validationMode.hireQuantity}
                                        rules={[{required: true, message: 'Установите количество'}]}
                                    >
                                        <InputNumber min={1} max={100} keyboard size='middle' onBlur={onBlur('hireQuantity')}/>
                                    </Form.Item>

                                    <Form.Item
                                        label={<span className='inputLabel'>Дата закрытия</span>}
                                        name="estimatedCompletedAtUtc"
                                        validateTrigger={validationMode.estimatedCompletedAtUtc}
                                        rules={[{required: true, message: 'Установите дату закрытия'}]}
                                    >
                                        <DatePicker showTime onBlur={onBlur('estimatedCompletedAtUtc')}/>
                                    </Form.Item>
                                </div>

                                <Form.Item
                                    label={<span className='inputLabel'>Шаблон воронки</span>}
                                    name="recruitmentProcessTemplateId"
                                >
                                    <Select allowClear options={templates?.items?.map((item: any) => ({
                                        value: item?.id,
                                        label: <span>{item?.name}</span>
                                    }))}/>
                                </Form.Item>

                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                    >
                                        Изменить вакансию
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>

                        <div className={styles.vacancyContainer}>
                            <span className={styles.columnTitle}>О вакансии</span>


                            <Form.Item
                                label={<span className='inputLabel'>Выборка городов</span>}
                                name="locations"
                            >
                                <KladrSearch values={currentVacancy?.vacancyLocations} onChangeValue={(values: any) => {
                                    setSelectedLocations(() => values.map((item: any) => (
                                        {
                                            kladrCode: item.split('~')[0],
                                            name: item.split('~')[1]
                                        }
                                    )))
                                }}/>
                            </Form.Item>

                            <div className={styles.formRow}>
                                <Form.Item
                                    label={<span className='inputLabel'>Зарплата от</span>}
                                    name="salaryFrom"
                                    validateTrigger={validationMode.salaryFrom}
                                    rules={[{required: true, message: 'Введите значение'}]}
                                >
                                    <InputNumber min={500} max={10000000} keyboard size='middle' onBlur={onBlur('salaryFrom')}/>
                                </Form.Item>

                                <Form.Item
                                    label={<span className='inputLabel'>Зарплата до</span>}
                                    name="salaryTo"
                                    validateTrigger={validationMode.salaryTo}
                                    rules={[{required: true, message: 'Введите значение'}]}
                                >
                                    <InputNumber min={500} max={10000000} keyboard size='middle' onBlur={onBlur('salaryTo')}/>
                                </Form.Item>
                            </div>

                            <Form.Item
                                label={<span className='inputLabel'>Обязанности кандидата</span>}
                                name="responsibilities"
                                validateTrigger={validationMode.responsibilities}
                                rules={[{required: true, message: 'Введите обязанности'}]}
                            >
                                <Input.TextArea placeholder='' autoSize={{minRows: 5, maxRows: 14}} showCount maxLength={1000}
                                                onBlur={onBlur('responsibilities')}/>
                            </Form.Item>

                            <Form.Item
                                label={<span className='inputLabel'>Требования к кандидату</span>}
                                name="requirements"
                                validateTrigger={validationMode.requirements}
                                rules={[{required: true, message: 'Введите требования'}]}
                            >
                                <Input.TextArea placeholder='' autoSize={{minRows: 5, maxRows: 14}} showCount maxLength={1000}
                                                onBlur={onBlur('requirements')}/>
                            </Form.Item>

                            <Form.Item
                                label={<span className='inputLabel'>Условия работы</span>}
                                name="workTerms"
                                validateTrigger={validationMode.workTerms}
                                rules={[{required: true, message: 'Введите условия работы'}]}
                            >
                                <Input.TextArea placeholder='' autoSize={{minRows: 5, maxRows: 14}} showCount maxLength={1000}
                                                onBlur={onBlur('workTerms')}/>
                            </Form.Item>

                            <Form.Item
                                label={<span className='inputLabel'>Комментарий и заметки</span>}
                                name="comment"
                            >
                                <Input.TextArea placeholder='' autoSize={{minRows: 5, maxRows: 14}} showCount maxLength={1000}
                                />
                            </Form.Item>
                        </div>
                    </Form>
                </div>
            </Spin>
        </div>
    )
}

export default EditVacancy
