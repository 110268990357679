import React, {useEffect, useState} from 'react'
import styles from '../../styles/Candidates/CandidatesMain.module.scss'
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {
    Button,
    Dropdown,
    Empty,
    Form,
    Input,
    InputNumber,
    MenuProps,
    message,
    Modal,
    Pagination,
    Radio,
    Select,
    Space,
    Spin
} from "antd";

import {DownOutlined, FilterOutlined, MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {useForm} from "antd/es/form/Form";
import {createCandidate, getAllCandidates, updateCandidate} from "../../redux/actions/CandidatesActions";
import {useImprovedUXValidation} from "../../hooks/useImprovedUXValidation";
import {SearchProps} from "antd/es/input";
import CandidatesFilter from './CandidatesFilter';

const {Search} = Input;


const CandidatesMain = () => {

    const {
        onFinishFailed,
        validationMode,
        onBlur
    } = useImprovedUXValidation(['firstName', 'lastName', 'middleName', 'day', 'month', 'year', 'gender', 'type', 'value'])
    const dispatch = useAppDispatch()
    const [candidateForm] = useForm()
    const [editCandidateForm] = useForm()
    const [showFilter, setShowFilter] = useState(true)
    const [messageApi, contextHolder] = message.useMessage();
    const [isNewCandidateModal, setIsNewCandidateModal] = useState(false)
    const [isEditCandidateModal, setIsEditCandidateModal] = useState({open: false, data: {}})

    const [contactsSelect, setContactsSelect] = useState([1])
    const [editContactsSelect, setEditContactsSelect] = useState([])

    const {currentWorkspace} = useAppSelector(state => state.workspacesReducer)
    const {candidates, isLoading, error, success} = useAppSelector(state => state.candidatesReducer)

    useEffect(() => {
        dispatch(getAllCandidates({workspaceId: currentWorkspace?.id, pageNumber: 1, pageSize: 8}))
    }, []);

    useEffect(() => {
        if (isEditCandidateModal.open) {

            //@ts-ignore
            setEditContactsSelect(isEditCandidateModal?.data?.contacts?.map((item: any) => item.type))

            editCandidateForm.setFieldsValue({
                //@ts-ignore
                firstName: isEditCandidateModal?.data?.firstName,
                //@ts-ignore
                lastName: isEditCandidateModal?.data?.lastName,
                //@ts-ignore
                middleName: isEditCandidateModal?.data?.middleName,
                //@ts-ignore
                dateOfBirth: {
                    //@ts-ignore
                    year: isEditCandidateModal?.data?.dateOfBirth.split('-')[0],
                    //@ts-ignore
                    month: isEditCandidateModal?.data?.dateOfBirth.split('-')[1],
                    //@ts-ignore
                    day: isEditCandidateModal?.data?.dateOfBirth.split('-')[2],
                },
                //@ts-ignore
                gender: isEditCandidateModal?.data?.gender,
                //@ts-ignore
                //citizenshipId: isEditCandidateModal?.data?.citizenshipId,
                //@ts-ignore
                contacts: isEditCandidateModal?.data?.contacts,
                //@ts-ignore
                qualification: isEditCandidateModal?.data?.qualification,
            })
        }
    }, [isEditCandidateModal?.open])


    useEffect(() => {
        if (error) {
            messageApi.open({
                type: 'error',
                content: error,
            });
        }

        if (success) {
            messageApi.open({
                type: 'success',
                content: success,
            });
        }
    }, [error, success])


    const candidatesActions: MenuProps['items'] = [
        {
            label: <span className={styles.actionItem}>Изменить</span>,
            key: '1',
        }
    ];

    const onSearch: SearchProps['onSearch'] = (value, _e, info) => {

        if (value === '') {
            dispatch(getAllCandidates({
                workspaceId: currentWorkspace?.id,
                keywords: '',
                pageNumber: 1,
                pageSize: 8
            }))
        }

        if (value.length !== 0 && value && value.indexOf(' ') < 0) {
            dispatch(getAllCandidates({
                workspaceId: currentWorkspace?.id,
                keywords: value,
                pageNumber: 1,
                pageSize: 8
            }))
        }
    };

    const inputsPlaceholders = {
        0: "Введите другие контакты",
        1: "+78805553535",
        2: "ivan_ivanov@mail.ru",
        3: "@ivanov_ivan",
        4: "+78805553535",
        5: "+78805553535"
    }

    const validators = {
        0: {
            regex: /(?=^.{1,255}$)/,
            message: "Не более 255 символов"
        },
        1: {
            regex: /^(\+7)?(\d{10})$/,
            message: "Введите корректный номер телефона"
        },
        2: {
            regex: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
            message: "Введите корректный адрес электронной почты"
        },
        3: {
            regex: /.*\B@(?=\w{5,64}\b)[a-zA-Z0-9]+(?:_[a-zA-Z0-9]+)*.*/,
            message: "Введите корректный Telegram",
        },
        4: {
            regex: /^(\+7)?(\d{10})$/i,
            message: "Введите корректный номер, привязанный к Viber"
        },
        5: {
            regex: /^(\+7)?(\d{10})$/i,
            message: "Введите корректный номер, привязанный к WhatsApp"
        },
    }


    const onPageNumberChange = (value: any) => {
        dispatch(getAllCandidates({workspaceId: currentWorkspace?.id, pageNumber: value, pageSize: 8}))
    }

    const handleActions = (key: any, data: any) => {
        switch (key) {
            case '1':
                setIsEditCandidateModal({open: true, data: data})
                break;
            default:
                break;
        }
    }

    // @ts-ignore
    return (
        <>
            {contextHolder}
            <div className={styles.container}>
                <div className={styles.titleContainer}>
                    <div className={styles.titleInnerContainer}>
                        <img src="/users_icon.svg" alt="Кандидаты"/>
                        <h3>Кандидаты</h3>
                    </div>

                    <div className={styles.buttonsContainer}>
                        <Button icon={<FilterOutlined/>} onClick={() => setShowFilter(prev => !prev)}/>
                        <Button type='primary' icon={<PlusOutlined/>} onClick={() => {
                            candidateForm.setFieldsValue({
                                contacts: [
                                    {
                                        type: 1
                                    }
                                ]
                            })
                            setIsNewCandidateModal(true)
                        }}>Добавить кандидата</Button>
                    </div>
                </div>

                <div className={styles.innerContainer}>
                    <CandidatesFilter showFilter={showFilter} onFilterChange={(values: any) => dispatch(
                        getAllCandidates({...values, workspaceId: currentWorkspace?.id, pageNumber: 1, pageSize: 8})
                    )}/>

                    <Spin spinning={isLoading} wrapperClassName={styles.tasksListSpinner}>
                        <div className={styles.listContainer}>
                            <div className={styles.listSearchContainer}>
                                <Search
                                    allowClear
                                    enterButton={false}
                                    onSearch={onSearch}
                                    placeholder="Введите поисковую строку"
                                    loading={isLoading}
                                    style={{maxWidth: "350px"}}
                                />
                            </div>
                            {
                                candidates?.items?.length > 0 ?
                                    <div className={styles.listInnerContainer}>
                                        {
                                            candidates?.items?.map((item: any) => (
                                                <div className={styles.listItem} key={item?.id}>
                                                    <div className={styles.listItemInner}>
                                                        <div className={styles.profileContainer}>
                                                            <div className={styles.profileInner}>
                                                                <span
                                                                    className={styles.profileName}>{item?.lastName} {item?.firstName} {item?.middleName}</span>
                                                            </div>
                                                        </div>

                                                        <div className={styles.infoContainer}>
                                                            <div className={styles.infoColumn}>
                                                                <span
                                                                    className={styles.infoColumnTitle}>Квалификация</span>
                                                                <span
                                                                    className={styles.infoColumnValue}>{item?.qualification}</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={styles.listItemActions}>
                                                        {/*@ts-ignore*/}
                                                        <Dropdown menu={{
                                                            items: candidatesActions, onClick: ({key}) => {
                                                                handleActions(key, item)
                                                            }
                                                        }}>
                                                            <a onClick={(e) => {
                                                                e.preventDefault()
                                                                e.stopPropagation()

                                                            }}>
                                                                Действия
                                                                <DownOutlined/>
                                                            </a>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                        {
                                            candidates?.totalCount > 8 &&
                                            <Pagination
                                                onChange={onPageNumberChange}
                                                defaultPageSize={8}
                                                current={candidates?.pageNumber}
                                                total={candidates?.totalCount}
                                            />
                                        }
                                    </div> :
                                    <div>
                                        <Empty description='Кандидаты отсутствуют'/>
                                    </div>
                            }

                        </div>
                    </Spin>
                </div>
            </div>

            <Modal
                title='Добавить кандидата'
                okText="Добавить"
                cancelText="Отменить"
                open={isNewCandidateModal}
                onOk={() => {
                    candidateForm.submit()
                }}
                onCancel={() => {
                    setIsNewCandidateModal(false)
                    candidateForm.resetFields()
                }}
            >
                <Form
                    form={candidateForm}
                    layout='vertical'
                    autoComplete='off'
                    requiredMark='optional'
                    onFinishFailed={onFinishFailed}
                    onFinish={(values) => {
                        dispatch(createCandidate({
                            ...values,
                            dateOfBirth: `${values.dateOfBirth.year}-${values.dateOfBirth.month}-${values.dateOfBirth.day}`,
                            workspaceId: currentWorkspace?.id,
                            citizenshipId: '00000000-0000-0000-0000-000000000000'
                        }))
                            .then(() => dispatch(getAllCandidates({
                                workspaceId: currentWorkspace?.id,
                                pageNumber: 1,
                                pageSize: 8
                            })))
                            .then(() => candidateForm.resetFields())
                            .then(() => setIsNewCandidateModal(false))
                    }}
                >
                    <Form.Item
                        label={<span className='inputLabel'>Фамилия</span>}
                        name="lastName"
                        validateTrigger={validationMode.lastName}
                        rules={[{required: true, message: 'Введите фамилию'}]}
                    >
                        <Input placeholder='Иванов' onBlur={onBlur('lastName')}/>
                    </Form.Item>

                    <Form.Item
                        label={<span className='inputLabel'>Имя</span>}
                        name="firstName"
                        validateTrigger={validationMode.firstName}
                        rules={[{required: true, message: 'Введите имя'}]}
                    >
                        <Input placeholder='Иван' onBlur={onBlur('firstName')}/>
                    </Form.Item>

                    <Form.Item
                        label={<span className='inputLabel'>Отчество</span>}
                        name="middleName"
                        validateTrigger={validationMode.middleName}
                        rules={[{required: true, message: 'Введите отчество'}]}
                    >
                        <Input placeholder='Иванович' onBlur={onBlur('middleName')}/>
                    </Form.Item>


                    <div className={styles.formRow}>
                        <div style={{width: "fit-content"}}>
                            <span className='inputLabel'>Дата рождения</span>
                            <Space.Compact style={{marginTop: "8px"}}>
                                <Form.Item
                                    name={["dateOfBirth", "day"]}
                                    validateTrigger={validationMode.day}
                                    style={{width: "30%"}}
                                    rules={[{required: true, message: 'Введите день'},
                                        {
                                            pattern: new RegExp('(0[1-9]|[12]\\d|3[01])'),
                                            message: "Введите день в формате 01-31"
                                        }
                                    ]}
                                >
                                    <Input placeholder="01" min={1} max={31} onBlur={onBlur('day')}/>
                                </Form.Item>

                                <Form.Item
                                    name={["dateOfBirth", "month"]}
                                    validateTrigger={validationMode.month}
                                    style={{width: "30%"}}
                                    rules={[{required: true, message: 'Введите месяц'},
                                        {pattern: new RegExp('0[1-9]|1[0-2]'), message: "Введите месяц в формате 01-12"}
                                    ]}
                                >
                                    <Input placeholder="05" min={1} max={12} onBlur={onBlur('month')}/>
                                </Form.Item>

                                <Form.Item
                                    name={["dateOfBirth", "year"]}
                                    validateTrigger={validationMode.year}
                                    style={{width: "40%"}}
                                    rules={[{required: true, message: 'Введите год'}]}
                                >
                                    <InputNumber placeholder="1995" min={1920} max={2024} onBlur={onBlur('year')}/>
                                </Form.Item>
                            </Space.Compact>
                        </div>

                        <Form.Item
                            label={<span className='inputLabel'>Пол</span>}
                            name="gender"
                            validateTrigger={validationMode.gender}
                            style={{width: "100%"}}
                            rules={[{required: true, message: 'Выберите пол'}]}
                        >
                            <Radio.Group onBlur={onBlur('gender')}>
                                <Radio.Button value={0}>Мужской</Radio.Button>
                                <Radio.Button value={1}>Женский</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    </div>

                    <Form.Item
                        label={<span className='inputLabel'>Гражданство</span>}
                        name="citizenshipId"
                    >
                        <Input placeholder=''/>
                    </Form.Item>

                    <div style={{marginBottom: "8px"}}>
                        <span className='inputLabel'>Контакты</span>
                    </div>
                    <Form.List
                        name="contacts">
                        {(fields, {add, remove}) => (
                            <>
                                {fields.map(({key, name, ...restField}) => (
                                    <Space key={key} className={styles.modalContactsRow} align="baseline">
                                        <div style={{display: "flex", flexDirection: "column"}}>
                                            <div style={{display: "flex", flexDirection: "row", gap: "12px"}}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'type']}
                                                    validateTrigger={validationMode.type}
                                                    rules={[{required: true, message: 'Выберите тип контакта'}]}
                                                    style={{minWidth: "120px"}}
                                                >
                                                    <Select
                                                        options={[
                                                            {value: 0, label: 'Другое'},
                                                            {value: 1, label: 'Моб.телефон'},
                                                            {value: 2, label: 'Email'},
                                                            {value: 3, label: 'Telegram'},
                                                            {value: 4, label: 'Viber'},
                                                            {value: 5, label: 'WhatsApp'},
                                                        ]}
                                                        onBlur={onBlur('type')}
                                                        onChange={(val) => {
                                                            setContactsSelect(prev => {
                                                                //@ts-ignore
                                                                prev[key] = val
                                                                return [...prev]
                                                            })
                                                        }}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    style={{flex: "1"}}
                                                    {...restField}
                                                    validateTrigger={validationMode.value}
                                                    name={[name, 'value']}
                                                    rules={[
                                                        {
                                                            validator: (_, value) => {
                                                                if (contactsSelect[key] !== undefined) {
                                                                    //@ts-ignore
                                                                    if (validators[contactsSelect[key]].regex.test(value)) {
                                                                        return Promise.resolve()
                                                                    }
                                                                    else { // @ts-ignore
                                                                        return Promise.reject(validators[contactsSelect[key]].message);
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    ]}
                                                >
                                                    {/*@ts-ignore*/}
                                                    <Input placeholder={inputsPlaceholders[contactsSelect[key]]} onBlur={onBlur('value')}/>
                                                </Form.Item>
                                            </div>

                                            <Form.Item
                                                {...restField}
                                                validateTrigger={validationMode.value}
                                                name={[name, 'comment']}
                                                style={{flex: 2}}
                                            >
                                                <Input placeholder='Комментарий'/>
                                            </Form.Item>
                                        </div>

                                        <MinusCircleOutlined onClick={() => remove(name)}/>
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                                        Добавить контакты
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>


                    <Form.Item
                        label={<span className='inputLabel'>Квалификация</span>}
                        name="qualification"
                    >
                        <Input placeholder='Инженер-программист'/>
                    </Form.Item>
                </Form>
            </Modal>


            <Modal
                title='Изменить кандидата'
                okText="Изменить"
                cancelText="Отменить"
                open={isEditCandidateModal?.open}
                onOk={() => {
                    editCandidateForm.submit()
                }}
                onCancel={() => {
                    setIsEditCandidateModal({open: false, data: {}})
                    editCandidateForm.resetFields()
                }}
            >
                <Form
                    form={editCandidateForm}
                    layout='vertical'
                    autoComplete='off'
                    requiredMark='optional'
                    onFinishFailed={onFinishFailed}
                    onFinish={(values) => {
                        dispatch(updateCandidate({
                            ...values,
                            dateOfBirth: `${values.dateOfBirth.year}-${values.dateOfBirth.month}-${values.dateOfBirth.day}`,
                            workspaceId: currentWorkspace?.id,
                            //@ts-ignore
                            id: isEditCandidateModal?.data?.id
                        }))
                            .then(() => dispatch(getAllCandidates({
                                workspaceId: currentWorkspace?.id,
                                pageNumber: 1,
                                pageSize: 8
                            })))
                            .then(() => editCandidateForm.resetFields())
                            .then(() => setIsEditCandidateModal({open: false, data: {}}))
                    }}
                >
                    <Form.Item
                        label={<span className='inputLabel'>Фамилия</span>}
                        name="lastName"
                        validateTrigger={validationMode.lastName}
                        rules={[{required: true, message: 'Введите фамилию'}]}
                    >
                        <Input placeholder='Иванов' onBlur={onBlur('lastName')}/>
                    </Form.Item>

                    <Form.Item
                        label={<span className='inputLabel'>Имя</span>}
                        name="firstName"
                        validateTrigger={validationMode.firstName}
                        rules={[{required: true, message: 'Введите имя'}]}
                    >
                        <Input placeholder='Иван' onBlur={onBlur('firstName')}/>
                    </Form.Item>

                    <Form.Item
                        label={<span className='inputLabel'>Отчество</span>}
                        name="middleName"
                        validateTrigger={validationMode.middleName}
                        rules={[{required: true, message: 'Введите отчество'}]}
                    >
                        <Input placeholder='Иванович' onBlur={onBlur('middleName')}/>
                    </Form.Item>


                    <div className={styles.formRow}>
                        <div style={{width: "fit-content"}}>
                            <span className='inputLabel'>Дата рождения</span>
                            <Space.Compact style={{marginTop: "8px"}}>
                                <Form.Item
                                    name={["dateOfBirth", "day"]}
                                    validateTrigger={validationMode.day}
                                    style={{width: "30%"}}
                                    rules={[{required: true, message: 'Введите день'},
                                        {
                                            pattern: new RegExp('(0[1-9]|[12]\\d|3[01])'),
                                            message: "Введите день в формате 01-31"
                                        }
                                    ]}
                                >
                                    <Input placeholder="01" min={1} max={31} onBlur={onBlur('day')}/>
                                </Form.Item>

                                <Form.Item
                                    name={["dateOfBirth", "month"]}
                                    validateTrigger={validationMode.month}
                                    style={{width: "30%"}}
                                    rules={[{required: true, message: 'Введите месяц'},
                                        {pattern: new RegExp('0[1-9]|1[0-2]'), message: "Введите месяц в формате 01-12"}
                                    ]}
                                >
                                    <Input placeholder="05" min={1} max={12} onBlur={onBlur('month')}/>
                                </Form.Item>

                                <Form.Item
                                    name={["dateOfBirth", "year"]}
                                    validateTrigger={validationMode.year}
                                    style={{width: "40%"}}
                                    rules={[{required: true, message: 'Введите год'}]}
                                >
                                    <InputNumber placeholder="1995" min={1920} max={2024} onBlur={onBlur('year')}/>
                                </Form.Item>
                            </Space.Compact>
                        </div>

                        <Form.Item
                            label={<span className='inputLabel'>Пол</span>}
                            name="gender"
                            validateTrigger={validationMode.gender}
                            style={{width: "100%"}}
                            rules={[{required: true, message: 'Выберите пол'}]}
                        >
                            <Radio.Group onBlur={onBlur('gender')}>
                                <Radio.Button value={0}>Мужской</Radio.Button>
                                <Radio.Button value={1}>Женский</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    </div>

                    <Form.Item
                        label={<span className='inputLabel'>Гражданство</span>}
                        name="citizenshipId"
                    >
                        <Input placeholder=''/>
                    </Form.Item>

                    <div style={{marginBottom: "8px"}}>
                        <span className='inputLabel'>Контакты</span>
                    </div>
                    <Form.List
                        name="contacts">
                        {(fields, {add, remove}) => (
                            <>
                                {fields.map(({key, name, ...restField}) => (
                                    <Space key={key} className={styles.modalContactsRow} align="baseline">
                                        <div style={{display: "flex", flexDirection: "column"}}>
                                            <div style={{display: "flex", flexDirection: "row", gap: "12px"}}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'type']}
                                                    validateTrigger={validationMode.type}
                                                    rules={[{required: true, message: 'Выберите тип контакта'}]}
                                                    style={{minWidth: "120px"}}
                                                >
                                                    <Select
                                                        options={[
                                                            {value: 0, label: 'Другое'},
                                                            {value: 1, label: 'Моб.телефон'},
                                                            {value: 2, label: 'Email'},
                                                            {value: 3, label: 'Telegram'},
                                                            {value: 4, label: 'Viber'},
                                                            {value: 5, label: 'WhatsApp'},
                                                        ]}
                                                        onBlur={onBlur('type')}
                                                        onChange={(val) => {
                                                            setContactsSelect(prev => {
                                                                //@ts-ignore
                                                                prev[key] = val
                                                                return [...prev]
                                                            })
                                                        }}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    style={{flex: "1"}}
                                                    {...restField}
                                                    validateTrigger={validationMode.value}
                                                    name={[name, 'value']}
                                                    rules={[
                                                        {
                                                            validator: (_, value) => {
                                                                if (contactsSelect[key] !== undefined) {
                                                                    //@ts-ignore
                                                                    if (validators[contactsSelect[key]].regex.test(value)) {
                                                                        return Promise.resolve()
                                                                    } else { // @ts-ignore
                                                                        return Promise.reject(validators[contactsSelect[key]].message);
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    ]}
                                                >
                                                    {/*@ts-ignore*/}
                                                    <Input placeholder={inputsPlaceholders[contactsSelect[key]]}
                                                           onBlur={onBlur('value')}/>
                                                </Form.Item>
                                            </div>

                                            <Form.Item
                                                {...restField}
                                                validateTrigger={validationMode.value}
                                                name={[name, 'comment']}
                                                style={{flex: 2}}
                                            >
                                                <Input placeholder='Комментарий'/>
                                            </Form.Item>
                                        </div>

                                        <MinusCircleOutlined onClick={() => remove(name)}/>
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                                        Добавить контакты
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>

                    <Form.Item
                        label={<span className='inputLabel'>Квалификация</span>}
                        name="qualification"
                    >
                        <Input placeholder=''/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default CandidatesMain
