import {createSlice} from "@reduxjs/toolkit";

interface DepartmentsSlice {
    departments: any;
    isLoading: boolean;
    error: any;
    success: any;
}

const initialState: DepartmentsSlice = {
    departments: [],
    isLoading: false,
    error: null,
    success: null
}

export const departmentsSlice = createSlice({
    name: "departments",
    initialState,
    reducers: {
        isLoading: (state) => {
            state.isLoading = true
            state.error = null
            state.success = null
            state.departments = null
        },
        isFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload
        },
        isSuccess: (state, action) => {
            state.isLoading = false
            state.success = action.payload
        },
        setDepartments: (state, action) => {
            state.isLoading = false
            state.departments = action.payload
        }
    }
})

export default departmentsSlice.reducer
