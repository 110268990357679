// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-picker {
    height: 38px !important;
}

.ant-input-number {
    height: 38px !important;
}

.ant-dropdown-trigger {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
}

#dateOfBirth_year {
    padding: 7px 11px!important;
}

/*div[class*="modalContactsRow"] {*/
/*    .ant-space-item:first-child {*/
/*        min-width: 100px;*/
/*    }*/

/*    .ant-space-item:nth-child(2) {*/
/*        flex: 1;*/
/*    }*/
/*}*/

div[class*="modalContactsRow"] {
    .ant-space-item:first-child {
        flex: 1 1;
    }
}
`, "",{"version":3,"sources":["webpack://./src/custom-antd.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;IACR,mBAAmB;AACvB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA,mCAAmC;AACnC,oCAAoC;AACpC,4BAA4B;AAC5B,QAAQ;;AAER,qCAAqC;AACrC,mBAAmB;AACnB,QAAQ;AACR,IAAI;;AAEJ;IACI;QACI,SAAO;IACX;AACJ","sourcesContent":[".ant-picker {\n    height: 38px !important;\n}\n\n.ant-input-number {\n    height: 38px !important;\n}\n\n.ant-dropdown-trigger {\n    display: flex;\n    flex-direction: row;\n    gap: 4px;\n    align-items: center;\n}\n\n#dateOfBirth_year {\n    padding: 7px 11px!important;\n}\n\n/*div[class*=\"modalContactsRow\"] {*/\n/*    .ant-space-item:first-child {*/\n/*        min-width: 100px;*/\n/*    }*/\n\n/*    .ant-space-item:nth-child(2) {*/\n/*        flex: 1;*/\n/*    }*/\n/*}*/\n\ndiv[class*=\"modalContactsRow\"] {\n    .ant-space-item:first-child {\n        flex: 1;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
