import React from 'react'
import styles from "../../styles/MyWorkspacesList.module.scss";
import styles2 from "../../styles/WorkspaceListItemBig.module.scss"
import WorkspaceListItemBig from "./WorkspaceListItemBig";
import {useAppSelector} from "../../hooks/redux";
import {Spin} from "antd";

interface handleAddNewModal {
    handleAddNewModal: (open: boolean) => void
}

const MyWorkspacesList = ({handleAddNewModal}: handleAddNewModal) => {

    const {workspaces, isLoading, error} = useAppSelector(state => state.workspacesReducer);

    return (
        <Spin spinning={isLoading}>
            <div className={styles.workspacesContainer}>
                {
                    workspaces.map((workspace) => (
                        <WorkspaceListItemBig key={workspace?.id} id={workspace?.id} name={workspace?.name}/>
                    ))
                }

                <div className={styles2.workspaceItem} onClick={() => handleAddNewModal(true)}>
                    <div className={styles2.workspaceImgContainer}>
                        <div style={{background: "#E2E2E2"}} className={styles2.workspaceBg}/>
                        <span style={{fontSize: "50px", fontWeight: "700"}}>+</span>
                    </div>
                </div>
            </div>
        </Spin>
    )
}
export default MyWorkspacesList
