import {AppDispatch} from "../index";
import api from "../../helpers/api";
import {getErrorMessage} from "../../helpers";
import {recruitmentSlice} from "../reducers/recruitmentSlice";

export const startRecruitment = (data: {
    workspaceId: string,
    workTaskId: string,
    candidateId: string
}) => async (dispatch: AppDispatch) => {
    try {
        dispatch(recruitmentSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/recruiting-processes/start`, data)

        if (response.data.succeeded) {
            dispatch(recruitmentSlice.actions.isSuccess("Процесс рекрутинга начат"))
        } else {
            dispatch(recruitmentSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(recruitmentSlice.actions.isFailure(message))
    }
}

export const removeRecruitment = (data: {
    workspaceId: string,
    recruitmentProcessId: string
}) => async (dispatch: AppDispatch) => {
    try {
        dispatch(recruitmentSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/recruiting-processes/remove`, data)

        if (response.data.succeeded) {
            dispatch(recruitmentSlice.actions.isSuccess("Процесс рекрутинга архивирован"))
        } else {
            dispatch(recruitmentSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(recruitmentSlice.actions.isFailure(message))
    }
}


export const updateResponsible = (data: {
    workspaceId: string,
    recruitmentProcessId: string,
    newResponsibleUserId: string
}) => async (dispatch: AppDispatch) => {
    try {
        dispatch(recruitmentSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/recruiting-processes/update-responsible`, data)

        if (response.data.succeeded) {
            dispatch(recruitmentSlice.actions.isSuccess("Ответственный в процессе рекрутинга изменен"))
        } else {
            dispatch(recruitmentSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(recruitmentSlice.actions.isFailure(message))
    }
}

export const startRecruitmentStage = (data: {
    workspaceId: string,
    recruitmentProcessId: string,
    recruitmentProcessStageId: string,
    //stageParticipantUserIds: [],
    // stageScheduleDateTime: {
    //     name: string,
    //     description: string,
    //     scheduledAtUtc: string,
    //     location: string
    // }
}) => async (dispatch: AppDispatch) => {
    try {
        dispatch(recruitmentSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/recruiting-processes/stages/start`, data)

        if (response.data.succeeded) {
            dispatch(recruitmentSlice.actions.isSuccess("Этап успешно запущен"))
        } else {
            dispatch(recruitmentSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(recruitmentSlice.actions.isFailure(message))
    }
}

export const rejectCandidateInStage = (data: {
    workspaceId: string,
    recruitmentProcessId: string,
    recruitmentProcessStageId: string,
    rejectionReasonId: string
}) => async (dispatch: AppDispatch) => {
    try {
        dispatch(recruitmentSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/recruiting-processes/stages/reject`, data)

        if (response.data.succeeded) {
            dispatch(recruitmentSlice.actions.isSuccess("Кандидат успешно отклонен"))
        } else {
            dispatch(recruitmentSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(recruitmentSlice.actions.isFailure(message))
    }
}

export const completeStage = (data: {
    workspaceId: string,
    recruitmentProcessId: string,
    recruitmentProcessStageId: string
}) => async (dispatch: AppDispatch) => {
    try {
        dispatch(recruitmentSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/recruiting-processes/stages/complete`, data)

        if (response.data.succeeded) {
            dispatch(recruitmentSlice.actions.isSuccess("Этап успешно завершен"))
        } else {
            dispatch(recruitmentSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(recruitmentSlice.actions.isFailure(message))
    }
}

export const addComment = (data: {
    workspaceId: string,
    recruitmentProcessStageId: string,
    comment: string
}) => async (dispatch: AppDispatch) => {
    try {
        dispatch(recruitmentSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/recruiting-processes/stages/${data.recruitmentProcessStageId}/comments/add?workspaceId=${data.workspaceId}`, {message: data?.comment},
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            })

        if (response.data.succeeded) {
            dispatch(recruitmentSlice.actions.isSuccess("Комментарий успешно добавлен"))
        } else {
            dispatch(recruitmentSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(recruitmentSlice.actions.isFailure(message))
    }
}

export const removeComment = (data: {
    workspaceId: string,
    recruitmentProcessStageId: string,
    commentId: string
}) => async (dispatch: AppDispatch) => {
    try {
        dispatch(recruitmentSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/recruiting-processes/stages/${data.recruitmentProcessStageId}/comments/${data.commentId}/remove?workspaceId=${data.workspaceId}`, data)

        if (response.data.succeeded) {
            dispatch(recruitmentSlice.actions.isSuccess("Комментарий успешно удален"))
        } else {
            dispatch(recruitmentSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(recruitmentSlice.actions.isFailure(message))
    }
}

export const uploadAttachment = (data: {
    workspaceId: string,
    recruitmentProcessStageId: string,
    file: string
}) => async (dispatch: AppDispatch) => {
    try {
        dispatch(recruitmentSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/recruiting-processes/stages/${data.recruitmentProcessStageId}/attachments/upload`, data)

        if (response.data.succeeded) {
            dispatch(recruitmentSlice.actions.isSuccess("Вложение успешно добавлено"))
        } else {
            dispatch(recruitmentSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(recruitmentSlice.actions.isFailure(message))
    }
}

export const removeAttachment = (data: {
    workspaceId: string,
    recruitmentProcessStageId: string,
    attachmentId: string
}) => async (dispatch: AppDispatch) => {
    try {
        dispatch(recruitmentSlice.actions.fileIsLoading())
        //dispatch(recruitmentSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/recruiting-processes/stages/${data.recruitmentProcessStageId}/attachments/${data.attachmentId}/remove?workspaceId=${data.workspaceId}`, data)

        if (response.data.succeeded) {
            dispatch(recruitmentSlice.actions.isFileLoaded())
            dispatch(recruitmentSlice.actions.isSuccess("Вложение успешно удалено"))
        } else {
            dispatch(recruitmentSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(recruitmentSlice.actions.isFailure(message))
    }
}


export const addAssessment = (data: {
    workspaceId: string,
    recruitmentProcessStageId: string,
    assessment: {
        metricId: number,
        value: number
    }
}) => async (dispatch: AppDispatch) => {
    try {
        dispatch(recruitmentSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/recruiting-processes/stages/${data.recruitmentProcessStageId}/assessments/add?workspaceId=${data.workspaceId}`, data.assessment)

        if (response.data.succeeded) {
            dispatch(recruitmentSlice.actions.isSuccess("Оценка успешно изменена"))
        } else {
            dispatch(recruitmentSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(recruitmentSlice.actions.isFailure(message))
    }
}

export const getRecruitmentStages = (data: {
    workspaceId: string,
    recruitmentProcessId: string,
}) => async (dispatch: AppDispatch) => {
    try {
        dispatch(recruitmentSlice.actions.isLoading())

        const response = await api.get(`${process.env.REACT_APP_API_URI_HTTPS}/api/recruiting-processes/stages?WorkspaceId=${data.workspaceId}&RecruitmentProcessId=${data.recruitmentProcessId}`)

        if (response.data.succeeded) {
            dispatch(recruitmentSlice.actions.getStages(response.data.value))
        } else {
            dispatch(recruitmentSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(recruitmentSlice.actions.isFailure(message))
    }
}

export const getStageDetails = (data: {
    workspaceId: string,
    recruitmentProcessStageId: string
}) => async (dispatch: AppDispatch) => {
    try {
        dispatch(recruitmentSlice.actions.isLoading())

        const response = await api.get(`${process.env.REACT_APP_API_URI_HTTPS}/api/recruiting-processes/stages/${data.recruitmentProcessStageId}/details?WorkspaceId=${data.workspaceId}`)

        if (response.data.succeeded) {
            dispatch(recruitmentSlice.actions.getStageDetails(response.data.value))
        } else {
            dispatch(recruitmentSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(recruitmentSlice.actions.isFailure(message))
    }
}

export const getComments = (data: {
    workspaceId: string,
    recruitmentProcessStageId: string
}) => async (dispatch: AppDispatch) => {
    try {
        dispatch(recruitmentSlice.actions.isLoading())

        const response = await api.get(`${process.env.REACT_APP_API_URI_HTTPS}/api/recruiting-processes/stages/${data.recruitmentProcessStageId}/comments?WorkspaceId=${data.workspaceId}`)

        if (response.data.succeeded) {
            dispatch(recruitmentSlice.actions.getStageComments(response.data.value))
        } else {
            dispatch(recruitmentSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(recruitmentSlice.actions.isFailure(message))
    }
}

export const getAttachments = (data: {
    workspaceId: string,
    recruitmentProcessStageId: string
}) => async (dispatch: AppDispatch) => {
    try {
        dispatch(recruitmentSlice.actions.isLoading())

        const response = await api.get(`${process.env.REACT_APP_API_URI_HTTPS}/api/recruiting-processes/stages/${data.recruitmentProcessStageId}/attachments?WorkspaceId=${data.workspaceId}`)

        if (response.data.succeeded) {
            dispatch(recruitmentSlice.actions.getStageAttachments(response.data.value))
        } else {
            dispatch(recruitmentSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(recruitmentSlice.actions.isFailure(message))
    }
}

export const getAttachmentFile = (data: {
    workspaceId: string,
    recruitmentProcessStageId: string,
    attachmentId: string
}) => async (dispatch: AppDispatch) => {
    try {
        dispatch(recruitmentSlice.actions.fileIsLoading())

        const response = await api.get(`${process.env.REACT_APP_API_URI_HTTPS}/api/recruiting-processes/stages/${data.recruitmentProcessStageId}/attachments/${data.attachmentId}?WorkspaceId=${data.workspaceId}`,
            {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/octet-stream'
                }
            })

        //const filename = response.headers['content-disposition'].split(';')[1].replace(/"/g, '')
        const filename = response.headers['content-disposition'].split(';')[2].replace(/"/g, '')
        console.log(filename)
        const extension = filename.split('.').slice(-1)[0]
        const utfName = decodeURIComponent(filename.split('.')[0].split('=')[1].split('UTF-8')[1].slice(2))
        const href = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `${utfName}.${extension}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        dispatch(recruitmentSlice.actions.isFileLoaded())
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(recruitmentSlice.actions.isFailure(message))
    }
}

export const getAssessments = (data: {
    workspaceId: string,
    recruitmentProcessStageId: string
}) => async (dispatch: AppDispatch) => {
    try {
        dispatch(recruitmentSlice.actions.isLoading())

        const response = await api.get(`${process.env.REACT_APP_API_URI_HTTPS}/api/recruiting-processes/stages/${data.recruitmentProcessStageId}/assessments?WorkspaceId=${data.workspaceId}`)

        if (response.data.succeeded) {
            dispatch(recruitmentSlice.actions.getStageAssessments(response.data.value))
        } else {
            dispatch(recruitmentSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(recruitmentSlice.actions.isFailure(message))
    }
}

export const setCurrentProcess = (data: any) => async (dispatch: AppDispatch) => {
    dispatch(recruitmentSlice.actions.setCurrentProcess(data))
}

export const getRecruitingProcesses = (data: {
    workspaceId: string,
    workTaskId: string
}) => async (dispatch: AppDispatch) => {
    try {
        dispatch(recruitmentSlice.actions.isLoading())

        const response = await api.get(`${process.env.REACT_APP_API_URI_HTTPS}/api/recruiting-processes?WorkspaceId=${data.workspaceId}&WorkTaskId=${data.workTaskId}&PageNumber=1&PageSize=100`)

        if (response.data.succeeded) {
            dispatch(recruitmentSlice.actions.getAllRecruitments(response.data.value))


            if (response.data.value.items.length > 0) {
                return response.data.value.items
            }
            else return null

        } else {
            dispatch(recruitmentSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(recruitmentSlice.actions.isFailure(message))
    }
}
