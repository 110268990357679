import {createSlice} from "@reduxjs/toolkit";

interface ParticipantsSlice {
    participants: any;
    requests: any;
    invites: any;
    isLoading: boolean;
    error: any;
    success: any;
}

const initialState: ParticipantsSlice = {
    participants: [],
    requests: [],
    invites: [],
    isLoading: false,
    error: null,
    success: null
}

export const participantsSlice = createSlice({
    name: "participants",
    initialState,
    reducers: {
        isLoading: (state) => {
            state.isLoading = true
            state.error = null
            state.success = null
        },
        isFailure: (state, action) => {
            state.isLoading = false
            state.error = action.payload
        },
        isSuccess: (state, action) => {
            state.isLoading = false
            state.success = action.payload
        },
        getParticipants: (state, action) => {
            state.isLoading = false
            state.participants = action.payload
        },
        getRequests: (state, action) => {
            state.isLoading = false
            state.requests = action.payload
        },
        getInvites: (state, action) => {
            state.isLoading = false
            state.invites = action.payload
        }
    }
})

export default participantsSlice.reducer
