import {combineReducers, configureStore} from "@reduxjs/toolkit";
import authReducer from "./reducers/authSlice"
import workspacesReducer from "./reducers/workspacesSlice"
import participantsReducer from "./reducers/participantsSlice"
import tasksReducer from "./reducers/tasksSlice"
import vacanciesReducer from "./reducers/vacanciesSlice"
import candidatesReducer from "./reducers/candidatesSlice"
import companiesReducer from "./reducers/companiesSlice"
import groupsReducer from "./reducers/groupsSlice"
import stagesReducer from "./reducers/stagesSlice"
import templatesReducer from "./reducers/templatesSlice"
import departmentsReducer from "./reducers/departmentsSlice"
import rejectionReasonsReducer from "./reducers/rejectionReasonsSlice"
import recruitmentReducer from "./reducers/recruitmentSlice"


const rootReducer = combineReducers({
    authReducer,
    workspacesReducer,
    participantsReducer,
    tasksReducer,
    vacanciesReducer,
    candidatesReducer,
    companiesReducer,
    groupsReducer,
    stagesReducer,
    templatesReducer,
    departmentsReducer,
    rejectionReasonsReducer,
    recruitmentReducer
})


export const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
    })
}


export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch']
