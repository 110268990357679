import React, {useEffect, useState} from 'react'
import {Button, FloatButton, Form, Input, message, Modal} from "antd";
import styles from '../../styles/WorkspaceAfterAuthPage.module.scss'
import MyWorkspacesList from "./MyWorkspacesList";
import WorkspaceSearch from "./WorkspaceSearch";
import {useForm} from "antd/es/form/Form";
import MyJoinRequestsList from "./MyJoinRequestsList";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {
    addNewWorkspace,
    createJoinRequest,
    getJoinRequests,
    getMyWorkspaces
} from "../../redux/actions/WorkspaceActions";
import {useNavigate} from "react-router-dom";
import {LogoutOutlined} from "@ant-design/icons";
import {logout} from "../../redux/actions/AuthActions";
import {useImprovedUXValidation} from "../../hooks/useImprovedUXValidation";

const WorkspaceAfterAuthPage = () => {

    const {onFinishFailed, validationMode, onBlur} = useImprovedUXValidation(['alias', 'name'])
    const navigate = useNavigate()
    const [messageApi, contextHolder] = message.useMessage();
    const [isJoinRequestModalOpen, setIsJoinRequestModalOpen] = useState({open: false, workspaceId: ''})
    const [isAddNewModalOpen, setIsAddNewModalOpen] = useState(false)
    const [joinRequestForm] = useForm()
    const [addNewWorkspaceForm] = useForm()


    const {joinRequests, error} = useAppSelector(state => state.workspacesReducer);
    const dispatch = useAppDispatch();

    const handleJoinRequestModal = (open: boolean, workspaceId: string) => {
        setIsJoinRequestModalOpen({open: open, workspaceId: workspaceId})
    }

    const handleAddNewModal = (open: boolean) => {
        setIsAddNewModalOpen(open)
    }

    useEffect(() => {
        dispatch(getMyWorkspaces())
        dispatch(getJoinRequests())
    }, []);

    useEffect(() => {
        if (error) {
            messageApi.open({
                type: 'error',
                content: error,
            });
        }
    }, [error])
    return (
        <>
            {contextHolder}
            <div className={styles.container}>
                <div className={styles.containerInner}>
                    <img src="/exp_logo_text_sm.svg" alt="logo_sm" className={styles.logo}/>
                    <h1>Выбери рабочее пространство</h1>

                    <MyWorkspacesList handleAddNewModal={handleAddNewModal}/>
                    <WorkspaceSearch handleJoinRequestModal={handleJoinRequestModal}/>
                    {joinRequests.length ? <MyJoinRequestsList/> : null}
                </div>
            </div>
            <FloatButton icon={<LogoutOutlined/>} type='primary' onClick={() => {
                dispatch(logout())
                navigate('/login')
            }}/>

            <Modal title="Запрос на вступление в рабочее пространство"
                   className={styles.modal}
                   open={isJoinRequestModalOpen.open}
                   zIndex={1053}
                   closeIcon={false}
                   styles={{
                       header: {
                           marginBottom: '12px'
                       },
                       footer: {
                           display: 'flex',
                           justifyContent: 'space-between'
                       }
                   }}
                   footer={[
                       <Button key="back"
                               onClick={() => setIsJoinRequestModalOpen(prev => ({open: !prev.open, workspaceId: ''}))}>
                           Назад
                       </Button>,
                       <Button type="primary" key="ok" onClick={() => {
                           joinRequestForm.submit()
                           setIsJoinRequestModalOpen(prev => ({open: !prev.open, workspaceId: ''}))
                       }}>
                           Отправить запрос на вступление
                       </Button>,
                   ]}
                   onCancel={() => setIsJoinRequestModalOpen(prev => ({open: !prev.open, workspaceId: ''}))}
            >
                <Form
                    form={joinRequestForm}
                    requiredMark='optional'
                    layout='vertical'
                    autoComplete='off'
                    onFinish={(values) => {
                        dispatch(createJoinRequest({
                            workspaceId: isJoinRequestModalOpen.workspaceId,
                            comment: values?.comment
                        }))
                        joinRequestForm.resetFields()
                    }}
                    style={{marginBottom: "16px"}}
                >
                    <Form.Item
                        label={<span className='inputLabel'>Комментарий</span>}
                        name="comment"
                    >
                        <Input.TextArea/>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal title="Создать новое рабочее пространство"
                   className={styles.modal}
                   open={isAddNewModalOpen}
                   zIndex={1053}
                   closeIcon={false}
                   styles={{
                       header: {
                           marginBottom: '12px'
                       },
                       footer: {
                           display: 'flex',
                           justifyContent: 'space-between'
                       }
                   }}
                   footer={[
                       <Button key="back" onClick={() => setIsAddNewModalOpen(prev => !prev)}>
                           Назад
                       </Button>,
                       <Button type="primary" key="ok" onClick={() => {
                           addNewWorkspaceForm.submit()
                           setIsAddNewModalOpen(prev => !prev)
                       }}>
                           Создать
                       </Button>,
                   ]}
                   onCancel={() => setIsAddNewModalOpen(prev => !prev)}
            >
                <Form
                    form={addNewWorkspaceForm}
                    requiredMark='optional'
                    layout='vertical'
                    autoComplete='off'
                    onFinishFailed={onFinishFailed}
                    onFinish={(values) => {
                        dispatch(addNewWorkspace(values))
                        addNewWorkspaceForm.resetFields()
                    }}
                    style={{marginBottom: "16px"}}
                >
                    <Form.Item
                        style={{marginBottom: "16px"}}
                        label={<span className='inputLabel'>Идентификатор</span>}
                        name="alias"
                        validateTrigger={validationMode.alias}
                        rules={[{required: true, message: 'Введите идентификатор'}]}
                    >
                        <div>
                            <Input placeholder="Например, ec_podbor" onBlur={onBlur('alias')}/>
                            <div className={styles.info}>
                                <img src="/info_icon.svg" alt="info_icon"/>
                                <span>Идентификатор используется для поиска рабочего пространства другими участниками</span>
                            </div>
                        </div>
                    </Form.Item>

                    <Form.Item
                        label={<span className='inputLabel'>Название</span>}
                        name="name"
                        validateTrigger={validationMode.name}
                        rules={[{required: true, message: 'Введите название рабочего пространства'}]}
                    >
                        <div>
                            <Input placeholder="Например, ЭЦ-Подбор" onBlur={onBlur('name')}/>
                            <div className={styles.info}>
                                <img src="/info_icon.svg" alt="info_icon"/>
                                <span>Отображаемое имя рабочего пространства</span>
                            </div>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default WorkspaceAfterAuthPage
