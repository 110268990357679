import React, {useEffect, useState} from 'react'
import styles from '../../styles/Vacancies/VacanciesMain.module.scss'
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {Button, Dropdown, Empty, Input, MenuProps, message, Modal, Pagination, Spin} from "antd";
import {CheckOutlined, DownOutlined, EditOutlined, FilterOutlined, FormOutlined, PlusOutlined} from "@ant-design/icons";
import VacanciesFilter from "./VacanciesFilter";
import {
    archiveVacancy,
    completeVacancy,
    getAllVacancies
} from "../../redux/actions/VacanciesActions";
import {IVacancy} from "../models/IVacancy";
import {formatDate, getVacancyStatus} from "../../helpers";
import {SearchProps} from "antd/es/input";
import {getAllCompanies} from "../../redux/actions/CompaniesActions";

const {Search} = Input;

const VacanciesMain = () => {

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [showFilter, setShowFilter] = useState(true)
    const [messageApi, contextHolder] = message.useMessage();
    const {vacancies, error, success, isLoading} = useAppSelector(state => state.vacanciesReducer)
    const {companies} = useAppSelector(state => state.companiesReducer)
    const {currentWorkspace} = useAppSelector(state => state.workspacesReducer)
    const [isArchiveModalOpen, setIsArchiveModalOpen] = useState({open: false, id: null})
    const [isCompleteModalOpen, setIsCompleteModalOpen] = useState({open: false, id: null})
    const [resetFilter, setResetFilter] = useState(false)

    useEffect(() => {
        if (error) {
            messageApi.open({
                type: 'error',
                content: error,
            });
        }

        if (success) {
            messageApi.open({
                type: 'success',
                content: success,
            });
        }
    }, [error, success])

    useEffect(() => {
        dispatch(getAllVacancies({
            workspaceId: currentWorkspace?.id,
            pageNumber: 1,
            pageSize: 8
        }))
        dispatch(getAllCompanies({pageNumber: 1, workspaceId: currentWorkspace?.id}))
    }, []);



    const vacanciesActions: MenuProps['items'] = [
        {
            label: <span className={styles.actionItem}><EditOutlined/>Редактировать</span>,
            key: '1',
        },
        {
            label: <span className={styles.actionItem}><img src="/archive_icon.svg" alt="edit"/>Архивировать</span>,
            key: '2',
        },
        {
            label: <span className={styles.actionItem}><CheckOutlined />Завершить</span>,
            key: '3',
        },
        {
            label: <span className={styles.actionItem}><FormOutlined />Создать задание на основе вакансии</span>,
            key: '4',
        },
    ];

    const handleActions = (key: any, id: any) => {
        switch (key) {
            case '1':
                navigate(`/vacancies/edit/${id}`)
                break;
            case '2':
                setIsArchiveModalOpen({open: true, id: id})
                break;
            case '3':
                setIsCompleteModalOpen({open: true, id: id})
                break;
            case '4':
                navigate(`/tasks/create?vacancyId=${id}`)
                break;
            default:
                break;
        }
    }

    const onSearch: SearchProps['onSearch'] = (value, _e, info) => {

        if (value === '') {
            dispatch(getAllVacancies({
                workspaceId: currentWorkspace?.id,
                searchString: '',
                pageNumber: 1,
                pageSize: 8
            }))
        }

        if (value.length !== 0 && value && value.indexOf(' ') < 0) {
            dispatch(getAllVacancies({
                workspaceId: currentWorkspace?.id,
                searchString: value,
                pageNumber: 1,
                pageSize: 8
            }))
        }
    };

    const onPageNumberChange = (value: any) => {
        dispatch(getAllVacancies({
            workspaceId: currentWorkspace?.id,
            pageNumber: value,
            pageSize: 8
        }))
    }

    return (
        <>
            {contextHolder}
            <div className={styles.container}>
                <div className={styles.titleContainer}>
                    <div className={styles.titleInnerContainer}>
                        <img src="/document_icon.svg" alt="Задания"/>
                        <h3>Вакансии</h3>
                    </div>

                    <div className={styles.buttonsContainer}>
                        <Button icon={<FilterOutlined/>} onClick={() => setShowFilter(prev => !prev)}/>
                        <Button type='primary' icon={<PlusOutlined/>} onClick={() => navigate('/vacancies/create')}>Создать
                            вакансию</Button>
                    </div>
                </div>

                <div className={styles.innerContainer}>
                    <VacanciesFilter resetFilter={resetFilter} showFilter={showFilter} onFilterChange={(data: any) => {
                        dispatch(getAllVacancies({
                            ...data,
                            workspaceId: currentWorkspace?.id,
                            pageNumber: 1,
                            pageSize: 8
                        }))
                    }}/>

                    <Spin spinning={isLoading} wrapperClassName={styles.tasksListSpinner}>
                        <div className={styles.listContainer}>
                            <div className={styles.listSearchContainer}>
                                <Search
                                    allowClear
                                    enterButton={false}
                                    onSearch={onSearch}
                                    placeholder="Введите название вакансии"
                                    loading={isLoading}
                                    style={{maxWidth: "350px"}}
                                />
                            </div>

                            <div className={styles.listInnerContainer}>
                                {
                                    vacancies?.items?.length !== 0 ?
                                        <>
                                            {
                                                vacancies?.items?.map((vacancy: IVacancy) => {
                                                    const {
                                                        id,
                                                        name,
                                                        vacancyStatus,
                                                        estimatedCompletedAtUtc,
                                                        hireQuantity,
                                                        companyId,
                                                        departmentName,
                                                        industryDirectionName
                                                    }: IVacancy = vacancy
                                                    return (
                                                        <div className={styles.listItem} key={id}>
                                                            <div className={styles.listItemHeading}>
                                                                <div className={styles.listItemTitleContainer}
                                                                     onClick={() => navigate(`/vacancies/${id}`)}>
                                                                    <div>{name}</div>
                                                                    {getVacancyStatus(vacancyStatus)}
                                                                </div>

                                                                <div className={styles.listItemActionsContainer}>
                                                                    <div className={styles.listItemDate}>
                                                                        Дата
                                                                        закрытия: {formatDate(estimatedCompletedAtUtc)}
                                                                    </div>

                                                                    {vacancyStatus === 1 &&
                                                                        <div className={styles.listItemActions}>
                                                                            {/*@ts-ignore*/}
                                                                            <Dropdown menu={{
                                                                                items: vacanciesActions,
                                                                                onClick: ({key}) => {
                                                                                    handleActions(key, id)
                                                                                }
                                                                            }}>
                                                                                <a onClick={(e) => {
                                                                                    e.preventDefault()
                                                                                    e.stopPropagation()

                                                                                }}>
                                                                                    Действия
                                                                                    <DownOutlined/>
                                                                                </a>
                                                                            </Dropdown>
                                                                        </div>}
                                                                </div>
                                                            </div>

                                                            <div className={styles.listItemBody}>
                                                                <div className={styles.listItemInfoContainer}>
                                                                    {companies?.items?.filter((company: any) => company?.id === companyId)[0]?.name &&
                                                                        <div className={styles.listItemInfoItem}>
                                                                            <img src="/vacancy_icon.svg" alt="Vacancy"/>
                                                                            <span>{companies?.items?.filter((company: any) => company?.id === companyId)[0].name}</span>
                                                                        </div>
                                                                    }

                                                                    <div className={styles.listItemInfoItem}>
                                                                        <img src="/profile_icon.svg" alt="Vacancy"/>
                                                                        <span>Количество позиций: <b>{hireQuantity}</b></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {
                                                vacancies?.totalCount > 8 &&
                                                <Pagination
                                                    onChange={onPageNumberChange}
                                                    defaultPageSize={8}
                                                    current={vacancies?.pageNumber}
                                                    total={vacancies?.totalCount}
                                                />
                                            }
                                        </> :
                                        <div>
                                            <Empty description='Вакансии отсутствуют'/>
                                        </div>
                                }
                            </div>
                        </div>
                    </Spin>
                </div>
            </div>

            <Modal
                title='Подтверждение операции'
                open={isArchiveModalOpen.open}
                okText="Да"
                cancelText="Отмена"

                onOk={() => {
                    setResetFilter(true)

                    dispatch(archiveVacancy({
                        workspaceId: currentWorkspace?.id,
                        vacancyId: isArchiveModalOpen?.id
                    }))
                        .then(() => {
                            dispatch(getAllVacancies({
                                workspaceId: currentWorkspace?.id,
                                pageNumber: 1,
                                pageSize: 8
                            }))

                            setIsArchiveModalOpen({open: false, id: null})
                        })
                        .then(() => setResetFilter(false))
                }}
                onCancel={() => setIsArchiveModalOpen(() => ({open: false, id: null}))}
            >
                Вы уверены, что хотите архивировать вакансию?
            </Modal>

            <Modal
                title='Подтверждение операции'
                open={isCompleteModalOpen.open}
                okText="Да"
                cancelText="Отмена"

                onOk={() => {
                    setResetFilter(true)
                    dispatch(completeVacancy({
                        workspaceId: currentWorkspace?.id,
                        vacancyId: isCompleteModalOpen.id,
                        vacancyStatus: 3
                    }))
                        .then(() => {
                            dispatch(getAllVacancies({
                                workspaceId: currentWorkspace?.id,
                                pageNumber: 1,
                                pageSize: 8
                            }))

                            setIsCompleteModalOpen({open: false, id: null})
                        })
                        .then(() => setResetFilter(false))
                }}
                onCancel={() => setIsCompleteModalOpen(() => ({open: false, id: null}))}
            >
                Вы уверены, что хотите закрыть вакансию?
            </Modal>
        </>
    )
}

export default VacanciesMain
