import axios from 'axios';


const api = axios.create();

api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('access_token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);


api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        console.log(error?.response)

        if (error?.response?.status || error?.message === "Network Error") {
            if ((error?.response?.status === 401 && !originalRequest._retry) || error?.message === "Network Error") {
                originalRequest._retry = true;

                try {
                    const refreshToken = JSON.parse(localStorage.getItem('refresh_token'))
                    const response = await axios.post(`${process.env.REACT_APP_API_URI_HTTPS}/connect/token`, new URLSearchParams({
                        client_id: process.env.REACT_APP_CLIENT_ID,
                        redirect_uri: process.env.REACT_APP_REDIRECT_URI,
                        response_type: 'code',
                        nonce: 'xxadadass',
                        scope: 'usermanager.access worktasks.access openid profile offline_access',
                        grant_type: 'refresh_token',
                        refresh_token: refreshToken
                    }))

                    //const response = await axios.post('/api/refresh-token', { refreshToken });
                    const { access_token, refresh_token } = response.data;

                    localStorage.setItem('access_token', access_token);
                    localStorage.setItem('refresh_token', refresh_token);

                    // Retry the original request with the new token
                    originalRequest.headers.Authorization = `Bearer ${access_token}`;
                    return axios(originalRequest);
                } catch (error) {
                    window.location.href = 'https://echire.ru/login'
                    // Handle refresh token error or redirect to login
                }
            }
        }

        return Promise.reject(error);
    }
);



export default api
