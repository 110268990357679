import {createSlice} from "@reduxjs/toolkit";

interface TasksSlice {
    tasks: any;
    currentTask: any;
    isLoading: boolean;
    error: any;
    success: any
}

const initialState: TasksSlice = {
    tasks: [],
    currentTask: null,
    isLoading: false,
    error: null,
    success: null
}


export const tasksSlice = createSlice({
    name: "tasks",
    initialState,
    reducers: {
        isLoading: (state) => {
            state.isLoading = true
            state.error = null
            state.success = null
            state.currentTask = null
            state.tasks = null
        },
        isFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload
        },
        isSuccess: (state, action) => {
            state.isLoading = false
            state.success = action.payload
        },
        getTasks: (state, action) => {
            state.isLoading = false
            state.tasks = action.payload
        },
        setCurrentTask: (state, action) => {
            state.isLoading = false;
            state.currentTask = action.payload
        }
    }
})

export default tasksSlice.reducer
