// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/bg_auth_layout.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AuthLayout_container__mhq0V {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr 1fr;

}

.AuthLayout_innerContainer__htOu\\+ {
    background-color: var(--white);
    display: flex;
    justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/styles/AuthLayout.module.css"],"names":[],"mappings":"AAAA;IACI,yDAAwD;IACxD,sBAAsB;IACtB,aAAa;IACb,aAAa;IACb,8BAA8B;;AAElC;;AAEA;IACI,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;AAC3B","sourcesContent":[".container {\n    background-image: url(\"../../public/bg_auth_layout.svg\");\n    background-size: cover;\n    height: 100vh;\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n\n}\n\n.innerContainer {\n    background-color: var(--white);\n    display: flex;\n    justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AuthLayout_container__mhq0V`,
	"innerContainer": `AuthLayout_innerContainer__htOu+`
};
export default ___CSS_LOADER_EXPORT___;
