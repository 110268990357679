import React, {useEffect} from 'react'
import {Button, Form, Input, message} from "antd";
import {FieldType} from "../../Types";
import styles from "../../styles/Auth/RegisterPage.module.scss";
import {useNavigate} from "react-router-dom";
import {clearSuccessMessage, setPasswordResetData} from "../../redux/actions/AuthActions";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {useImprovedUXValidation} from "../../hooks/useImprovedUXValidation";

const PasswordReset = () => {

    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();

    const {onFinishFailed, validationMode, onBlur} = useImprovedUXValidation(['email'])
    const {isLoading, error, success} = useAppSelector(state => state.authReducer);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (error) {
            messageApi.open({
                type: 'error',
                content: error,
            });
        }
        if (success) {
            messageApi.open({
                type: 'success',
                content: success,
            });

            dispatch(clearSuccessMessage())
        }
    }, [error, success])

    return (
        <>
            {contextHolder}
            <div className={styles.container}>
                <div className={styles.containerInner}>
                    <img src="/exp_logo_text_sm.svg" alt="logo_sm" className={styles.logo}/>
                    <div className={styles.headingContainer}>
                        <img src="/arrow_left.svg" alt="arrow_left" onClick={() => navigate('/login')}/>
                        <h1>Сброс пароля</h1>
                    </div>

                    <Form
                        requiredMark='optional'
                        layout='vertical'
                        autoComplete='off'
                        onFinishFailed={onFinishFailed}
                        onFinish={(values) => {
                            console.log(values)
                            // dispatch(checkEmail(values?.email))
                            //     .then((isAvailable) => {
                            //         if (isAvailable) {
                            //             dispatch(setPasswordResetData(values)).then(success => {
                            //                 if (success) {
                            //                     navigate('/password/reset/confirm')
                            //                 }
                            //             })
                            //         }
                            //     })
                            dispatch(setPasswordResetData(values)).then(success => {
                                if (success) {
                                    navigate('/password/reset/confirm')
                                }
                            })
                        }}
                    >
                        <Form.Item<FieldType>
                            label={<span className='inputLabel'>Адрес электронной почты</span>}
                            name="email"
                            validateTrigger={validationMode.email}
                            rules={[{required: true, type: 'email', message: 'Введите адрес электронной почты'}]}
                        >
                            <Input placeholder='ivanov_ivan@gmail.com' onBlur={onBlur('email')}/>
                        </Form.Item>

                        <Form.Item>
                            <Button
                                loading={isLoading}
                                type="primary"
                                htmlType="submit"
                                className={styles.loginButton}
                            >
                                Отправить письмо для сброса пароля
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default PasswordReset
