import {createSlice} from "@reduxjs/toolkit";

interface RecruitmentSlice {
    stages: [];
    stageDetails: null;
    stageComments: null;
    stageAttachments: null;
    stageAssessments: null;
    isLoading: boolean;
    isFileLoading: boolean;
    error: any;
    success: any;
    allRecruitments: [];
    currentProcess: null
}

const initialState: RecruitmentSlice = {
    stages: [],
    stageDetails: null,
    stageComments: null,
    stageAttachments: null,
    stageAssessments: null,
    isLoading: false,
    isFileLoading: false,
    error: null,
    success: null,
    allRecruitments: [],
    currentProcess: null
}

export const recruitmentSlice = createSlice({
    name: "recruitment",
    initialState,
    reducers: {
        fileIsLoading: (state) => {
          state.isFileLoading = true
        },
        isFileLoaded: (state) => {
            state.isFileLoading = false
        },
        isLoading: (state) => {
            state.isLoading = true
            state.error = null
            state.success = null
        },
        isFailure: (state, action) => {
            state.isLoading = false
            state.isFileLoading = false
            state.error = action.payload
        },
        isSuccess: (state, action) => {
            state.isLoading = false
            state.isFileLoading = false
            state.success = action.payload
        },
        getStages: (state, action) => {
            state.isLoading = false
            state.isFileLoading = false
            state.stages = action.payload
        },
        getStageDetails: (state, action) => {
            state.isLoading = false
            state.isFileLoading = false
            state.stageDetails = action.payload
        },
        getStageComments: (state, action) => {
            state.isLoading = false
            state.isFileLoading = false
            state.stageComments = action.payload
        },
        getStageAttachments: (state, action) => {
            state.isLoading = false
            state.isFileLoading = false
            state.stageAttachments = action.payload
        },
        getStageAssessments: (state, action) => {
            state.isLoading = false
            state.isFileLoading = false
            state.stageAssessments = action.payload
        },
        getAllRecruitments: (state, action) => {
            state.isLoading = false
            state.isFileLoading = false
            state.allRecruitments = action.payload
        },
        setCurrentProcess: (state, action) => {
            state.isLoading = false
            state.isFileLoading = false
            state.currentProcess = action.payload
        }
    }
})

export default recruitmentSlice.reducer
