import {AppDispatch} from "../index";
import {getErrorMessage} from "../../helpers";
import {departmentsSlice} from "../reducers/departmentsSlice";
import {candidatesSlice} from "../reducers/candidatesSlice";
import api from "../../helpers/api";

export const getAllDepartments = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(departmentsSlice.actions.isLoading())

        const response = await api.get(`${process.env.REACT_APP_API_URI_HTTPS}/api/departments?WorkspaceId=${data.workspaceId}&CompanyId=${data.companyId}&PageNumber=${data.pageNumber}&PageSize=8`)

        if (response.data.succeeded) {
            dispatch(departmentsSlice.actions.setDepartments(response.data.value))
        } else {
            dispatch(departmentsSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(departmentsSlice.actions.isFailure(message))
    }
}

export const getArchivedDepartments = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(departmentsSlice.actions.isLoading())

        const response = await api.get(`${process.env.REACT_APP_API_URI_HTTPS}/api/departments/archived?WorkspaceId=${data.workspaceId}&CompanyId=${data.companyId}&PageNumber=${data.pageNumber}&PageSize=8`)

        if (response.data.succeeded) {
            dispatch(departmentsSlice.actions.setDepartments(response.data.value))
        } else {
            dispatch(departmentsSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(departmentsSlice.actions.isFailure(message))
    }
}

export const createDepartment = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(departmentsSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/departments/create`, data)

        if (response.data.succeeded) {
            dispatch(departmentsSlice.actions.isSuccess("Отдел успешно добавлен"))
        } else {
            dispatch(departmentsSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(candidatesSlice.actions.isFailure(message))
    }
}

export const updateDepartment = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(departmentsSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/departments/update`, data)

        if (response.data.succeeded) {
            dispatch(departmentsSlice.actions.isSuccess("Отдел успешно изменён"))
        } else {
            dispatch(departmentsSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(candidatesSlice.actions.isFailure(message))
    }
}

export const deleteDepartment = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(departmentsSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/departments/delete`, data)

        if (response.data.succeeded) {
            dispatch(departmentsSlice.actions.isSuccess("Отдел успешно удалён"))
        } else {
            dispatch(departmentsSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(candidatesSlice.actions.isFailure(message))
    }
}

export const restoreDepartment = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(departmentsSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/departments/restore`, data)

        if (response.data.succeeded) {
            dispatch(departmentsSlice.actions.isSuccess("Отдел успешно восстановлен"))
        } else {
            dispatch(departmentsSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(candidatesSlice.actions.isFailure(message))
    }
}
