import {AppDispatch} from "../index";
import {getErrorMessage} from "../../helpers";
import {tasksSlice} from "../reducers/tasksSlice";
import axios from "axios";
import {ITask} from "../../Components/models/ITask";
import api from "../../helpers/api";

export const getAllTasks = (data: {workspaceId: string, status: number, pageNumber: number, pageSize: number}) => async (dispatch: AppDispatch) => {
    try {
        dispatch(tasksSlice.actions.isLoading())

        const response = await api.get(`${process.env.REACT_APP_API_URI_HTTPS}/api/tasks/by-filter?WorkspaceId=${data.workspaceId}&IsFinished=${!!data.status}&PageNumber=${data.pageNumber}&PageSize=${data.pageSize}`)

        if (response.data.succeeded) {
            dispatch(tasksSlice.actions.getTasks(response.data.value))
        } else {
            dispatch(tasksSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(tasksSlice.actions.isFailure(message))
    }
}

export const getFilteredTasks = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(tasksSlice.actions.isLoading())
        const response = await api.get(`${process.env.REACT_APP_API_URI_HTTPS}/api/tasks/by-filter`,
            {
                // @ts-ignore
                params: new URLSearchParams(data)
            })

        if (response.data.succeeded) {
            dispatch(tasksSlice.actions.getTasks(response.data.value))
        } else {
            dispatch(tasksSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(tasksSlice.actions.isFailure(message))
    }
}

export const createTask = (task: ITask) => async (dispatch: AppDispatch) => {
    try {
        dispatch(tasksSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/tasks/create`, task)

        if (response.data.succeeded) {
            dispatch(tasksSlice.actions.isSuccess('Задание успешно создано'))
        } else {
            dispatch(tasksSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(tasksSlice.actions.isFailure(message))
    }
}

export const updateTask = (task: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(tasksSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/tasks/update`, task)

        if (response.data.succeeded) {
            dispatch(tasksSlice.actions.isSuccess('Задание успешно обновлено'))
        } else {
            dispatch(tasksSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(tasksSlice.actions.isFailure(message))
    }
}

export const completeTask = (data: {workspaceId: string, workTaskId: any}) => async (dispatch: AppDispatch) => {
    try {
        dispatch(tasksSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/tasks/complete`, data)


        if (response.data.succeeded) {
            dispatch(tasksSlice.actions.isSuccess('Задание успешно завершено'))
        } else {
            dispatch(tasksSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(tasksSlice.actions.isFailure(message))
    }
}

export const cancelTask = (data: {workspaceId: string, workTaskId: any}) => async (dispatch: AppDispatch) => {
    try {
        dispatch(tasksSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/tasks/cancel`, data)


        if (response.data.succeeded) {
            dispatch(tasksSlice.actions.isSuccess('Задание успешно отменено'))
        } else {
            dispatch(tasksSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(tasksSlice.actions.isFailure(message))
    }
}

export const archiveTask = (data: {workspaceId: string, workTaskId: any}) => async (dispatch: AppDispatch) => {
    try {
        dispatch(tasksSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/tasks/archive`, data)

        if (response.data.succeeded) {
            dispatch(tasksSlice.actions.isSuccess('Задание успешно архивировано'))
        } else {
            dispatch(tasksSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(tasksSlice.actions.isFailure(message))
    }
}


export const getTaskById = (data: {workspaceId: string, id: any}) => async (dispatch: AppDispatch) => {
    try {
        dispatch(tasksSlice.actions.isLoading())

        const response = await api.get(`${process.env.REACT_APP_API_URI_HTTPS}/api/tasks/details?WorkTaskId=${data.id}&WorkspaceId=${data.workspaceId}`)


        if (response.data.succeeded) {
            dispatch(tasksSlice.actions.setCurrentTask(response.data.value))
        } else {
            dispatch(tasksSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(tasksSlice.actions.isFailure(message))
    }
}
