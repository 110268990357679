import {useEffect, useState} from "react";

type setterFn = (a: number) => void
type countDownFn = (minutes: number) => [timer, setterFn]
type timer = {
    minutes: string,
    seconds: string,
    disabled: boolean
}

const useCountdown:countDownFn = (mins: number) => {
    const [count, setCount] = useState(mins * 60)

    const updateValue = (newValue: number) => {
        setCount(newValue * 60)
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (count > 0) {
                setCount(prev => prev - 1)
            }
        }, 1000)

        return () => clearInterval(interval)
    }, [count])

    if (count <= 0) {
        const time = {minutes: '00', seconds: '00', disabled: false}
        return [time, updateValue]
    }
    else {
        const minutes = Math.floor(count / 60)
        const seconds = count % 60

        const time = {minutes: minutes < 10 ? `0${minutes}` : minutes.toString(), seconds: seconds < 10 ? `0${seconds}` : seconds.toString(), disabled: true}

        return [time, updateValue]
    }
}

export default useCountdown
