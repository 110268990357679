import {AppDispatch} from "../index";
import {getErrorMessage} from "../../helpers";
import {rejectionReasonsSlice} from "../reducers/rejectionReasonsSlice";
import api from "../../helpers/api";

export const getAllRejectionReasons = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(rejectionReasonsSlice.actions.isLoading())

        const response = await api.get(`${process.env.REACT_APP_API_URI_HTTPS}/api/rejection-reasons?WorkspaceId=${data.workspaceId}&PageNumber=${data.pageNumber}&PageSize=8`)

        if (response.data.succeeded) {
            dispatch(rejectionReasonsSlice.actions.setReasons(response.data.value))
        } else {
            dispatch(rejectionReasonsSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(rejectionReasonsSlice.actions.isFailure(message))
    }
}

export const getArchivedRejectionReasons = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(rejectionReasonsSlice.actions.isLoading())

        const response = await api.get(`${process.env.REACT_APP_API_URI_HTTPS}/api/rejection-reasons/archived?WorkspaceId=${data.workspaceId}&PageNumber=${data.pageNumber}&PageSize=8`)

        if (response.data.succeeded) {
            dispatch(rejectionReasonsSlice.actions.setReasons(response.data.value))
        } else {
            dispatch(rejectionReasonsSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(rejectionReasonsSlice.actions.isFailure(message))
    }
}

export const createRejectionReason = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(rejectionReasonsSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/rejection-reasons/create`,
            data)

        if (response.data.succeeded) {
            dispatch(rejectionReasonsSlice.actions.isSuccess("Причина отказа успешно добавлена"))
        } else {
            dispatch(rejectionReasonsSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(rejectionReasonsSlice.actions.isFailure(message))
    }
}

export const updateRejectionReason = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(rejectionReasonsSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/rejection-reasons/update`,
            data)

        if (response.data.succeeded) {
            dispatch(rejectionReasonsSlice.actions.isSuccess("Причина отказа успешно изменена"))
        } else {
            dispatch(rejectionReasonsSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(rejectionReasonsSlice.actions.isFailure(message))
    }
}

export const deleteRejectionReason = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(rejectionReasonsSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/rejection-reasons/delete`,
            data)

        if (response.data.succeeded) {
            dispatch(rejectionReasonsSlice.actions.isSuccess("Причина отказа успешно удалена"))
        } else {
            dispatch(rejectionReasonsSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(rejectionReasonsSlice.actions.isFailure(message))
    }
}

export const restoreRejectionReason = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(rejectionReasonsSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/rejection-reasons/restore`,
            data)

        if (response.data.succeeded) {
            dispatch(rejectionReasonsSlice.actions.isSuccess("Причина отказа успешно восстановлена"))
        } else {
            dispatch(rejectionReasonsSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(rejectionReasonsSlice.actions.isFailure(message))
    }
}
