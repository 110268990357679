import React, {useEffect, useState} from 'react'
import styles from '../../styles/Vacancies/VacancyPage.module.scss'
import {formatDate, getVacancyStatus} from "../../helpers";
import {Button, Input, message, Modal, Spin} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {archiveVacancy, completeVacancy, getVacancyById} from "../../redux/actions/VacanciesActions";
import {getAllCompanies} from "../../redux/actions/CompaniesActions";
import {getTemplates} from "../../redux/actions/TemplatesActions";
import {getAllDepartments} from "../../redux/actions/DepartmentsActions";
import {CheckOutlined, EditOutlined} from "@ant-design/icons";

const VacancyPage = () => {

    const {id} = useParams<{ id: string }>()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [messageApi, contextHolder] = message.useMessage();

    const {currentWorkspace} = useAppSelector(state => state.workspacesReducer)
    const {companies} = useAppSelector(state => state.companiesReducer)
    const {departments} = useAppSelector(state => state.departmentsReducer)
    const {currentVacancy, error, success, isLoading} = useAppSelector(state => state.vacanciesReducer)
    const [isArchiveModalOpen, setIsArchiveModalOpen] = useState({open: false, id: null})
    const [isCompleteModalOpen, setIsCompleteModalOpen] = useState({open: false, id: null})


    useEffect(() => {
        if (currentWorkspace?.id) {
            //@ts-ignore
            dispatch(getVacancyById({workspaceId: currentWorkspace.id, vacancyId: id}))
            dispatch(getAllCompanies({pageNumber: 1, workspaceId: currentWorkspace?.id}))
            dispatch(getTemplates({workspaceId: currentWorkspace?.id}))
        }
    }, []);

    useEffect(() => {
        if (currentVacancy) {
            dispatch(getAllDepartments({
                workspaceId: currentWorkspace?.id,
                companyId: currentVacancy?.companyId,
                pageNumber: 1
            }))
        }
    }, [currentVacancy]);

    useEffect(() => {
        if (error) {
            messageApi.open({
                type: 'error',
                content: error,
            });
        }

        if (success) {
            messageApi.open({
                type: 'success',
                content: success,
            });
        }
    }, [error, success])


    return (
        <>
            {contextHolder}
            <Spin spinning={isLoading} wrapperClassName={styles.vacancyListSpinner}>
                <div className={styles.container}>
                    <div className={styles.titleContainer}>
                        <div className={styles.titleInnerContainer}>
                            <img src="/document_icon.svg" alt="Иконка вакансии"/>
                            <h3>{currentVacancy?.name}</h3>
                            {getVacancyStatus(currentVacancy?.vacancyStatus)}
                        </div>

                        {currentVacancy?.vacancyStatus === 1 &&
                            <div className={styles.actionsContainer}>
                                <Button icon={<EditOutlined/>}
                                        onClick={() => navigate(`/vacancies/edit/${id}`)}>Редактировать</Button>
                                <Button icon={<img src="/archive_icon.svg" alt="edit"/>}
                                        onClick={() => setIsArchiveModalOpen(() => ({
                                            open: true,
                                            id: currentVacancy?.id
                                        }))}
                                >Архивировать</Button>
                                <Button icon={<CheckOutlined/>}
                                        onClick={() => setIsCompleteModalOpen(() => ({
                                            open: true,
                                            id: currentVacancy?.id
                                        }))}
                                >Завершить</Button>
                            </div>}
                    </div>

                    <div>
                        <div className={styles.filterForm}>
                            <div className={styles.descriptionContainer}>
                                <span className={styles.columnTitle}>Основная информация</span>

                                <div className={styles.innerFormContainer}>
                                    <div className={styles.item}>
                                        <span className={styles.label}>Название вакансии</span>
                                        <span>{currentVacancy?.name}</span>
                                    </div>

                                    <div className={styles.item}>
                                        <span className={styles.label}>Компания</span>
                                        <span>{companies?.items?.filter((company: any) => company?.id === currentVacancy?.companyId)[0]?.name}</span>
                                    </div>

                                    <div className={styles.item}>
                                        <span className={styles.label}>Отдел</span>
                                        <span>{departments?.items?.filter((department: any) => department?.id === currentVacancy?.departmentId)[0]?.name}</span>
                                    </div>

                                    <div className={styles.item}>
                                        <span className={styles.label}>Специализация</span>
                                        <span>–</span>
                                    </div>

                                    <div className={styles.formRow}>

                                        <div className={styles.item}>
                                            <span className={styles.label}>Количество кандидатов</span>
                                            <span>{currentVacancy?.hireQuantity}</span>
                                        </div>

                                        <div className={styles.item}>
                                            <span className={styles.label}>Дата закрытия</span>
                                            <span>{formatDate(currentVacancy?.estimatedCompletedAtUtc)}</span>
                                        </div>
                                    </div>

                                    <div className={styles.item}>
                                        <span className={styles.label}>Шаблон воронки</span>
                                        <span>-</span>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.vacancyContainer}>
                                <span className={styles.columnTitle}>О вакансии</span>

                                <div className={styles.item}>
                                    <span className={styles.label}>Выборка городов</span>
                                    {currentVacancy?.vacancyLocations?.map((item: any, key: number) => (
                                        <span key={key}>{item?.name}</span>
                                    ))}
                                </div>

                                <div className={styles.formRow}>
                                    <div className={styles.item}>
                                        <span className={styles.label}>Зарплата от</span>
                                        <span>{currentVacancy?.salaryFrom} RUB</span>
                                    </div>

                                    <div className={styles.item}>
                                        <span className={styles.label}>Зарплата до</span>
                                        <span>{currentVacancy?.salaryTo} RUB</span>
                                    </div>
                                </div>

                                <div className={styles.item}>
                                    <span className={styles.label}>Обязанности кандидата</span>
                                    {/*<span>{currentVacancy?.responsibilities}</span>*/}
                                    <Input.TextArea
                                        style={{color: "#222222", padding: 0}}
                                        variant='borderless'
                                        disabled
                                        value={currentVacancy?.responsibilities}
                                        autoSize
                                    />
                                </div>

                                <div className={styles.item}>
                                    <span className={styles.label}>Требования к кандидату</span>
                                    <Input.TextArea
                                        style={{color: "#222222", padding: 0}}
                                        variant='borderless'
                                        disabled
                                        value={currentVacancy?.requirements}
                                        autoSize
                                    />
                                </div>

                                <div className={styles.item}>
                                    <span className={styles.label}>Условия работы</span>
                                    <Input.TextArea
                                        style={{color: "#222222", padding: 0}}
                                        variant='borderless'
                                        disabled
                                        value={currentVacancy?.workTerms}
                                        autoSize
                                    />
                                </div>

                                <div className={styles.item}>
                                    <span className={styles.label}>Комментарий и заметки</span>
                                    <Input.TextArea
                                        style={{color: "#222222", padding: 0}}
                                        variant='borderless'
                                        disabled
                                        value={currentVacancy?.comment}
                                        autoSize
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Spin>


            <Modal
                title='Подтверждение операции'
                open={isArchiveModalOpen.open}
                okText="Да"
                cancelText="Отмена"

                onOk={() => {
                    dispatch(archiveVacancy({
                        workspaceId: currentWorkspace?.id,
                        vacancyId: isArchiveModalOpen?.id
                    }))
                        .then(() => setIsArchiveModalOpen({open: false, id: null}))
                        .then(() => navigate(-1))
                }}
                onCancel={() => setIsArchiveModalOpen(() => ({open: false, id: null}))}
            >
                Вы уверены, что хотите архивировать вакансию?
            </Modal>

            <Modal
                title='Подтверждение операции'
                open={isCompleteModalOpen.open}
                okText="Да"
                cancelText="Отмена"

                onOk={() => {
                    dispatch(completeVacancy({
                        workspaceId: currentWorkspace?.id,
                        vacancyId: isCompleteModalOpen.id,
                        vacancyStatus: 3
                    }))
                        .then(() => setIsCompleteModalOpen({open: false, id: null}))
                        .then(() => navigate(-1))
                }}
                onCancel={() => setIsCompleteModalOpen(() => ({open: false, id: null}))}
            >
                Вы уверены, что хотите закрыть вакансию?
            </Modal>
        </>
    )
}

export default VacancyPage
