import React from 'react'
import {Outlet, useNavigate} from "react-router-dom";
import {ConfigProvider, Menu, MenuProps} from "antd";
import styles from "../../styles/SettingsLayout.module.scss"
type MenuItem = Required<MenuProps>['items'][number];

const SettingsLayout = () => {

    const navigate = useNavigate()

    const onClick: MenuProps['onClick'] = (e) => {
        navigate(`/settings/${e.key}`)
    };

    const items: MenuItem[] = [
        {
            key: 'companies',
            label: 'Компании',
        },
        {
            key: 'members',
            label: 'Участники',
        },
        {
            key: 'stages',
            label: 'Настройка этапов воронок',
        },
        {
            key: 'departments',
            label: 'Настройка отделов',
        },
        {
            key: 'groups',
            label: 'Группы',
        },
        {
            key: 'reasons',
            label: 'Причины отказов',
        },
        {
            key: 'templates',
            label: 'Шаблоны воронок',
        }
    ];

    return (
        <ConfigProvider
            theme={{
                token: {
                    fontFamily: "Montserrat, sans-serif",
                    colorText: "#222222",
                },
                components: {
                    Checkbox: {
                        colorPrimary: "#4365E1",
                        fontSize: 12,
                        algorithm: true
                    },
                    Collapse: {
                        padding: 0
                    }
                }
            }}
        >
            <div className={styles.container}>
                <div className={styles.titleContainer}>
                    <img src="/settings_icon.svg" alt="settings"/>
                    <h3>Настройки</h3>
                </div>

                <div className={styles.innerContainer}>
                    <Menu
                        onClick={onClick}
                        style={{width: '300px'}}
                        defaultSelectedKeys={['1']}
                        defaultOpenKeys={['sub1']}
                        mode="inline"
                        items={items}
                    />

                    <Outlet/>
                </div>
            </div>
        </ConfigProvider>
    )
}

export default SettingsLayout;
