import React, {useEffect} from 'react'
import styles from '../../styles/Auth/RegistrationFinal.module.scss'
import {Button, Form, FormProps, Input, message} from "antd";
import {FieldType} from "../../Types";
import useCountdown from "../../hooks/useCountdown";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {passwordReset, resendConfirmationToken} from "../../redux/actions/AuthActions";
import {useNavigate} from "react-router-dom";

const PasswordResetFinal: React.FC = () => {

    const [countDown, setCountDown] = useCountdown(1)
    const [messageApi, contextHolder] = message.useMessage();

    const {passwordResetData, isLoading, error, success} = useAppSelector(state => state.authReducer)
    const dispatch = useAppDispatch();

    const navigate = useNavigate()

    useEffect(() => {
        if (error) {
            messageApi.open({
                type: 'error',
                content: error,
            });
        }
        if (success) {
            messageApi.open({
                type: 'success',
                content: success,
            });
        }
    }, [error, success])

    const handleForm: FormProps<FieldType>['onFinish'] = (values) => {
        if (passwordResetData) {
            const obj = {...passwordResetData, ...values}
            dispatch(passwordReset(obj)).then(success => {
                if (success) {
                    navigate('/login')
                }
            })
        } else {
            messageApi.open({
                type: 'error',
                content: 'Возникла ошибка',
            });
        }
    }

    return (
        <>
            {contextHolder}
            <div className={styles.container}>
                <div className={styles.containerInner}>
                    <img src="/exp_logo_text_sm.svg" alt="logo_sm" className={styles.logo}/>
                    <h1>Сброс пароля</h1>

                    <div className={styles.infoContainer}>
                        <span>Мы отправили сообщение с кодом подтверждения на адрес <b>{passwordResetData.email}</b></span>
                        <span>Проверьте электронную почту и введите <b>код подтверждения</b>, указанный в письме</span>

                        <div className={styles.codeActionContainer}>
                            <span>Не пришло сообщение?</span>
                            <div className={styles.codeActionInner}>
                                <div className={styles.timerContainer}>
                                    <img src="/clock_icon.svg" alt="clock_icon"/>
                                    <span>{countDown.minutes}:{countDown.seconds}</span>
                                </div>

                                <button
                                    onClick={() => {
                                        if (!countDown.disabled) {
                                            // @ts-ignore
                                            dispatch(resendConfirmationToken(passwordResetData?.email))
                                                .then(success => {
                                                    if (success) {
                                                        setCountDown(1)
                                                        messageApi.open({
                                                            type: 'success',
                                                            content: 'Код подтверждения успешно отправлен!',
                                                        });
                                                    }
                                                })
                                        }
                                    }}
                                    disabled={countDown.disabled}
                                    className={styles.renewCode}>Отправить заново
                                </button>
                            </div>
                        </div>
                    </div>

                    <Form
                        requiredMark='optional'
                        layout='vertical'
                        autoComplete='off'
                        onFinish={handleForm}
                    >
                        <Form.Item
                            label={<span className='inputLabel'>Код подтверждения</span>}
                            name="token"
                            rules={[{required: true, message: 'Введите код подтверждения'}]}
                        >
                            <Input.OTP length={6} style={{width: "45%"}}/>
                        </Form.Item>

                        <Form.Item<FieldType>
                            label={<span className='inputLabel'>Пароль</span>}
                            name="password"
                            rules={[{required: true, message: 'Введите пароль'},
                                {min: 6, message: 'Пароль должен содержать не менее 6 символов'},
                                {pattern: new RegExp('^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{6,}$'), message: 'Обязательно заглавная буква, строчная, цифра и спец. символ'}
                            ]}
                        >
                            <Input.Password placeholder='Введите пароль'/>
                        </Form.Item>

                        <Form.Item
                            label={<span className='inputLabel'>Подтверждение пароля</span>}
                            name="confirmPassword"
                            dependencies={['password']}
                            rules={[
                                {required: true, message: 'Введите пароль ещё раз'},
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Пароли не совпадают'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password placeholder='Введите пароль'/>
                        </Form.Item>

                        <Form.Item>
                            <Button
                                loading={isLoading}
                                type="primary"
                                htmlType="submit"
                                className={styles.loginButton}
                            >
                                Сменить пароль
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default PasswordResetFinal
