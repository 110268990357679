// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MyWorkspacesList_workspacesContainer__x6oaD {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 48px;
}`, "",{"version":3,"sources":["webpack://./src/styles/MyWorkspacesList.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sCAAA;EACA,SAAA;AACF","sourcesContent":[".workspacesContainer {\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr 1fr;\n  gap: 48px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"workspacesContainer": `MyWorkspacesList_workspacesContainer__x6oaD`
};
export default ___CSS_LOADER_EXPORT___;
