import React, {useEffect, useState} from 'react'
import styles from '../../styles/Settings/Companies.module.scss'
import {Button, Dropdown, Empty, Form, Input, MenuProps, message, Modal, Pagination, Spin} from "antd";
import {DownOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import {useForm} from "antd/es/form/Form";
import {
    archiveCompany,
    createCompany,
    getAllCompanies,
    getAllCompaniesWithPagination,
    updateCompany
} from "../../redux/actions/CompaniesActions";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {useImprovedUXValidation} from "../../hooks/useImprovedUXValidation";

const Companies = () => {

    const {onFinishFailed, validationMode, onBlur} = useImprovedUXValidation(['name'])
    const dispatch = useAppDispatch()
    const [form] = useForm()
    const [createForm] = useForm()
    const [messageApi, contextHolder] = message.useMessage();
    const [isEditModalOpen, setIsEditModalOpen] = useState({open: false, id: null, name: null})
    const [isArchiveModalOpen, setIsArchiveModalOpen] = useState({open: false, id: null})
    const [isAddNewModalOpen, setIsAddNewModalOpen] = useState(false)

    const {companies, isLoading, error, success} = useAppSelector(state => state.companiesReducer)
    const {currentWorkspace} = useAppSelector(state => state.workspacesReducer)

    const companyActions: MenuProps['items'] = [
        {
            label: <span className={styles.actionItem}><EditOutlined/>Редактировать</span>,
            key: '1',
        },
        {
            label: <span className={styles.actionItem}><img src="/archive_icon.svg" alt="edit"/>Архивировать</span>,
            key: '2',
        }
    ];
    const handleActions = (key: any, id: any, name: any) => {
        switch (key) {
            case '1':
                setIsEditModalOpen({open: true, id: id, name: name})
                break;
            case '2':
                setIsArchiveModalOpen({open: true, id: id})
                break;
            default:
                break;
        }
    }

    const onPageNumberChange = (value: any) => {
        dispatch(getAllCompaniesWithPagination({
            pageNumber: value,
            workspaceId: currentWorkspace?.id
        }))
    }

    useEffect(() => {
        if (error) {
            messageApi.open({
                type: 'error',
                content: error,
            });
        }

        if (success) {
            messageApi.open({
                type: 'success',
                content: success,
            });
        }
    }, [error, success])

    useEffect(() => {
        dispatch(getAllCompaniesWithPagination({
            pageNumber: 1,
            workspaceId: currentWorkspace?.id
        }))
    }, []);

    useEffect(() => {
        if (isEditModalOpen.open) {
            form.setFieldsValue({
                name: isEditModalOpen?.name
            })
        }
    }, [isEditModalOpen?.open])

    return (
        <>
            {contextHolder}
            <Spin spinning={isLoading} wrapperClassName={styles.spinnerWrapper}>
                <div className={styles.container}>
                    <div className={styles.buttonContainer}>
                        <span className={styles.listHeader}>Компании</span>
                        <Button type='primary' icon={<PlusOutlined/>} onClick={() => setIsAddNewModalOpen(true)}>Добавить
                            компанию</Button>
                    </div>

                    <div className={styles.listContainer}>
                        {companies?.items?.length !== 0 ?
                            <>
                                {
                                    companies?.items?.map((item: any) => {

                                        return (
                                            <div className={styles.listItem} key={item?.id}>
                                                <div className={styles.listItemHeading}>
                                                    <div className={styles.listItemTitleContainer}>
                                                        <div>{item?.name}</div>
                                                    </div>

                                                    <div className={styles.listItemActionsContainer}>
                                                        <div className={styles.listItemActions}>
                                                            {/*@ts-ignore*/}
                                                            <Dropdown menu={{
                                                                items: companyActions, onClick: ({key}) => {
                                                                    handleActions(key, item?.id, item?.name)
                                                                }
                                                            }}>
                                                                <a onClick={(e) => {
                                                                    e.preventDefault()
                                                                    e.stopPropagation()

                                                                }}>
                                                                    Действия
                                                                    <DownOutlined/>
                                                                </a>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/*<div className={styles.listItemBody}>*/}
                                                {/*    <div className={styles.listItemInfoContainer}>*/}
                                                {/*        <div className={styles.listItemInfoItem}>*/}
                                                {/*            <img src="/profile_icon.svg" alt="Vacancy"/>*/}
                                                {/*            <span>Россия / Москва</span>*/}
                                                {/*        </div>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                            </div>
                                        )
                                    })
                                }

                                {
                                    companies?.totalCount > 8 &&
                                    <Pagination
                                        onChange={onPageNumberChange}
                                        defaultPageSize={8}
                                        current={companies?.pageNumber}
                                        total={companies?.totalCount}
                                    />
                                }
                            </>
                            :
                            <div>
                                <Empty description='Компании отсутствуют'/>
                            </div>
                        }
                    </div>
                </div>
            </Spin>

            <Modal
                title='Добавить компанию'
                open={isAddNewModalOpen}
                okText="Добавить"
                cancelText="Отмена"

                onOk={() => {
                    createForm.submit()
                }}
                onCancel={() => setIsAddNewModalOpen(false)}
            >
                <Form
                    form={createForm}
                    layout='vertical'
                    autoComplete='off'
                    requiredMark='optional'
                    onFinishFailed={onFinishFailed}
                    onFinish={(values) => {
                        dispatch(createCompany({...values, workspaceId: currentWorkspace?.id}))
                            .then(() => dispatch(getAllCompanies({
                                pageNumber: 1,
                                workspaceId: currentWorkspace?.id
                            })))
                        setIsAddNewModalOpen(false)
                    }}
                >
                    <Form.Item
                        label={<span className='inputLabel'>Название компании</span>}
                        name="name"
                        validateTrigger={validationMode.name}
                        rules={[{required: true, message: 'Введите название компании'}]}
                    >
                        <Input placeholder='ООО "Бизнес-решение"' maxLength={100} onBlur={onBlur('name')}/>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title='Редактирование компании'
                open={isEditModalOpen.open}
                okText="Изменить"
                cancelText="Отмена"

                onOk={() => {
                    form.submit()
                }}
                onCancel={() => setIsEditModalOpen(() => ({open: false, id: null, name: null}))}
            >
                <Form
                    form={form}
                    layout='vertical'
                    autoComplete='off'
                    requiredMark='optional'
                    onFinishFailed={onFinishFailed}
                    onFinish={(values) => {
                        dispatch(updateCompany({
                            ...values, id: isEditModalOpen.id,
                            workspaceId: currentWorkspace?.id
                        }))
                            .then(() => dispatch(getAllCompanies({
                                pageNumber: 1,
                                workspaceId: currentWorkspace?.id
                            })))
                        setIsEditModalOpen(() => ({open: false, id: null, name: null}))
                    }}
                >
                    <Form.Item
                        label={<span className='inputLabel'>Название компании</span>}
                        name="name"
                        validateTrigger={validationMode.name}
                        rules={[{required: true, message: 'Введите название компании'}]}
                    >
                        <Input placeholder='ООО "Бизнес-решение"' maxLength={100} onBlur={onBlur('name')}/>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title='Подтверждение операции'
                open={isArchiveModalOpen.open}
                okText="Да"
                cancelText="Отмена"

                onOk={() => {

                    dispatch(archiveCompany({
                        workspaceId: currentWorkspace?.id,
                        id: isArchiveModalOpen.id
                    }))
                        .then(() => dispatch(getAllCompanies({
                            pageNumber: 1,
                            workspaceId: currentWorkspace?.id
                        })))
                        .then(() => setIsArchiveModalOpen(() => ({open: false, id: null})))
                }}
                onCancel={() => setIsArchiveModalOpen(() => ({open: false, id: null}))}
            >
                Вы уверены, что хотите архивировать компанию?
            </Modal>
        </>
    )
}

export default Companies
