import {Tag} from "antd";
import React from "react";

export const isEmpty = (obj) => {
    for(let key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}


export const getErrorMessage = (error) => {
    if (error.response) {
        console.log(error.response);
        // console.log(error.response.status);
        // console.log(error.response.headers);

        return 'Что-то пошло не так. Обратитесь к администратору'
    } else if (error.request) {
        console.log('Error', error);
        return 'Ошибка подключения к серверу'
    } else {
        console.log('Error', error);
        return 'Что-то пошло не так. Обратитесь к администратору'
    }
}


export const formatDate = (ISOString) => {
    if (ISOString) {
        let date = new Date(ISOString);
        let year = new Intl.DateTimeFormat('ru', { year: 'numeric' }).format(date);
        let month = new Intl.DateTimeFormat('ru', { month: '2-digit' }).format(date);
        let day = new Intl.DateTimeFormat('ru', { day: '2-digit' }).format(date);
        let time = new Intl.DateTimeFormat('ru', { timeStyle: 'short' }).format(date);
        return `${day}.${month}.${year} ${time}`
    }
}

export const getTaskStatus = (number) => {
    switch (number) {
        case 1: return <Tag bordered={false} color="blue">Открыто</Tag>
        case 2: return <Tag bordered={false} color="green">Завершено</Tag>
        case 3: return <Tag bordered={false} color="red">Отменено</Tag>
        default: return <Tag bordered={false} color="blue">Открыто</Tag>
    }
}

export const getVacancyStatus = (number) => {
    switch (number) {
        case 1: return <Tag bordered={false} color="blue">Открыто</Tag>
        case 2: return <Tag bordered={false} color="green">Завершено</Tag>
        case 3: return <Tag bordered={false} color="red">Закрыто</Tag>
        default: return <Tag bordered={false} color="blue">Открыто</Tag>
    }
}

export const getGroupStatus = (status) => {
    if (status) {
        return <Tag bordered={false} color="orange">В архиве</Tag>
    } else return <Tag bordered={false} color="green">Действует</Tag>
}
