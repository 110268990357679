import {AppDispatch} from "../index";
import {getErrorMessage} from "../../helpers";
import {workspacesSlice} from "../reducers/workspacesSlice";
import api from "../../helpers/api";

export const getMyWorkspaces = () => async (dispatch: AppDispatch) => {
    try {

        dispatch(workspacesSlice.actions.isLoading())
        const response = await api.get(`${process.env.REACT_APP_API_URI_HTTPS}/api/workspaces/list?PageNumber=1&PageSize=100`)

        if (response.data.succeeded) {
            dispatch(workspacesSlice.actions.myWorkspacesSuccess(response.data.value.items))
        } else {
            dispatch(workspacesSlice.actions.isFailure(response.data?.errors[0]?.ErrorMessages[0]))
        }

    } catch (error: any) {
        const message = getErrorMessage(error)
        dispatch(workspacesSlice.actions.isFailure(message))
    }
}

export const addNewWorkspace = (data: {
    alias: string;
    name: string
}) => async (dispatch: AppDispatch) => {
    try {
        dispatch(workspacesSlice.actions.isLoading())
        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/workspaces/create`, data)
        if (response.data.succeeded) {
            dispatch(workspacesSlice.actions.isSuccess('Рабочее пространство успешно добавлено'))
            dispatch(getMyWorkspaces())
        } else {
            dispatch(workspacesSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error: any) {
        const message = getErrorMessage(error)
        dispatch(workspacesSlice.actions.isFailure(message))
    }
}

export const getJoinRequests = () => async (dispatch: AppDispatch) => {
    try {

        dispatch(workspacesSlice.actions.isLoading())
        const response = await api.get(`${process.env.REACT_APP_API_URI_HTTPS}/api/requests/user/list?PageNumber=1&PageSize=100`)
        if (response.data.succeeded) {
            dispatch(workspacesSlice.actions.getJoinRequestsSuccess(response.data.value.items))
        } else {
            dispatch(workspacesSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error: any) {
        const message = getErrorMessage(error)
        dispatch(workspacesSlice.actions.isFailure(message))
    }
}

export const createJoinRequest = (data: {
    workspaceId: string;
    comment: string
}) => async (dispatch: AppDispatch) => {
    try {

        dispatch(workspacesSlice.actions.isLoading())
        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/requests/create`,
            data)
        if (response.data.succeeded) {
            dispatch(workspacesSlice.actions.isSuccess('Запрос на добавление в рабочее пространство успешно отправлен'))
            dispatch(getJoinRequests())
        } else {
            dispatch(workspacesSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error: any) {
        const message = getErrorMessage(error)
        dispatch(workspacesSlice.actions.isFailure(message))
    }
}

export const revokeJoinRequest = (requestId: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(workspacesSlice.actions.isLoading())
        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/requests/revoke`,
            {requestId: requestId})
        if (response.data.succeeded) {
            dispatch(workspacesSlice.actions.isSuccess('Запрос на добавление в рабочее пространство успешно отменен'))
            dispatch(getJoinRequests())
        } else {
            dispatch(workspacesSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error: any) {
        const message = getErrorMessage(error)
        dispatch(workspacesSlice.actions.isFailure(message))
    }
}

export const setCurrentWorkspace = (workspaceId: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(workspacesSlice.actions.isLoading())
        const response = await api.get(`${process.env.REACT_APP_API_URI_HTTPS}/api/workspaces/get?WorkspaceId=${workspaceId}`)
        if (response.data.succeeded) {
            dispatch(workspacesSlice.actions.setCurrentWorkspace(response.data.value))
        } else {
            dispatch(workspacesSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error: any) {
        const message = getErrorMessage(error)
        dispatch(workspacesSlice.actions.isFailure(message))
    }
}

export const removeCurrentWorkspace = () => (dispatch: AppDispatch) => {
    dispatch(workspacesSlice.actions.removeCurrentWorkspace())
}
