import React, {useEffect} from 'react'
import {Button, Checkbox, Form, FormProps, Input, message} from "antd";
import {FieldType} from "../../Types";
import styles from '../../styles/Auth/LoginPage.module.scss'
import {Link} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {authorizeUser, clearSuccessMessage} from "../../redux/actions/AuthActions";
import {useImprovedUXValidation} from "../../hooks/useImprovedUXValidation";

const LoginPage = () => {

    const [messageApi, contextHolder] = message.useMessage();
    const {loginError, isLoading, success, error} = useAppSelector(state => state.authReducer)
    const dispatch = useAppDispatch()

    const {onFinishFailed, validationMode, onBlur} = useImprovedUXValidation(['email', 'password'])

    useEffect(() => {
        if (loginError) {
            messageApi.open({
                type: 'error',
                content: loginError,
            });
        }

        if (error) {
            messageApi.open({
                type: 'error',
                content: error,
            });
        }



        if (success) {
            messageApi.open({
                type: 'success',
                content: success,
            });

            dispatch(clearSuccessMessage())
        }
    }, [loginError, error, success]);


    const handleForm: FormProps<FieldType>['onFinish'] = async (values) => {
        dispatch(authorizeUser(values))
    }


    return (
        <>
            {contextHolder}
            <div className={styles.container}>
                <div className={styles.containerInner}>
                    <img src="/exp_logo_text_sm.svg" alt="logo_sm" className={styles.logo}/>
                    <h1>Войдите в аккаунт</h1>

                    <Form
                        requiredMark='optional'
                        layout='vertical'
                        autoComplete='off'
                        onFinish={handleForm}
                        className={styles.loginForm}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item
                            label={<span className='inputLabel'>Адрес электронной почты</span>}
                            name="login"
                            validateTrigger={validationMode.email}
                            rules={[{
                                required: true,
                                type: 'email',
                                message: 'Введите корректный адрес электронной почты!'
                            }]}
                        >
                            <Input autoFocus placeholder='Email' onBlur={onBlur('email')}/>
                        </Form.Item>

                        <Form.Item<FieldType>
                            label={<span className='inputLabel'>Пароль</span>}
                            name="password"
                            validateTrigger={validationMode.password}
                            rules={[{required: true, message: 'Введите пароль!'}]}
                        >
                            <Input.Password placeholder='Введите пароль' onBlur={onBlur('password')}/>
                        </Form.Item>

                        <div className={styles.checkboxContainer}>
                            <Form.Item<FieldType>
                                name="remember"
                                valuePropName="checked"
                                noStyle
                            >
                                <Checkbox>Запомнить меня</Checkbox>
                            </Form.Item>

                            <Link to="/password/reset">Забыли пароль?</Link>
                        </div>

                        <div className={styles.registrationContainer}>
                            <span>Ещё не зарегистрированы?</span>
                            <Link to="/register">Зарегистрироваться</Link>
                        </div>

                        <Form.Item>
                            <Button
                                loading={isLoading}
                                type="primary"
                                htmlType="submit"
                                className={styles.loginButton}
                            >
                                Войти
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default LoginPage
