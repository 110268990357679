import {AppDispatch} from "../index";
import {getErrorMessage} from "../../helpers";
import {templatesSlice} from "../reducers/templatesSlice";
import api from "../../helpers/api";

export const getTemplates = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(templatesSlice.actions.isLoading())

        const response = await api.get(`${process.env.REACT_APP_API_URI_HTTPS}/api/process-templates?WorkspaceId=${data.workspaceId}&PageNumber=1&PageSize=100`)

        if (response.data.succeeded) {
            dispatch(templatesSlice.actions.getTemplates(response.data.value))
        } else {
            dispatch(templatesSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(templatesSlice.actions.isFailure(message))
    }
}

export const getTemplateById = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(templatesSlice.actions.isLoadingWithoutNull())

        const response = await api.get(`${process.env.REACT_APP_API_URI_HTTPS}/api/process-templates/details?WorkspaceId=${data.workspaceId}&RecruitmentProcessTemplateId=${data.id}`)

        if (response.data.succeeded) {
            const sortedValues = response.data.value?.recruitmentProcessTemplateStages?.sort((a?: any, b?: any) => a?.sequenceNumber - b?.sequenceNumber)
            dispatch(templatesSlice.actions.setCurrentTemplate({...response.data.value, recruitmentProcessTemplateStages: sortedValues}))
        } else {
            dispatch(templatesSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(templatesSlice.actions.isFailure(message))
    }
}

export const createTemplate = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(templatesSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/process-templates/create`, data)

        if (response.data.succeeded) {
            dispatch(templatesSlice.actions.isSuccess("Шаблон успешно добавлен"))
        } else {
            dispatch(templatesSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(templatesSlice.actions.isFailure(message))
    }
}

export const removeTemplate = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(templatesSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/process-templates/remove`, data)

        if (response.data.succeeded) {
            dispatch(templatesSlice.actions.isSuccess("Шаблон успешно удалён"))
        } else {
            dispatch(templatesSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(templatesSlice.actions.isFailure(message))
    }
}

export const updateTemplate = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(templatesSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/process-templates/update`, data)

        if (response.data.succeeded) {
            dispatch(templatesSlice.actions.isSuccess("Шаблон успешно изменён"))
        } else {
            dispatch(templatesSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(templatesSlice.actions.isFailure(message))
    }
}
