import React, {useEffect, useState} from 'react'
import styles from '../../styles/Profile/ProfileMain.module.scss'
import {Button, Form, Input, message, Modal, Spin} from "antd";
import {EditOutlined, IdcardOutlined, LockOutlined} from "@ant-design/icons";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {getUserInfo, updatePassword, updateUserInfo} from "../../redux/actions/AuthActions";
import {useForm} from "antd/es/form/Form";
import {useImprovedUXValidation} from "../../hooks/useImprovedUXValidation";

const ProfileMain = () => {

    const {onFinishFailed, validationMode, onBlur} = useImprovedUXValidation(['firstName', 'lastName', 'currentPassword', 'newPassword', 'confirmNewPassword'])
    const [messageApi, contextHolder] = message.useMessage();
    const dispatch = useAppDispatch()
    const [editForm] = useForm()
    const [editPassForm] = useForm()
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const [isEditPassModalOpen, setIsEditPassModalOpen] = useState(false)
    const {user, userInfo, isLoading, error, success} = useAppSelector(state => state.authReducer)

    useEffect(() => {
        dispatch(getUserInfo(user?.access_token))
    }, []);

    useEffect(() => {
        if (isEditModalOpen) {
            editForm.setFieldsValue({
                firstName: userInfo?.firstName,
                lastName: userInfo?.lastName,
                middleName: userInfo?.middleName
            })
        }
    }, [isEditModalOpen])

    useEffect(() => {
        if (error) {
            messageApi.open({
                type: 'error',
                content: error,
            });
        }

        if (success) {
            messageApi.open({
                type: 'success',
                content: success,
            });
        }
    }, [error, success])

    return (
        <>
            {contextHolder}
            <div className={styles.container}>
                <Spin spinning={isLoading} wrapperClassName={styles.spinnerWrapper}>
                    <div className={styles.wrapper}>
                        <div className={styles.titleContainer}>
                            <div className={styles.titleInnerContainer}>
                                <IdcardOutlined style={{fontSize: "20px"}}/>
                                {/*<img src="/profile_icon.svg" alt="Профиль"/>*/}
                                <h3>Профиль</h3>
                            </div>

                            <div className={styles.buttonsContainer}>
                                <Button icon={<EditOutlined/>}
                                        onClick={() => setIsEditModalOpen(true)}>Редактировать</Button>
                                <Button icon={<LockOutlined/>} onClick={() => setIsEditPassModalOpen(true)}>Сменить
                                    пароль</Button>
                            </div>
                        </div>

                        <div className={styles.innerContainer}>
                            <div
                                className={styles.username}>{`${userInfo.lastName} ${userInfo.firstName} ${userInfo?.middleName || ''}`}</div>

                            <div className={styles.additionalData}>
                                <div className={styles.item}>
                                    <span className={styles.label}>Почта</span>
                                    <span>{userInfo?.email ? userInfo?.email : 'Не указана'}</span>
                                </div>

                                <div className={styles.item}>
                                    <span className={styles.label}>Мобильный телефон</span>
                                    <span>{userInfo?.phoneNumber ? userInfo?.phoneNumber : 'Не указан'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Spin>

            </div>


            <Modal
                title='Редактирование профиля'
                open={isEditModalOpen}
                okText="Изменить"
                cancelText="Отмена"

                onOk={() => {
                    editForm.submit()
                }}
                onCancel={() => setIsEditModalOpen(false)}
            >
                <Form
                    form={editForm}
                    layout='vertical'
                    autoComplete='off'
                    requiredMark='optional'
                    onFinishFailed={onFinishFailed}
                    onFinish={(values) => {
                        dispatch(updateUserInfo(values, user?.access_token))
                            .then(() => dispatch(getUserInfo(user?.access_token)))
                        setIsEditModalOpen(false)
                    }}
                >
                    <Form.Item
                        label={<span className='inputLabel'>Фамилия</span>}
                        name="lastName"
                        validateTrigger={validationMode.lastName}
                        rules={[{required: true, message: 'Введите фамилию'}]}
                    >
                        <Input placeholder='Иванов' onBlur={onBlur('lastName')}/>
                    </Form.Item>

                    <Form.Item
                        label={<span className='inputLabel'>Имя</span>}
                        name="firstName"
                        validateTrigger={validationMode.firstName}
                        rules={[{required: true, message: 'Введите имя'}]}
                    >
                        <Input placeholder='Иван' onBlur={onBlur('firstName')}/>
                    </Form.Item>

                    <Form.Item
                        label={<span className='inputLabel'>Отчество</span>}
                        name="middleName"
                    >
                        <Input placeholder='Иванович'/>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title='Смена пароля'
                open={isEditPassModalOpen}
                okText="Сменить пароль"
                cancelText="Отмена"

                onOk={() => {
                    editPassForm.submit()
                }}
                onCancel={() => setIsEditPassModalOpen(false)}
            >
                <Form
                    form={editPassForm}
                    layout='vertical'
                    autoComplete='off'
                    requiredMark='optional'
                    onFinishFailed={onFinishFailed}
                    onFinish={(values) => {
                        dispatch(updatePassword(values, user?.access_token))
                            .then(() => editPassForm.resetFields())
                        setIsEditPassModalOpen(false)
                    }}
                >
                    <Form.Item
                        label={<span className='inputLabel'>Текущий пароль</span>}
                        name="currentPassword"
                        validateTrigger={validationMode.currentPassword}
                        rules={[{required: true, message: 'Введите текущий пароль'},
                            {min: 6, message: 'Пароль должен содержать не менее 6 символов'},
                            {pattern: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{6,}$'), message: 'Обязательно заглавная буква, строчная и цифра'}
                        ]}
                    >
                        <Input.Password placeholder='Введите текущий пароль' onBlur={onBlur('currentPassword')}/>
                    </Form.Item>

                    <Form.Item
                        label={<span className='inputLabel'>Новый пароль</span>}
                        name="newPassword"
                        validateTrigger={validationMode.newPassword}
                        rules={[{required: true, message: 'Введите новый пароль'},
                            {min: 6, message: 'Пароль должен содержать не менее 6 символов'},
                            {pattern: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{6,}$'), message: 'Обязательно заглавная буква, строчная и цифра'}
                        ]}
                    >
                        <Input.Password placeholder='Введите новый пароль' onBlur={onBlur('newPassword')}/>
                    </Form.Item>

                    <Form.Item
                        label={<span className='inputLabel'>Подтверждение нового пароля</span>}
                        name="confirmNewPassword"
                        validateTrigger={validationMode.confirmNewPassword}
                        dependencies={['newPassword']}
                        rules={[
                            {required: true, message: 'Введите пароль ещё раз'},
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newPassword') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Пароли не совпадают'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password placeholder='Повторите пароль' onBlur={onBlur('confirmNewPassword')}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default ProfileMain
