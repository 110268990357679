// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WorkspaceSearch_container__smXVC {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 38px;
}
.WorkspaceSearch_container__smXVC h2 {
  font-size: 16px;
  font-weight: 600;
}

.WorkspaceSearch_itemContainer__8MlQK {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.WorkspaceSearch_itemContainer__8MlQK .WorkspaceSearch_itemInnerContainer__N-Wd3 {
  display: flex;
  gap: 16px;
  align-items: center;
}
.WorkspaceSearch_itemContainer__8MlQK .WorkspaceSearch_itemInnerContainer__N-Wd3 span {
  font-size: 16px;
  font-weight: 600;
  color: #222222;
}
.WorkspaceSearch_itemContainer__8MlQK button {
  border: none;
  background-color: transparent;
  color: var(--primary);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.WorkspaceSearch_input__ilWcO {
  height: 48px;
  font-size: 16px;
  border-radius: 12px;
}

.WorkspaceSearch_dropdown__d\\+xmX {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/styles/WorkspaceSearch.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,gBAAA;AACF;AACE;EACE,eAAA;EACA,gBAAA;AACJ;;AAGA;EACE,WAAA;EACA,aAAA;EACA,8BAAA;AAAF;AAEE;EAEE,aAAA;EACA,SAAA;EACA,mBAAA;AADJ;AAGI;EACE,eAAA;EACA,gBAAA;EACA,cAAA;AADN;AAOE;EACE,YAAA;EACA,6BAAA;EACA,qBAAA;EACA,eAAA;EACA,gBAAA;EACA,eAAA;AALJ;;AASA;EACE,YAAA;EACA,eAAA;EACA,mBAAA;AANF;;AASA;EACE,cAAA;AANF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n  margin-top: 38px;\n\n  h2 {\n    font-size: 16px;\n    font-weight: 600;\n  }\n}\n\n.itemContainer {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n\n  .itemInnerContainer {\n\n    display: flex;\n    gap: 16px;\n    align-items: center;\n\n    span {\n      font-size: 16px;\n      font-weight: 600;\n      color: #222222;\n    }\n  }\n\n\n\n  button {\n    border: none;\n    background-color: transparent;\n    color: var(--primary);\n    font-size: 14px;\n    font-weight: 500;\n    cursor: pointer;\n  }\n}\n\n.input {\n  height: 48px;\n  font-size: 16px;\n  border-radius: 12px;\n}\n\n.dropdown {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `WorkspaceSearch_container__smXVC`,
	"itemContainer": `WorkspaceSearch_itemContainer__8MlQK`,
	"itemInnerContainer": `WorkspaceSearch_itemInnerContainer__N-Wd3`,
	"input": `WorkspaceSearch_input__ilWcO`,
	"dropdown": `WorkspaceSearch_dropdown__d+xmX`
};
export default ___CSS_LOADER_EXPORT___;
