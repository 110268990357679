// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RegistrationFinal_container__WVYRc {
  display: flex;
  justify-content: center;
  align-items: center;
}
.RegistrationFinal_container__WVYRc .RegistrationFinal_logo__DvCI3 {
  margin-bottom: 32px;
}
.RegistrationFinal_container__WVYRc h1 {
  margin-bottom: 42px;
  font-weight: 600;
}
.RegistrationFinal_container__WVYRc .RegistrationFinal_containerInner__j6u2H {
  width: 532px;
}
.RegistrationFinal_container__WVYRc .RegistrationFinal_loginButton__aPgRD {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.RegistrationFinal_infoContainer__qELEH {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 32px;
}
.RegistrationFinal_infoContainer__qELEH span {
  color: #6E6E6E;
  line-height: 22px;
}
.RegistrationFinal_infoContainer__qELEH b {
  color: #222222;
  font-weight: 500;
}

.RegistrationFinal_codeActionContainer__jLRZa {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.RegistrationFinal_codeActionContainer__jLRZa .RegistrationFinal_codeActionInner__-pm3h {
  display: flex;
  gap: 5px;
  align-items: center;
}
.RegistrationFinal_codeActionContainer__jLRZa .RegistrationFinal_codeActionInner__-pm3h .RegistrationFinal_timerContainer__MfEfH {
  display: flex;
  gap: 6px;
  align-items: center;
  width: 75px;
}
.RegistrationFinal_codeActionContainer__jLRZa .RegistrationFinal_codeActionInner__-pm3h .RegistrationFinal_timerContainer__MfEfH span {
  color: #222222;
}
.RegistrationFinal_codeActionContainer__jLRZa .RegistrationFinal_renewCode__0ZwcE {
  border: none;
  background-color: transparent;
  color: var(--primary);
  cursor: pointer;
}
.RegistrationFinal_codeActionContainer__jLRZa .RegistrationFinal_renewCode__0ZwcE[disabled] {
  color: var(--text-gray);
  cursor: not-allowed;
}`, "",{"version":3,"sources":["webpack://./src/styles/Auth/RegistrationFinal.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;AACE;EACE,mBAAA;AACJ;AAEE;EACE,mBAAA;EACA,gBAAA;AAAJ;AAGE;EACE,YAAA;AADJ;AAIE;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAFJ;;AAMA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,mBAAA;AAHF;AAKE;EACE,cAAA;EACA,iBAAA;AAHJ;AAME;EACE,cAAA;EACA,gBAAA;AAJJ;;AASA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AANF;AAQE;EACE,aAAA;EACA,QAAA;EACA,mBAAA;AANJ;AAQI;EACE,aAAA;EACA,QAAA;EACA,mBAAA;EACA,WAAA;AANN;AAQM;EACE,cAAA;AANR;AAYE;EACE,YAAA;EACA,6BAAA;EACA,qBAAA;EACA,eAAA;AAVJ;AAaE;EACE,uBAAA;EACA,mBAAA;AAXJ","sourcesContent":[".container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  .logo {\n    margin-bottom: 32px;\n  }\n\n  h1 {\n    margin-bottom: 42px;\n    font-weight: 600;\n  }\n\n  .containerInner {\n    width: 532px;\n  }\n\n  .loginButton {\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n}\n\n.infoContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  margin-bottom: 32px;\n\n  span {\n    color: #6E6E6E;\n    line-height: 22px;\n  }\n\n  b {\n    color: #222222;\n    font-weight: 500;\n  }\n}\n\n\n.codeActionContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n\n  .codeActionInner {\n    display: flex;\n    gap: 5px;\n    align-items: center;\n\n    .timerContainer {\n      display: flex;\n      gap: 6px;\n      align-items: center;\n      width: 75px;\n\n      span {\n        color: #222222;\n      }\n    }\n  }\n\n\n  .renewCode {\n    border: none;\n    background-color: transparent;\n    color: var(--primary);\n    cursor: pointer;\n  }\n\n  .renewCode[disabled] {\n    color: var(--text-gray);\n    cursor: not-allowed;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RegistrationFinal_container__WVYRc`,
	"logo": `RegistrationFinal_logo__DvCI3`,
	"containerInner": `RegistrationFinal_containerInner__j6u2H`,
	"loginButton": `RegistrationFinal_loginButton__aPgRD`,
	"infoContainer": `RegistrationFinal_infoContainer__qELEH`,
	"codeActionContainer": `RegistrationFinal_codeActionContainer__jLRZa`,
	"codeActionInner": `RegistrationFinal_codeActionInner__-pm3h`,
	"timerContainer": `RegistrationFinal_timerContainer__MfEfH`,
	"renewCode": `RegistrationFinal_renewCode__0ZwcE`
};
export default ___CSS_LOADER_EXPORT___;
