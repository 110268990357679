// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CreateTask_container__ZOPeI {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 64px 32px;
  max-width: 1312px;
  width: 100%;
}
.CreateTask_container__ZOPeI input {
  height: 38px;
}

.CreateTask_titleInnerContainer__CYsMn h3 {
  font-size: 24px;
  font-weight: 600;
}

.CreateTask_filterForm__g7gBE {
  display: flex;
  flex-direction: row;
  gap: 80px;
}

.CreateTask_descriptionContainer__w-1c6 {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.CreateTask_vacancyContainer__5NNu8 {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.CreateTask_columnTitle__JWtdF {
  font-size: 16px;
  font-weight: 600;
}

.CreateTask_innerFormContainer__Ni1-3 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.CreateTask_innerFormContainer__Ni1-3 div {
  margin-bottom: 0;
}

.CreateTask_formRow__kmx6R {
  display: flex;
  gap: 24px;
}
.CreateTask_formRow__kmx6R div {
  width: 100%;
}

.CreateTask_spinnerWrapper__\\+xCHV {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/styles/Tasks/CreateTask.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,kBAAA;EACA,iBAAA;EACA,WAAA;AACF;AACE;EACE,YAAA;AACJ;;AAIE;EACE,eAAA;EACA,gBAAA;AADJ;;AAKA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAFF;;AAKA;EACE,UAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AAFF;;AAKA;EACE,UAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AAFF;;AAKA;EACE,eAAA;EACA,gBAAA;AAFF;;AAKA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAFF;AAIE;EACE,gBAAA;AAFJ;;AAMA;EACE,aAAA;EACA,SAAA;AAHF;AAKE;EACE,WAAA;AAHJ;;AAOA;EACE,WAAA;AAJF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 32px;\n  padding: 64px 32px;\n  max-width: 1312px;\n  width: 100%;\n\n  input {\n    height: 38px;\n  }\n}\n\n.titleInnerContainer {\n  h3 {\n    font-size: 24px;\n    font-weight: 600;\n  }\n}\n\n.filterForm {\n  display: flex;\n  flex-direction: row;\n  gap: 80px;\n}\n\n.descriptionContainer {\n  width: 40%;\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n\n.vacancyContainer {\n  width: 60%;\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n\n.columnTitle {\n  font-size: 16px;\n  font-weight: 600;\n}\n\n.innerFormContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n\n  div {\n    margin-bottom: 0;\n  }\n}\n\n.formRow {\n  display: flex;\n  gap: 24px;\n\n  div {\n    width: 100%;\n  }\n}\n\n.spinnerWrapper {\n  width: 100%\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CreateTask_container__ZOPeI`,
	"titleInnerContainer": `CreateTask_titleInnerContainer__CYsMn`,
	"filterForm": `CreateTask_filterForm__g7gBE`,
	"descriptionContainer": `CreateTask_descriptionContainer__w-1c6`,
	"vacancyContainer": `CreateTask_vacancyContainer__5NNu8`,
	"columnTitle": `CreateTask_columnTitle__JWtdF`,
	"innerFormContainer": `CreateTask_innerFormContainer__Ni1-3`,
	"formRow": `CreateTask_formRow__kmx6R`,
	"spinnerWrapper": `CreateTask_spinnerWrapper__+xCHV`
};
export default ___CSS_LOADER_EXPORT___;
