import React, {useEffect} from 'react'
import styles from '../../styles/Tasks/CreateTask.module.scss'
import {Button, DatePicker, Form, Input, InputNumber, message, Select} from "antd";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {createTask} from "../../redux/actions/TasksActions";
import {getParticipants} from "../../redux/actions/ParticipantsActions";
import {IParticipant} from "../models/IParticipant";
import {useLocation, useNavigate} from "react-router-dom";
import {getAllVacancies, getVacancyById} from "../../redux/actions/VacanciesActions";
import {IVacancy} from "../models/IVacancy";
import {useImprovedUXValidation} from "../../hooks/useImprovedUXValidation";
import {useForm} from "antd/es/form/Form";
import dayjs from "dayjs";

const CreateTask = () => {
    //@ts-ignore
    const {query, search} = useLocation();
    const navigate = useNavigate()

    const [taskForm] = useForm()

    const {
        onFinishFailed,
        validationMode,
        onBlur
    } = useImprovedUXValidation(['name', 'description', 'deadlineAtUtc', 'responsibleUserId', 'vacancyId', 'vacancyHireQuantity'])

    const {currentWorkspace} = useAppSelector(state => state.workspacesReducer)
    const {error, success, isLoading} = useAppSelector(state => state.tasksReducer);
    const {participants} = useAppSelector(state => state.participantsReducer)
    const {vacancies, currentVacancy, error: vacancyError} = useAppSelector(state => state.vacanciesReducer)

    const dispatch = useAppDispatch();
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {

        dispatch(getParticipants({workspaceId: currentWorkspace?.id, pageNumber: 1}))
        dispatch(getAllVacancies({
            workspaceId: currentWorkspace?.id,
            pageNumber: 1,
            pageSize: 100
        }))

        if (search) {
            const vacancyId = search.split('=')[1]
            console.log(vacancyId)
            dispatch(getVacancyById({
                workspaceId: currentWorkspace?.id,
                vacancyId: vacancyId
            }))
        }
    }, [])

    useEffect(() => {
        taskForm.setFieldsValue({
            name: currentVacancy?.name,
            description: currentVacancy?.comment,
            deadlineAtUtc: dayjs(currentVacancy?.estimatedCompletedAtUtc),
            vacancyId: currentVacancy?.id,
        })
    }, [currentVacancy]);

    useEffect(() => {
        if (error) {
            messageApi.open({
                type: 'error',
                content: error,
            });
        }
    }, [error])

    useEffect(() => {
        if (vacancyError) {
            messageApi.open({
                type: 'error',
                content: vacancyError,
            });
        }
    }, [vacancyError]);

    useEffect(() => {
        if (success) {
            messageApi.open({
                type: 'success',
                content: success,
            });
            navigate(-1)
        }
    }, [success])

    return (
        <>
            {contextHolder}
                <div className={styles.container}>
                    <div className={styles.titleInnerContainer}>
                        <h3>Создание задания</h3>
                    </div>

                    <div>
                        <Form
                            form={taskForm}
                            layout='vertical'
                            autoComplete='off'
                            requiredMark='optional'
                            onFinishFailed={onFinishFailed}
                            onFinish={(values) => {
                                dispatch(createTask({
                                    ...values,
                                    workspaceId: currentWorkspace?.id
                                }))
                            }}
                            className={styles.filterForm}
                        >
                            <div className={styles.descriptionContainer}>
                                <span className={styles.columnTitle}>Основная информация</span>

                                <div className={styles.innerFormContainer}>
                                    <Form.Item
                                        label={<span className='inputLabel'>Название задания</span>}
                                        name="name"
                                        validateTrigger={validationMode.name}
                                        rules={[{required: true, message: 'Введите название задания'}]}
                                    >
                                        <Input placeholder='Менеджер по продажам' maxLength={100}
                                               onBlur={onBlur('name')}/>
                                    </Form.Item>

                                    <Form.Item
                                        label={<span className='inputLabel'>Текст задания</span>}
                                        name="description"
                                        // validateTrigger={validationMode.description}
                                        // rules={[{required: true, message: 'Введите описание задания'}]}
                                    >
                                        {/*@ts-ignore*/}
                                        <Input.TextArea placeholder='' autoSize={{minRows: 5, maxRows: 9}} showCount
                                                        maxLength={500}
                                        />
                                    </Form.Item>

                                    <div className={styles.formRow}>
                                        <Form.Item
                                            label={<span className='inputLabel'>Выполнить до</span>}
                                            name="deadlineAtUtc"
                                            validateTrigger={validationMode.deadlineAtUtc}
                                            rules={[{required: true, message: 'Установите дедлайн'}]}
                                        >
                                            <DatePicker showTime onBlur={onBlur('deadlineAtUtc')}/>
                                        </Form.Item>
                                    </div>

                                    <Form.Item
                                        label={<span className='inputLabel'>Ответственный</span>}
                                        name="responsibleUserId"
                                        validateTrigger={validationMode.responsibleUserId}
                                        rules={[{required: true, message: 'Выберите ответственного'}]}
                                    >
                                        <Select
                                            showSearch
                                            allowClear
                                            optionFilterProp="label"
                                            onBlur={onBlur('responsibleUserId')}
                                            options={participants?.items?.map((item: IParticipant) => (
                                                {
                                                    value: item.userId,
                                                    label: `${item?.user?.fullName?.last} ${item?.user?.fullName?.first}`
                                                }))}
                                            filterOption={(input, option) =>
                                                //@ts-ignore
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                        />
                                    </Form.Item>

                                    <Form.Item>
                                        <Button
                                            loading={isLoading}
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            Создать задание
                                        </Button>
                                    </Form.Item>
                                </div>
                            </div>

                            <div className={styles.vacancyContainer}>
                                <span className={styles.columnTitle}>Дополнительно</span>

                                <Form.Item
                                    label={<span className='inputLabel'>Вакансия</span>}
                                    name="vacancyId"
                                    validateTrigger={validationMode.vacancyId}
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        optionFilterProp="label"
                                        filterOption={(input, option) =>
                                            //@ts-ignore
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={vacancies?.items?.map((item: IVacancy) => (
                                            {
                                                value: item?.id,
                                                label: item?.name
                                            }))}
                                        onBlur={onBlur('vacancyId')}/>
                                </Form.Item>

                                <Form.Item
                                    label={<span className='inputLabel'>Количество кандидатов</span>}
                                    name="vacancyHireQuantity"
                                    validateTrigger={validationMode.vacancyHireQuantity}
                                >
                                    <InputNumber min={1} max={100} keyboard size='middle'
                                                 onBlur={onBlur('vacancyHireQuantity')}/>
                                </Form.Item>

                                {/*<div className={styles.innerFormContainer}>*/}
                                {/*    <div className={styles.formRow}>*/}
                                {/*        <Form.Item*/}
                                {/*            label={<span className='inputLabel'>Пол</span>}*/}
                                {/*            name="gender"*/}
                                {/*        >*/}
                                {/*            <Radio.Group>*/}
                                {/*                <Radio.Button value="man">Мужской</Radio.Button>*/}
                                {/*                <Radio.Button value="woman">Женский</Radio.Button>*/}
                                {/*            </Radio.Group>*/}
                                {/*        </Form.Item>*/}

                                {/*        <Form.Item*/}
                                {/*            label={<span className='inputLabel'>Высшее образование</span>}*/}
                                {/*            name="high_education"*/}
                                {/*        >*/}
                                {/*            <Radio.Group>*/}
                                {/*                <Radio.Button value="man">Есть</Radio.Button>*/}
                                {/*                <Radio.Button value="woman">Отсутствует</Radio.Button>*/}
                                {/*            </Radio.Group>*/}
                                {/*        </Form.Item>*/}
                                {/*    </div>*/}

                                {/*    <div className={styles.formRow}>*/}
                                {/*        <Form.Item*/}
                                {/*            label={<span className='inputLabel'>Возраст от</span>}*/}
                                {/*            name="age_from"*/}
                                {/*        >*/}
                                {/*            <Input placeholder='18'/>*/}
                                {/*        </Form.Item>*/}

                                {/*        <Form.Item*/}
                                {/*            label={<span className='inputLabel'>Возраст до</span>}*/}
                                {/*            name="age_to"*/}
                                {/*        >*/}
                                {/*            <Input placeholder='65'/>*/}
                                {/*        </Form.Item>*/}
                                {/*    </div>*/}

                                {/*    <Form.Item*/}
                                {/*        label={<span className='inputLabel'>Квалификация</span>}*/}
                                {/*        name="qualification"*/}
                                {/*    >*/}
                                {/*        <Input placeholder='Маркетолог'/>*/}
                                {/*    </Form.Item>*/}
                                {/*</div>*/}
                            </div>
                        </Form>
                    </div>

                </div>
        </>
    )
}

export default CreateTask
