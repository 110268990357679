import React, {useEffect, useState} from 'react'
import styles from '../../../styles/Settings/RejectionReasons/RejectionReasonsMain.module.scss'
import {useImprovedUXValidation} from "../../../hooks/useImprovedUXValidation";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {useForm} from "antd/es/form/Form";
import {Button, Dropdown, Empty, Form, Input, MenuProps, message, Modal, Pagination, Segmented, Spin, Tag} from "antd";
import {DeleteOutlined, DownOutlined, EditOutlined, PlusOutlined, RetweetOutlined} from "@ant-design/icons";
import {
    createRejectionReason,
    deleteRejectionReason,
    getAllRejectionReasons,
    getArchivedRejectionReasons,
    restoreRejectionReason,
    updateRejectionReason
} from "../../../redux/actions/RejectionReasonsActions";


const RejectionReasonsMain = () => {

    const {onFinishFailed, validationMode, onBlur} = useImprovedUXValidation(['name', 'description', 'companyId'])
    const dispatch = useAppDispatch()
    const [createForm] = useForm()
    const [editForm] = useForm()
    const [messageApi, contextHolder] = message.useMessage();

    const {currentWorkspace} = useAppSelector(state => state.workspacesReducer)
    const {reasons, isLoading, error, success} = useAppSelector(state => state.rejectionReasonsReducer)


    const [currentTab, setCurrentTab] = useState(0)
    const [isAddNewModalOpen, setIsAddNewModalOpen] = useState(false)
    const [isEditModalOpen, setIsEditModalOpen] = useState<any>({open: false, reason: null})
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<any>({open: false, reason: null})
    const [isRestoreModalOpen, setIsRestoreModalOpen] = useState<any>({open: false, reason: null})


    const rejectionReasonsActions: MenuProps['items'] = [
        {
            label: <span className={styles.actionItem}><EditOutlined/>Редактировать</span>,
            key: '1',
        },
        {
            label: <span className={styles.actionItem}><DeleteOutlined/>Удалить</span>,
            key: '2',
        }
    ];

    const rejectionReasonsArchiveActions: MenuProps['items'] = [
        {
            label: <span className={styles.actionItem}><RetweetOutlined/>Восстановить</span>,
            key: '3',
        },
    ];

    const handleActions = (key: any, data?: any) => {
        switch (key) {
            case '1':
                setIsEditModalOpen({open: true, reason: data})
                break;
            case '2':
                setIsDeleteModalOpen({open: true, reason: data})
                break;
            case '3':
                setIsRestoreModalOpen({open: true, reason: data})
                break;
            default:
                break;
        }
    }

    const onTabChange = (value: any) => {
        setCurrentTab(value)

        switch (value) {
            case 0:
                dispatch(getAllRejectionReasons({
                    workspaceId: currentWorkspace?.id,
                    pageNumber: 1
                }))
                break;
            case 1:
                dispatch(getArchivedRejectionReasons({
                    workspaceId: currentWorkspace?.id,
                    pageNumber: 1
                }))
                break;
            default:
                break;
        }
    }

    const onPageNumberChange = (value: any) => {
        if (currentTab === 0) {
            dispatch(getAllRejectionReasons({
                workspaceId: currentWorkspace?.id,
                pageNumber: value
            }))
        } else {
            dispatch(getArchivedRejectionReasons({
                workspaceId: currentWorkspace?.id,
                pageNumber: value
            }))
        }
    }

    useEffect(() => {
        if (error) {
            messageApi.open({
                type: 'error',
                content: error,
            });
        }

        if (success) {
            messageApi.open({
                type: 'success',
                content: success,
            });
        }
    }, [error, success])

    useEffect(() => {
        if (isEditModalOpen?.open) {
            editForm.setFieldsValue({
                name: isEditModalOpen?.reason?.name,
            })
        }
    }, [isEditModalOpen?.open])

    useEffect(() => {
        dispatch(getAllRejectionReasons({
            workspaceId: currentWorkspace?.id,
            pageNumber: 1
        }))
    }, [])

    return (
        <>
            {contextHolder}
            <Spin spinning={isLoading} wrapperClassName={styles.spinnerWrapper}>
                <div className={styles.container}>
                    <div className={styles.listContainer}>
                        <div className={styles.pageTitleWrapper}>
                            <span className={styles.listHeader}>Причины отказов</span>
                            <Button type='primary' icon={<PlusOutlined/>}
                                    onClick={() => setIsAddNewModalOpen(true)}>Добавить причину отказов</Button>
                        </div>

                        <Segmented
                            className={styles.listTabs}
                            onChange={(value) => onTabChange(value)}
                            value={currentTab}
                            options={[
                                {
                                    label: 'Текущие',
                                    value: 0
                                },
                                {
                                    label: 'Архив',
                                    value: 1
                                }
                            ]}
                        />

                        <div className={styles.innerListContainer}>
                            {
                                reasons?.items?.length > 0 ?
                                    <>
                                        {
                                            reasons?.items?.map((item: any) => (
                                                <div className={styles.listItem} key={item?.id}>
                                                    <div className={styles.listItemHeading}>
                                                        <div className={styles.listItemTitleContainer}>
                                                            <div>{item?.name}</div>
                                                            {item?.archivedBy &&
                                                                <Tag bordered={false} color="orange">В архиве</Tag>}
                                                        </div>

                                                        <div className={styles.listItemActionsContainer}>
                                                            <div className={styles.listItemActions}>
                                                                {/*@ts-ignore*/}
                                                                <Dropdown menu={{
                                                                    items: item?.archivedBy ? rejectionReasonsArchiveActions : rejectionReasonsActions,
                                                                    onClick: ({key}) => {
                                                                        handleActions(key, item)
                                                                    }
                                                                }}>
                                                                    <a onClick={(e) => {
                                                                        e.preventDefault()
                                                                        e.stopPropagation()

                                                                    }}>
                                                                        Действия
                                                                        <DownOutlined/>
                                                                    </a>
                                                                </Dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                        {
                                            reasons?.totalCount > 8 &&
                                            <Pagination
                                                onChange={onPageNumberChange}
                                                defaultPageSize={8}
                                                current={reasons?.pageNumber}
                                                total={reasons?.totalCount}
                                            />
                                        }
                                    </>
                                    :
                                    <div>
                                        <Empty description='Причины отказа отсутствуют'/>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </Spin>


            <Modal
                title='Добавить причину отказа'
                open={isAddNewModalOpen}
                okText="Добавить"
                cancelText="Отмена"

                onOk={() => {
                    createForm.submit()
                }}
                onCancel={() => setIsAddNewModalOpen(false)}
            >
                <Form
                    form={createForm}
                    layout='vertical'
                    autoComplete='off'
                    requiredMark='optional'
                    onFinishFailed={onFinishFailed}
                    onFinish={(values) => {
                        dispatch(createRejectionReason({
                            ...values,
                            workspaceId: currentWorkspace?.id
                        }))
                            .then(() => dispatch(getAllRejectionReasons({
                                workspaceId: currentWorkspace?.id,
                                pageNumber: 1
                            })))
                            .then(() => createForm.resetFields())
                        setIsAddNewModalOpen(false)
                    }}
                >
                    <Form.Item
                        label={<span className='inputLabel'>Причина отказа</span>}
                        name="name"
                        validateTrigger={validationMode.name}
                        rules={[{required: true, message: 'Введите причину отказа'}]}
                    >
                        <Input.TextArea placeholder='Отсутствие достаточной квалификации' autoSize={{minRows: 2, maxRows: 2}} showCount maxLength={100} onBlur={onBlur('name')}/>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title='Редактировать причину отказа'
                open={isEditModalOpen?.open}
                okText="Изменить"
                cancelText="Отмена"

                onOk={() => {
                    editForm.submit()
                }}
                onCancel={() => setIsEditModalOpen({open: false, reason: null})}
            >
                <Form
                    form={editForm}
                    layout='vertical'
                    autoComplete='off'
                    requiredMark='optional'
                    onFinishFailed={onFinishFailed}
                    onFinish={(values) => {
                        dispatch(updateRejectionReason({
                            ...values,
                            workspaceId: currentWorkspace?.id,
                            rejectionReasonId: isEditModalOpen?.reason?.id
                        }))
                            .then(() => dispatch(getAllRejectionReasons({
                                workspaceId: currentWorkspace?.id,
                                pageNumber: 1
                            })))
                            .then(() => createForm.resetFields())
                        setIsEditModalOpen({open: false, reason: null})
                    }}
                >
                    <Form.Item
                        label={<span className='inputLabel'>Причина отказа</span>}
                        name="name"
                        validateTrigger={validationMode.name}
                        rules={[{required: true, message: 'Введите причину отказа'}]}
                    >
                        <Input.TextArea placeholder='Отсутствие достаточной квалификации' autoSize={{minRows: 2, maxRows: 2}} showCount maxLength={100} onBlur={onBlur('name')}/>
                    </Form.Item>
                </Form>
            </Modal>


            <Modal
                title='Подтверждение операции'
                open={isDeleteModalOpen.open}
                okText="Да"
                cancelText="Отмена"

                onOk={() => {
                    dispatch(deleteRejectionReason({
                        workspaceId: currentWorkspace?.id,
                        rejectionReasonId: isDeleteModalOpen?.reason?.id
                    }))
                        .then(() => dispatch(getAllRejectionReasons({
                            workspaceId: currentWorkspace?.id,
                            pageNumber: 1
                        })))
                    setIsDeleteModalOpen({open: false, reason: null})

                }}
                onCancel={() => setIsDeleteModalOpen(() => ({open: false, reason: null}))}
            >
                Вы уверены, что хотите удалить причину отказа?
                <br/>
                Причина отказа будет перенесена в архив
            </Modal>


            <Modal
                title='Подтверждение операции'
                open={isRestoreModalOpen.open}
                okText="Да"
                cancelText="Отмена"

                onOk={() => {
                    dispatch(restoreRejectionReason({
                        workspaceId: currentWorkspace?.id,
                        rejectionReasonId: isRestoreModalOpen?.reason?.id
                    }))
                        .then(() => dispatch(getAllRejectionReasons({
                            workspaceId: currentWorkspace?.id,
                            pageNumber: 1
                        })))
                        .then(() => setCurrentTab(0))
                    setIsRestoreModalOpen({open: false, reason: null})

                }}
                onCancel={() => setIsRestoreModalOpen(() => ({open: false, reason: null}))}
            >
                Вы уверены, что хотите восстановить причину отказа?
            </Modal>
        </>
    )
}

export default RejectionReasonsMain
