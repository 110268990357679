import {useForm} from "antd/es/form/Form";
import {useAppDispatch} from "../../hooks/redux";
import {isEmpty} from "../../helpers";
import styles from "../../styles/Vacancies/VacanciesMain.module.scss";
import {Button, DatePicker, Form, Input, Select} from "antd";
import React from "react";
import {useImprovedUXValidation} from "../../hooks/useImprovedUXValidation";

const CandidatesFilter = ({...props}) => {
    const [filterForm] = useForm()
    const dispatch = useAppDispatch()

    const {onFinishFailed, validationMode, onBlur} = useImprovedUXValidation(['firstName', 'lastName', 'middleName', 'dateOfBirth'])


    const onFilterSearch = (values: any) => {
        const filterParams = Object.fromEntries(Object.entries(values)
            .filter(([_, v]) => v !== undefined && v !== null)
        )
        // @ts-ignore
        if (!isEmpty(filterParams)) {
            const str = Object.fromEntries(Object.entries(filterParams)
                // @ts-ignore
                .map(([_, v]) => {
                    if (_.includes('dateOfBirth')) {

                        let tzoffset = (new Date()).getTimezoneOffset() * 60000;
                        // @ts-ignore
                        let localISOTime = (new Date(v - tzoffset)).toISOString().slice(0, -1);
                        // @ts-ignore
                        return [_, `${localISOTime.split('-')[0]}-${localISOTime.split('-')[1]}-${localISOTime.split('-')[2].slice(0,2)}`]
                    }
                    // @ts-ignore
                    else return [_, v.toString()]
                })
            )
            props.onFilterChange(str)
        }
    }

    const onFilterFormReset = () => {
        filterForm.resetFields()
        props.onFilterChange({})
    }


    return (
        <div className={styles.filterContainer + ` ${!props.showFilter ? styles.filterActive : ''}`}>
            <span className={styles.filterTitle}>Фильтры</span>

            <Form
                form={filterForm}
                layout='vertical'
                autoComplete='off'
                onFinishFailed={onFinishFailed}
                onFinish={(values) => onFilterSearch(values)}
                className={styles.filterForm}
            >
                <Form.Item
                    label={<span className='inputLabel'>Имя</span>}
                    name="firstName"
                >
                    <Input placeholder='Иван'/>
                </Form.Item>

                <Form.Item
                    label={<span className='inputLabel'>Фамилия</span>}
                    name="lastName"
                >
                    <Input placeholder='Иванов'/>
                </Form.Item>

                <Form.Item
                    label={<span className='inputLabel'>Отчество</span>}
                    name="middleName"
                >
                    <Input placeholder='Иванович'/>
                </Form.Item>

                <Form.Item
                    label={<span className='inputLabel'>Дата рождения</span>}
                    name="dateOfBirth"
                >
                    <DatePicker style={{width: "100%"}} />
                </Form.Item>

                <div className={styles.formRow}>
                    <Form.Item>
                        <Button
                            onClick={() => onFilterFormReset()}
                        >
                            Сбросить
                        </Button>
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className={styles.loginButton}
                        >
                            Применить
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </div>
    )
}

export default CandidatesFilter
