import {useSortable} from "@dnd-kit/sortable";
import {CSS} from '@dnd-kit/utilities';
import styles from "../../../styles/Settings/FunnelTemplates/FunnelTemplates.module.scss";
import React from "react";
import {CloseOutlined, HolderOutlined} from "@ant-design/icons";
import {Checkbox} from "antd";

const SortableItem = (props: any) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id: props.id});

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners} className={styles.listItem}>
            <div className={styles.itemTitleContainer}>
                <div className={styles.itemTitleInnerContainer}>
                    <HolderOutlined/>
                    <div className={styles.ordinalNumber}>{props?.data?.sequenceNumber}</div>
                    <div className={styles.titleInnerContainer}>
                        <span className={styles.title}>{props?.data?.name}</span>
                    </div>
                </div>

                <div className={styles.itemActions}>
                    {
                        props.isCheckboxes && <Checkbox
                            checked={props?.data?.isWorkTaskPositionCloser}
                            onChange={(e) => {
                                props.handleChecked(props?.id, e.target.checked)
                            }}>Закрывает позицию</Checkbox>
                    }
                    <CloseOutlined style={{fontSize: "18px", color: "red"}}
                                   onClick={() => props.removeItem(props?.data)}
                    />
                </div>

            </div>
        </div>
    );
}

export default SortableItem
