import React, {useEffect, useState} from 'react'
import {Select, SelectProps} from "antd";
import api from "../../helpers/api";
import {SearchOutlined} from "@ant-design/icons";

let timeout: ReturnType<typeof setTimeout> | null;
let currentValue: string;

const fetch = (value: string, callback: Function) => {
    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }
    currentValue = value;

    const search = () => {
        api.get(`https://echire.ru/api/kladr/search?Keywords=${value}&Size=100`)
            .then((response) => {
                if (response.data.value.length !== 0) {
                    const data = response.data.value.map((el: any) => ({
                        text: el.name,
                        value: `${el.kladrCode}~${el.name}`
                    }))

                    callback(data)
                } else callback([])
            })
    };
    if (value) {
        timeout = setTimeout(search, 200);
    } else {
        callback([]);
    }
};

// @ts-ignore
const SearchInput: React.FC<{placeholder: string, handleChange: Function, values: any}> = (props) => {
    const [data, setData] = useState<SelectProps['options']>([]);
    const [value, setValue] = useState<string>();

    useEffect(() => {
        if (!!props.values) {
            console.log(props.values)
            setValue(() => props.values.map((item: any) => (
                {
                    value: `${item.kladrCode}~${item.name}`,
                    label: item.name
                }
            )))
        }
    }, [props.values])

    const handleSearch = (newValue: string) => {
        fetch(newValue, setData);
    };

    const handleChange = (newValue: string) => {
        setValue(newValue);
        props.handleChange(newValue)
    };

    return (
        <Select
            showSearch
            value={value}
            mode='multiple'
            placeholder={props.placeholder}
            defaultActiveFirstOption={false}
            suffixIcon={<SearchOutlined width={24} height={24} style={{color: "#AFB1B6", marginInlineEnd: "8px"}}/>}
            filterOption={false}
            onSearch={handleSearch}
            //@ts-ignore
            onChange={handleChange}
            notFoundContent={null}
            options={(data || []).map((d) => ({
                value: d.value,
                label: d.text,
            }))}
        />
    );
}

const KladrSearch = (props: any) => {
    return (
        <SearchInput placeholder="Введите название города" handleChange={props.onChangeValue} values={props.values}/>
    )
}

export default KladrSearch
