import {createSlice} from "@reduxjs/toolkit";

interface RejectionReasonsSlice {
    reasons: any;
    isLoading: boolean;
    error: any;
    success: any;
}

const initialState: RejectionReasonsSlice = {
    reasons: [],
    isLoading: false,
    error: null,
    success: null
}

export const rejectionReasonsSlice = createSlice({
    name: "rejectionReasons",
    initialState,
    reducers: {
        isLoading: (state) => {
            state.isLoading = true
            state.error = null
            state.success = null
            state.reasons = null
        },
        isFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload
        },
        isSuccess: (state, action) => {
            state.isLoading = false
            state.success = action.payload
        },
        setReasons: (state, action) => {
            state.isLoading = false
            state.reasons = action.payload
        }
    }
})

export default rejectionReasonsSlice.reducer
