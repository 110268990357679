import {AppDispatch} from "../index";
import {getErrorMessage} from "../../helpers";
import {groupsSlice} from "../reducers/groupsSlice";
import api from "../../helpers/api";

export const getAllGroups = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(groupsSlice.actions.isLoading())

        const response = await api.get(`${process.env.REACT_APP_API_URI_HTTPS}/api/groups/list?&WorkspaceId=${data.workspaceId}&PageNumber=${data.pageNumber}&PageSize=8`)

        if (response.data.succeeded) {
            dispatch(groupsSlice.actions.setGroups(response.data.value))
        } else {
            dispatch(groupsSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(groupsSlice.actions.isFailure(message))
    }
}

export const createGroup = (group: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(groupsSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/groups/create`, group)

        if (response.data.succeeded) {
            dispatch(groupsSlice.actions.isSuccess("Группа успешно создана"))
        } else {
            dispatch(groupsSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(groupsSlice.actions.isFailure(message))
    }
}

export const renameGroup = (group: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(groupsSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/groups/rename`, group)

        if (response.data.succeeded) {
            dispatch(groupsSlice.actions.isSuccess("Группа успешно переименована"))
        } else {
            dispatch(groupsSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(groupsSlice.actions.isFailure(message))
    }
}

export const getGroupById = (groupId: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(groupsSlice.actions.isLoading())

        const response = await api.get(`${process.env.REACT_APP_API_URI_HTTPS}/api/groups/get?&GroupId=${groupId}`)

        if (response.data.succeeded) {
            dispatch(groupsSlice.actions.setCurrentGroup(response.data.value))
        } else {
            dispatch(groupsSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(groupsSlice.actions.isFailure(message))
    }
}

export const deleteGroup = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(groupsSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/groups/delete`, data)

        if (response.data.succeeded) {
            dispatch(groupsSlice.actions.isSuccess("Группа успешно удалена"))
        } else {
            dispatch(groupsSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(groupsSlice.actions.isFailure(message))
    }
}

export const addParticipants = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(groupsSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/groups/participants/add`, data)

        if (response.data.succeeded) {
            dispatch(groupsSlice.actions.isSuccess("Участники успешно добавлены"))
        } else {
            dispatch(groupsSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(groupsSlice.actions.isFailure(message))
    }
}

export const removeParticipants = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(groupsSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/groups/participants/remove`, data)

        if (response.data.succeeded) {
            dispatch(groupsSlice.actions.isSuccess("Участники успешно удалены"))
        } else {
            dispatch(groupsSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(groupsSlice.actions.isFailure(message))
    }
}

export const changeSenior = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(groupsSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/groups/senior/change`, data)

        if (response.data.succeeded) {
            dispatch(groupsSlice.actions.isSuccess("Руководитель успешно изменен"))
        } else {
            dispatch(groupsSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(groupsSlice.actions.isFailure(message))
    }
}
