import React from "react";

import { Navigate, Outlet } from "react-router-dom";
import {useAppSelector} from "../../hooks/redux";

const ProtectedRoutes = () => {
    // @ts-ignore
    const {user} = useAppSelector(state => state.authReducer)
    const {currentWorkspace} = useAppSelector(state => state.workspacesReducer)

    if (user?.access_token) {
        if (currentWorkspace?.id) {
            return <Outlet/>
        }
        else if (window.location.href.includes('/selectWorkspace')) {
            return <Outlet/>
        }
        else return <Navigate to="/login"/>
    }
    else return <Navigate to="/login" />
};

export default ProtectedRoutes;
