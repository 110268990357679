// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TaskMainPanel_container__y6yWB {
  margin-top: 16px;
}

.TaskMainPanel_filterForm__0D9ys {
  display: flex;
  flex-direction: row;
  gap: 80px;
}

.TaskMainPanel_descriptionContainer__5enOE {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.TaskMainPanel_vacancyContainer__9x-uE {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.TaskMainPanel_columnTitle__KQbzX {
  font-size: 16px;
  font-weight: 600;
}

.TaskMainPanel_innerFormContainer__Dgx4P {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.TaskMainPanel_innerFormContainer__Dgx4P div {
  margin-bottom: 0;
}

.TaskMainPanel_formRow__Wi\\+JX {
  display: flex;
  gap: 24px;
}
.TaskMainPanel_formRow__Wi\\+JX div {
  width: 100%;
}

.TaskMainPanel_item__LC3LJ {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.TaskMainPanel_item__LC3LJ .TaskMainPanel_label__gmlJy {
  color: #AFB1B6;
}`, "",{"version":3,"sources":["webpack://./src/styles/Tasks/TaskMainPanel.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AACF;;AAEA;EACE,UAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,UAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;AACE;EACE,gBAAA;AACJ;;AAGA;EACE,aAAA;EACA,SAAA;AAAF;AAEE;EACE,WAAA;AAAJ;;AAKA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AAFF;AAIE;EACE,cAAA;AAFJ","sourcesContent":[".container {\n  margin-top: 16px;\n}\n\n.filterForm {\n  display: flex;\n  flex-direction: row;\n  gap: 80px;\n}\n\n.descriptionContainer {\n  width: 40%;\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n\n.vacancyContainer {\n  width: 60%;\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n\n.columnTitle {\n  font-size: 16px;\n  font-weight: 600;\n}\n\n.innerFormContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n\n  div {\n    margin-bottom: 0;\n  }\n}\n\n.formRow {\n  display: flex;\n  gap: 24px;\n\n  div {\n    width: 100%;\n  }\n}\n\n\n.item {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n\n  .label {\n    color: #AFB1B6;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `TaskMainPanel_container__y6yWB`,
	"filterForm": `TaskMainPanel_filterForm__0D9ys`,
	"descriptionContainer": `TaskMainPanel_descriptionContainer__5enOE`,
	"vacancyContainer": `TaskMainPanel_vacancyContainer__9x-uE`,
	"columnTitle": `TaskMainPanel_columnTitle__KQbzX`,
	"innerFormContainer": `TaskMainPanel_innerFormContainer__Dgx4P`,
	"formRow": `TaskMainPanel_formRow__Wi+JX`,
	"item": `TaskMainPanel_item__LC3LJ`,
	"label": `TaskMainPanel_label__gmlJy`
};
export default ___CSS_LOADER_EXPORT___;
