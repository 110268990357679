import React, {useEffect, useState} from 'react'
import styles from '../../../styles/Settings/Groups/GroupsMain.module.scss'
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {useForm} from "antd/es/form/Form";
import {Button, Dropdown, Empty, Form, Input, MenuProps, message, Modal, Pagination, Select, Spin} from "antd";
import {DeleteOutlined, DownOutlined, PlusOutlined} from "@ant-design/icons";
import {getParticipants} from "../../../redux/actions/ParticipantsActions";
import {createGroup, deleteGroup, getAllGroups} from "../../../redux/actions/GroupsActions";
import {IParticipant} from "../../models/IParticipant";
import {useNavigate} from "react-router-dom";
import {getGroupStatus} from "../../../helpers";
import {useImprovedUXValidation} from "../../../hooks/useImprovedUXValidation";
import {getAllCompanies} from "../../../redux/actions/CompaniesActions";


const GroupsMain = () => {

    const {onFinishFailed, validationMode, onBlur} = useImprovedUXValidation(['name', 'seniorId', 'participants'])
    const dispatch = useAppDispatch()
    const [form] = useForm()
    const [createForm] = useForm()
    const navigate = useNavigate()
    const [messageApi, contextHolder] = message.useMessage();
    const [isEditModalOpen, setIsEditModalOpen] = useState({open: false, id: null})
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState({open: false, id: null})
    const [isAddNewModalOpen, setIsAddNewModalOpen] = useState(false)

    const {currentWorkspace} = useAppSelector(state => state.workspacesReducer)
    const {participants} = useAppSelector(state => state.participantsReducer)
    const {groups, error, success, isLoading} = useAppSelector(state => state.groupsReducer)

    const companyActions: MenuProps['items'] = [
        {
            label: <span className={styles.actionItem}><DeleteOutlined/>Удалить</span>,
            key: '1',
        }
    ];
    const handleActions = (key: any, id: any) => {
        switch (key) {
            case '1':
                setIsDeleteModalOpen({open: true, id: id})
                break;
            default:
                break;
        }
    }

    const onPageNumberChange = (value: any) => {
        dispatch(getAllGroups({workspaceId: currentWorkspace?.id, pageNumber: value}))
    }

    useEffect(() => {
        dispatch(getParticipants({workspaceId: currentWorkspace?.id, pageNumber: 1}))
        dispatch(getAllGroups({workspaceId: currentWorkspace?.id, pageNumber: 1}))
    }, [])

    useEffect(() => {
        if (error) {
            messageApi.open({
                type: 'error',
                content: error,
            });
        }

        if (success) {
            messageApi.open({
                type: 'success',
                content: success,
            });
        }
    }, [error, success])

    return (
        <>
            {contextHolder}
            <div className={styles.container}>
                <div className={styles.buttonContainer}>
                    <span className={styles.listHeader}>Группы</span>
                    <Button type='primary' icon={<PlusOutlined/>} onClick={() => setIsAddNewModalOpen(true)}>Добавить
                        группу</Button>
                </div>

                <Spin spinning={isLoading} wrapperClassName={styles.spinnerWrapper}>
                    <div className={styles.listContainer}>
                        {
                            groups?.items?.length !== 0 ?
                                <>
                                    {
                                        groups?.items?.map((group: any) => {
                                            return (
                                                <div className={styles.listItem} key={group?.id}>
                                                    <div className={styles.listItemHeading}>
                                                        <div className={styles.listItemTitleContainer}
                                                             onClick={() => navigate(`/settings/groups/${group?.id}`)}>
                                                            <div>{group?.name}</div>
                                                            {getGroupStatus(group?.isArchived)}
                                                        </div>

                                                        {
                                                            !group?.isArchived &&
                                                            <div className={styles.listItemActionsContainer}>
                                                                <div className={styles.listItemActions}>
                                                                    {/*@ts-ignore*/}
                                                                    <Dropdown menu={{
                                                                        items: companyActions, onClick: ({key}) => {
                                                                            handleActions(key, group?.id)
                                                                        }
                                                                    }}>
                                                                        <a onClick={(e) => {
                                                                            e.preventDefault()
                                                                            e.stopPropagation()

                                                                        }}>
                                                                            Действия
                                                                            <DownOutlined/>
                                                                        </a>
                                                                    </Dropdown>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>

                                                    <div className={styles.listItemBody}>
                                                        <div className={styles.listItemInfoContainer}>
                                                            <div className={styles.listItemInfoItem}>
                                                                <img src="/profile_icon.svg" alt="Vacancy"/>
                                                                <span>
                                                                    Руководитель:
                                                                    {
                                                                        participants?.items?.filter((item: any) => item?.id === group?.seniorId)
                                                                            .map((data: any) => (` ${data?.user?.fullName?.last} ${data?.user?.fullName?.first}`))
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        groups?.totalCount > 8 &&
                                        <Pagination
                                            onChange={onPageNumberChange}
                                            defaultPageSize={8}
                                            current={groups?.pageNumber}
                                            total={groups?.totalCount}
                                        />
                                    }
                                </>
                                :
                                <div>
                                    <Empty description='Группы отсутствуют'/>
                                </div>
                        }
                    </div>
                </Spin>
            </div>

            <Modal
                title='Добавить группу'
                open={isAddNewModalOpen}
                okText="Добавить"
                cancelText="Отмена"

                onOk={() => {
                    createForm.submit()
                }}
                onCancel={() => {
                    createForm.resetFields()
                    setIsAddNewModalOpen(false)
                }}
            >
                <Form
                    form={createForm}
                    layout='vertical'
                    autoComplete='off'
                    requiredMark='optional'
                    onFinishFailed={onFinishFailed}
                    onFinish={(values) => {
                        dispatch(createGroup({...values, workspaceId: currentWorkspace?.id}))
                            .then(() => dispatch(getAllGroups({workspaceId: currentWorkspace?.id, pageNumber: 1})))
                        setIsAddNewModalOpen(false)
                    }}
                >
                    <Form.Item
                        label={<span className='inputLabel'>Название группы</span>}
                        name="name"
                        validateTrigger={validationMode.name}
                        rules={[{required: true, message: 'Введите название группы'}]}
                    >
                        <Input placeholder='Группа 1' onBlur={onBlur('name')}/>
                    </Form.Item>

                    <Form.Item
                        label={<span className='inputLabel'>Руководитель</span>}
                        name="seniorId"
                        validateTrigger={validationMode.seniorId}
                        rules={[{required: true, message: 'Выберите руководителя'}]}
                    >
                        <Select onBlur={onBlur('seniorId')} options={participants?.items?.map((item: IParticipant) => (
                            {
                                value: item.userId,
                                label:
                                    <span>{`${item?.user?.fullName?.last} ${item?.user?.fullName?.first}`}</span>
                            }
                        ))}/>
                    </Form.Item>

                    <Form.Item
                        label={<span className='inputLabel'>Участники</span>}
                        name="participants"
                        validateTrigger={validationMode.participants}
                        rules={[{required: true, message: 'Выберите участников'}]}
                    >
                        <Select
                            onBlur={onBlur('participants')}
                            mode="multiple"
                            options={participants?.items?.map((item: IParticipant) => (
                                {
                                    value: item.userId,
                                    label:
                                        <span>{`${item?.user?.fullName?.last} ${item?.user?.fullName?.first}`}</span>
                                }
                            ))}/>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title='Подтверждение операции'
                open={isDeleteModalOpen.open}
                okText="Да"
                cancelText="Отмена"

                onOk={() => {
                    dispatch(deleteGroup({
                        workspaceId: currentWorkspace?.id,
                        groupId: isDeleteModalOpen?.id,
                    }))
                        .then(() => setIsDeleteModalOpen({open: false, id: null}))
                        .then(() => dispatch(getAllGroups({workspaceId: currentWorkspace?.id, pageNumber: 1})))
                }}
                onCancel={() => setIsDeleteModalOpen(() => ({open: false, id: null}))}
            >
                Вы уверены, что хотите удалить группу?
            </Modal>
        </>
    )
}

export default GroupsMain
