// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RegisterPage_container__7qA7K {
  display: flex;
  justify-content: center;
  align-items: center;
}
.RegisterPage_container__7qA7K .RegisterPage_logo__rxOgB {
  margin-bottom: 32px;
}
.RegisterPage_container__7qA7K .RegisterPage_headingContainer__\\+2Nce {
  display: flex;
  gap: 10px;
  margin-bottom: 42px;
}
.RegisterPage_container__7qA7K h1 {
  font-weight: 600;
}
.RegisterPage_container__7qA7K .RegisterPage_containerInner__hx42u {
  width: 440px;
}
.RegisterPage_container__7qA7K .RegisterPage_inputRow__DdXQk {
  display: flex;
  gap: 24px;
}
.RegisterPage_container__7qA7K .RegisterPage_loginButton__7Qb4G {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
}`, "",{"version":3,"sources":["webpack://./src/styles/Auth/RegisterPage.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;AACE;EACE,mBAAA;AACJ;AAEE;EACE,aAAA;EACA,SAAA;EACA,mBAAA;AAAJ;AAGE;EACE,gBAAA;AADJ;AAIE;EACE,YAAA;AAFJ;AAKE;EACE,aAAA;EACA,SAAA;AAHJ;AAME;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AAJJ","sourcesContent":[".container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  .logo {\n    margin-bottom: 32px;\n  }\n\n  .headingContainer {\n    display: flex;\n    gap: 10px;\n    margin-bottom: 42px;\n  }\n\n  h1 {\n    font-weight: 600;\n  }\n\n  .containerInner {\n    width: 440px;\n  }\n\n  .inputRow {\n    display: flex;\n    gap: 24px;\n  }\n\n  .loginButton {\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-top: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RegisterPage_container__7qA7K`,
	"logo": `RegisterPage_logo__rxOgB`,
	"headingContainer": `RegisterPage_headingContainer__+2Nce`,
	"containerInner": `RegisterPage_containerInner__hx42u`,
	"inputRow": `RegisterPage_inputRow__DdXQk`,
	"loginButton": `RegisterPage_loginButton__7Qb4G`
};
export default ___CSS_LOADER_EXPORT___;
