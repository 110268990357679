import React, {useEffect} from 'react'
import {Button, Form, Input, message} from "antd";
import styles from "../../styles/Auth/RegisterPage.module.scss";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {setRegisterData} from "../../redux/actions/AuthActions";
import {useImprovedUXValidation} from "../../hooks/useImprovedUXValidation";

const RegisterPage: React.FC = () => {

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const {onFinishFailed, validationMode, onBlur} = useImprovedUXValidation(['firstName', 'lastName', 'middleName', 'email', 'phone', 'password', 'changePassword'])
    const {isLoading, error} = useAppSelector(state => state.authReducer);
    const dispatch = useAppDispatch();


    useEffect(() => {
        if (error) {
            messageApi.open({
                type: 'error',
                content: error,
            });
        }
    }, [error])

    return (
        <>
            {contextHolder}
            <div className={styles.container}>
                <div className={styles.containerInner}>
                    <img src="/exp_logo_text_sm.svg" alt="logo_sm" className={styles.logo}/>
                    <div className={styles.headingContainer}>
                        <img src="/arrow_left.svg" alt="arrow_left" onClick={() => navigate('/login')}/>
                        <h1>Регистрация</h1>
                    </div>

                    <Form
                        form={form}
                        requiredMark='optional'
                        layout='vertical'
                        autoComplete='off'
                        onFinishFailed={onFinishFailed}
                        onFinish={(values) => {
                            if (values?.phone) {
                                values.phone = '+7' + values?.phone
                            }

                            dispatch(setRegisterData(Object.fromEntries(Object.entries(values).filter(([_, v]) => v !== ""))))
                                .then(success => {
                                    if (success) {
                                        navigate('/register/confirm')
                                    }
                                })
                        }}
                    >
                        <div className={styles.inputRow}>
                            <Form.Item
                                label={<span className='inputLabel'>Имя</span>}
                                name="firstName"
                                validateTrigger={validationMode.firstName}
                                rules={[
                                    {required: true, message: 'Заполните поле'},
                                    {min: 3, message: 'Не менее 3 символов'},
                                    {max: 30, message: 'Не более 30 символов'},
                                    {pattern: new RegExp('^[А-Яа-яЁёA-Za-z]+$'), message: 'Должно содержать только буквы'}
                                ]}
                            >
                                <Input placeholder='Иван' onBlur={onBlur('firstName')}/>
                            </Form.Item>

                            <Form.Item
                                label={<span className='inputLabel'>Фамилия</span>}
                                name="lastName"
                                validateTrigger={validationMode.lastName}
                                rules={[
                                    {required: true, message: 'Заполните поле'},
                                    {min: 3, message: 'Не менее 3 символов'},
                                    {max: 30, message: 'Не более 30 символов'},
                                    {pattern: new RegExp('^[А-Яа-яЁёA-Za-z]+$'), message: 'Должно содержать только буквы'}
                                ]}
                            >
                                <Input placeholder='Иванов' onBlur={onBlur('lastName')}/>
                            </Form.Item>
                        </div>

                        <Form.Item
                            label={<span className='inputLabel'>Отчество</span>}
                            name="middleName"
                            validateTrigger={validationMode.middleName}
                            rules={[
                                {min: 3, message: 'Не менее 3 символов'},
                                {max: 30, message: 'Не более 30 символов'},
                                {pattern: new RegExp('^[А-Яа-яЁёA-Za-z]+$'), message: 'Должно содержать только буквы'}
                            ]}
                        >
                            <Input placeholder='Иванович' value="" onBlur={onBlur('middleName')}/>
                        </Form.Item>

                        <Form.Item
                            label={<span className='inputLabel'>Адрес электронной почты</span>}
                            name="email"
                            validateTrigger={validationMode.email}
                            rules={[{required: true, type: 'email', message: 'Введите адрес электронной почты'}]}
                        >
                            <Input placeholder='ivanov_ivan@gmail.com' onBlur={onBlur('email')}/>
                        </Form.Item>

                        <Form.Item
                            label={<span className='inputLabel'>Контактный номер</span>}
                            name="phone"
                            validateTrigger={validationMode.phone}
                            rules={[
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        if (new RegExp("^[0-9\\-\\+]{10}$").test(value)) {
                                            return Promise.resolve();
                                        } else if (!value || value.length === 0) {
                                            return Promise.resolve();
                                        } else {
                                            return Promise.reject(new Error("Введите корректный телефон"));
                                        }
                                    },
                                }),
                            ]}
                        >
                            <Input addonBefore="+7" placeholder='8005553535' onBlur={onBlur('phone')}/>
                        </Form.Item>

                        <Form.Item
                            label={<span className='inputLabel'>Пароль</span>}
                            name="password"
                            validateTrigger={validationMode.password}
                            rules={[{required: true, message: 'Введите пароль'},
                                {min: 6, message: 'Пароль должен содержать не менее 6 символов'},
                                {pattern: new RegExp('^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{6,}$'), message: 'Обязательно заглавная буква, строчная, цифра и спец. символ'}
                                //{pattern: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{6,}$'), message: 'Обязательно заглавная буква, строчная и цифра'}
                            ]}
                        >
                            <Input.Password placeholder='Введите пароль' onBlur={onBlur('password')}/>
                        </Form.Item>

                        <Form.Item
                            label={<span className='inputLabel'>Подтверждение пароля</span>}
                            name="confirmPassword"
                            validateTrigger={validationMode.confirmPassword}
                            dependencies={['password']}
                            rules={[
                                {required: true, message: 'Введите пароль ещё раз'},
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Пароли не совпадают'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password placeholder='Введите пароль' onBlur={onBlur('confirmPassword')}/>
                        </Form.Item>

                        <Form.Item>
                            <Button
                                loading={isLoading}
                                type="primary"
                                htmlType="submit"
                                className={styles.loginButton}
                            >
                                Зарегистрироваться
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default RegisterPage
