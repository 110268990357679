import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './custom-antd.css'
import App from './App';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import ErrorPage from "./Components/ErrorPage";
import MainLayout from "./Components/Layouts/MainLayout";
import AuthLayout from "./Components/Layouts/AuthLayout";
import LoginPage from "./Components/Auth/LoginPage";
import RegisterPage from "./Components/Auth/RegisterPage";
import RegistrationFinal from "./Components/Auth/RegistrationFinal";
import PasswordReset from "./Components/Auth/PasswordReset";
import PasswordResetFinal from "./Components/Auth/PasswordResetFinal";
import WorkspaceAfterAuthPage from "./Components/WorkspaceAfterAuth/WorkspaceAfterAuthPage";
import SettingsLayout from "./Components/Layouts/SettingsLayout";
import Members from "./Components/Settings/Members/Members";
import {Provider} from 'react-redux'
import {setupStore} from './redux';
import SignInCallback from "./Components/Auth/SignInCallback";
import ProtectedRoutes from "./Components/Auth/ProtectedRoutes";
import TasksMain from "./Components/Tasks/TasksMain";
import ru_RU from "antd/lib/locale/ru_RU";
import {ConfigProvider} from 'antd';
import CreateTask from "./Components/Tasks/CreateTask";
import TaskPage from "./Components/Tasks/TaskPage";
import EditTask from "./Components/Tasks/EditTask";
import VacanciesMain from "./Components/Vacancies/VacanciesMain";
import CreateVacancy from "./Components/Vacancies/CreateVacancy";
import EditVacancy from "./Components/Vacancies/EditVacancy";
import VacancyPage from "./Components/Vacancies/VacancyPage";
import CandidatesMain from "./Components/Candidates/CandidatesMain";
import Companies from "./Components/Settings/Companies";
import GroupsMain from "./Components/Settings/Groups/GroupsMain";
import GroupsPage from "./Components/Settings/Groups/GroupsPage";
import StagesMain from "./Components/Settings/StagesMain";
import FunnelTemplatesMain from "./Components/Settings/FunnelTemplates/FunnelTemplatesMain";
import FunnelTemplatesPage from "./Components/Settings/FunnelTemplates/FunnelTemplatesPage";
import CreateFunnelPage from "./Components/Settings/FunnelTemplates/CreateFunnelPage";
import EditFunnelPage from "./Components/Settings/FunnelTemplates/EditFunnelPage";
import DepartmentsMain from "./Components/Settings/Departments/DepartmentsMain";
import ProfileMain from "./Components/Profile/ProfileMain";
import RejectionReasonsMain from "./Components/Settings/RejectionReasons/RejectionReasonsMain";
import RegisterByInvite from "./Components/Auth/RegisterByInvite";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
    {
        element: <ProtectedRoutes/>,
        children: [
            {
                path: "/",
                element: <MainLayout/>,
                errorElement: <ErrorPage/>,
                children: [
                    {
                        path: "/",
                        element: <App/>
                    },
                    {
                        path: "/candidates",
                        element: <CandidatesMain/>
                    },
                    {
                        path: "/profile",
                        element: <ProfileMain/>
                    },
                    {
                        path: "/vacancies",
                        element: <VacanciesMain/>
                    },
                    {
                        path: "/vacancies/create",
                        element: <CreateVacancy/>
                    },
                    {
                        path: "/vacancies/edit/:id",
                        element: <EditVacancy/>
                    },
                    {
                        path: "/vacancies/:id",
                        element: <VacancyPage/>
                    },
                    {
                        path: "/tasks",
                        element: <TasksMain/>,
                    },
                    {
                        path: "/tasks/create",
                        element: <CreateTask/>
                    },
                    {
                        path: "/tasks/edit/:id",
                        element: <EditTask/>
                    },
                    {
                      path: "/tasks/:id",
                      element: <TaskPage/>
                    },
                    {
                        path: "/settings/groups/:id",
                        element: <GroupsPage/>
                    },
                    {
                        path: "/settings/templates/:id",
                        element: <FunnelTemplatesPage/>
                    },
                    {
                        path: "/settings/templates/create",
                        element: <CreateFunnelPage/>
                    },
                    {
                        path: "/settings/templates/edit/:id",
                        element: <EditFunnelPage/>
                    },
                    {
                        path: "/settings",
                        element: <SettingsLayout/>,
                        children: [
                            {
                                path: "/settings/companies",
                                element: <Companies/>,
                            },
                            {
                                path: "/settings/members",
                                element: <Members/>,
                            },
                            {
                                path: "/settings/stages",
                                element: <StagesMain/>,
                            },
                            {
                                path: "/settings/departments",
                                element: <DepartmentsMain/>,
                            },
                            {
                                path: "/settings/groups",
                                element: <GroupsMain/>,
                            },
                            {
                                path: "/settings/reasons",
                                element: <RejectionReasonsMain/>
                            },
                            {
                                path: "/settings/templates",
                                element: <FunnelTemplatesMain/>,
                            }
                        ]
                    }
                ]
            },
            {
                path: "/",
                element: <AuthLayout/>,
                errorElement: <ErrorPage/>,
                children: [
                    {
                        path: "/selectWorkspace",
                        element: <WorkspaceAfterAuthPage/>
                    }
                ]
            }
        ]
    },
    {
        path: "/",
        element: <AuthLayout/>,
        errorElement: <ErrorPage/>,
        children: [
            {
                path: "/login",
                element: <LoginPage/>
            },
            {
                path: "/register",
                element: <RegisterPage/>
            },
            {
                path: "/register/confirm",
                element: <RegistrationFinal/>
            },
            {
                path: "/password/reset",
                element: <PasswordReset/>
            },
            {
                path: "/password/reset/confirm",
                element: <PasswordResetFinal/>
            },
            {
                path: "/invites/accept/:inviteToken",
                element: <RegisterByInvite/>
            }
        ]
    },
    {
        path: "/signin-callback",
        element: <SignInCallback/>
    },
]);

// @ts-ignore
root.render(
    <ConfigProvider locale={ru_RU}
                    theme={{
                        token: {
                            fontFamily: "Montserrat, sans-serif",
                            colorText: "#222222",
                        },
                        components: {
                            Select: {
                                controlHeight: 38
                            },
                            Input: {
                                controlHeight: 38
                            },
                            Radio: {
                                controlHeight: 38
                            }
                        }
                    }}
    >
        <Provider store={setupStore()}>
            <RouterProvider router={router}/>
        </Provider>
    </ConfigProvider>
);
