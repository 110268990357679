import React, {useEffect, useState} from 'react'
import styles from '../../styles/Settings/StagesMain.module.scss'
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {
    Button,
    Checkbox,
    ConfigProvider,
    Dropdown,
    Form,
    Input,
    MenuProps,
    message,
    Modal,
    Select,
    Space,
    Spin
} from "antd";
import {
    createStage,
    getMetrics,
    getStages,
    getStagesById, removeAssessment,
    removeStage,
    updateStage
} from "../../redux/actions/StagesActions";
import {BlockOutlined, DeleteOutlined, DownOutlined, MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {useForm} from "antd/es/form/Form";
import {useImprovedUXValidation} from "../../hooks/useImprovedUXValidation";

const StagesMain = () => {

    const {
        onFinishFailed,
        validationMode,
        onBlur
    } = useImprovedUXValidation(['title', 'description', 'metricId', 'mandatoryAssessmentMode'])
    const dispatch = useAppDispatch()
    const [createForm] = useForm()
    const [editForm] = useForm()
    const [messageApi, contextHolder] = message.useMessage();
    const {currentWorkspace} = useAppSelector(state => state.workspacesReducer)
    const {stages, metrics, isLoading, error, success} = useAppSelector(state => state.stagesReducer)

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState({open: false, id: null})
    const [isNewStageModalOpen, setIsNewStageModalOpen] = useState<any>({open: false, blockStageType: null})
    const [isNewStageAssessments, setIsNewStageAssessments] = useState(false)
    const [isEditStageModalOpen, setIsEditStageModalOpen] = useState<any>({open: false, stage: null})

    useEffect(() => {
        dispatch(getStages({workspaceId: currentWorkspace?.id}))
        dispatch(getMetrics())
    }, []);

    useEffect(() => {

        if (isEditStageModalOpen?.stage) {
            dispatch(getStagesById({workspaceId: currentWorkspace?.id, stageId: isEditStageModalOpen?.stage?.id}))
                .then((data) => {
                    if (data.stageAssessments.length > 0) {
                        setIsNewStageAssessments(true)
                        setIsEditStageModalOpen((prev: any) => ({...prev, stage: {...prev.stage, stageAssessments: data.stageAssessments}}))
                    }
                    editForm.setFieldsValue({
                        title: isEditStageModalOpen?.stage?.title,
                        description: isEditStageModalOpen?.stage?.description,
                        hasParticipants: isEditStageModalOpen?.stage?.hasParticipants,
                        hasScheduledDateTime: isEditStageModalOpen?.stage?.hasScheduledDateTime,
                        stageAssessments: data.stageAssessments
                    })
                })
        }
    }, [isEditStageModalOpen?.open]);

    useEffect(() => {
        createForm.setFieldsValue({
            stageAssessments: [
                {
                    metricId: 1,
                    mandatoryAssessmentMode: 1
                }
            ]
        })
    }, [isNewStageModalOpen?.open]);

    useEffect(() => {
        if (error) {
            messageApi.open({
                type: 'error',
                content: error,
            });
        }

        if (success) {
            messageApi.open({
                type: 'success',
                content: success,
            });
        }
    }, [error, success])

    const stagesActions: MenuProps['items'] = [
        {
            label: <span className={styles.actionItem}><img src="/edit_icon.svg" alt="edit"/>Редактировать</span>,
            key: '1',
        },
        {
            label: <span className={styles.actionItem}><DeleteOutlined/>Удалить</span>,
            key: '2',
        }
    ];

    const handleActions = (key: any, id: any, data?: any) => {
        switch (key) {
            case '1':
                setIsEditStageModalOpen({open: true, stage: data})
                break;
            case '2':
                setIsDeleteModalOpen({open: true, id: id})
                break;
            default:
                break;
        }
    }

    const assessmentModeOptions = [
        {value: 0, label: 'Необязательно'},
        {value: 1, label: 'Только ответственный'},
        {value: 2, label: 'Все участники'},
        {value: 3, label: 'Все участники и ответственный'},
        {value: 4, label: 'Как минимум 1 участник'},
        {value: 5, label: 'Как минимум 1 участник и ответственный'},
    ]

    return (
        <>
            {contextHolder}
            <Spin spinning={isLoading} wrapperClassName={styles.spinnerWrapper}>
                <div className={styles.container}>
                    <div className={styles.listContainer}>
                        <span className={styles.listHeader}>Процедура найма</span>

                        <div className={styles.innerListContainer}>
                            {
                                stages?.items?.filter((item: any) => item.blockStageType === 1)
                                    .map((stage: any, number: number) => {
                                        return (
                                            <div className={styles.listItem} key={stage?.id}>
                                                <div className={styles.titleContainer}>
                                                    <div className={styles.ordinalNumber}>
                                                        <BlockOutlined/>
                                                    </div>
                                                    <div className={styles.titleInnerContainer}>
                                                        <span className={styles.title}>{stage?.title}</span>
                                                        <span className={styles.description}>{stage?.description}</span>
                                                    </div>
                                                </div>

                                                <div className={styles.listItemActionsContainer}>
                                                    <div className={styles.actionsContainer}>
                                                        <Checkbox disabled checked={stage?.assessmentsCount}>Цифровые
                                                            оценки</Checkbox>
                                                        <Checkbox disabled
                                                                  checked={stage?.hasParticipants}>Участники</Checkbox>
                                                        <Checkbox disabled checked={stage?.hasScheduledDateTime}>Дата /
                                                            время</Checkbox>
                                                    </div>

                                                    <div className={styles.listItemActions}>
                                                        {/*@ts-ignore*/}
                                                        <Dropdown menu={{
                                                            items: stagesActions, onClick: ({key}) => {
                                                                handleActions(key, stage?.id, stage)
                                                            }
                                                        }}>
                                                            <a onClick={(e) => {
                                                                e.preventDefault()
                                                                e.stopPropagation()

                                                            }}>
                                                                Действия
                                                                <DownOutlined/>
                                                            </a>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                            }

                            <div className={styles.listItem}
                                 onClick={() => setIsNewStageModalOpen({open: true, blockStageType: 1})}>
                                <div className={styles.titleContainer}>
                                    <div className={styles.ordinalNumber} style={{fontSize: "24px"}}>+</div>
                                    <div className={styles.titleInnerContainer}>
                                        <span className={styles.title}>Добавить этап</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.listContainer}>
                        <span className={styles.listHeader}>После подписания</span>

                        <div className={styles.innerListContainer}>
                            {
                                stages?.items?.filter((item: any) => item.blockStageType === 2)
                                    .map((stage: any) => {
                                        return (
                                            <div className={styles.listItem} key={stage?.id}>
                                                <div className={styles.titleContainer}>
                                                    <div className={styles.ordinalNumber}><BlockOutlined/></div>
                                                    <div className={styles.titleInnerContainer}>
                                                        <span className={styles.title}>{stage?.title}</span>
                                                        <span className={styles.description}>{stage?.description}</span>
                                                    </div>
                                                </div>

                                                <div className={styles.listItemActionsContainer}>
                                                    <div className={styles.actionsContainer}>
                                                        <Checkbox disabled checked={stage?.assessmentsCount}>Цифровые
                                                            оценки</Checkbox>
                                                        <Checkbox disabled
                                                                  checked={stage?.hasParticipants}>Участники</Checkbox>
                                                        <Checkbox disabled checked={stage?.hasScheduledDateTime}>Дата /
                                                            время</Checkbox>
                                                    </div>

                                                    <div className={styles.listItemActions}>
                                                        {/*@ts-ignore*/}
                                                        <Dropdown menu={{
                                                            items: stagesActions, onClick: ({key}) => {
                                                                handleActions(key, stage?.id, stage)
                                                            }
                                                        }}>
                                                            <a onClick={(e) => {
                                                                e.preventDefault()
                                                                e.stopPropagation()

                                                            }}>
                                                                Действия
                                                                <DownOutlined/>
                                                            </a>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                            }

                            <div className={styles.listItem}
                                 onClick={() => setIsNewStageModalOpen({open: true, blockStageType: 2})}>
                                <div className={styles.titleContainer}>
                                    <div className={styles.ordinalNumber} style={{fontSize: "24px"}}>+</div>
                                    <div className={styles.titleInnerContainer}>
                                        <span className={styles.title}>Добавить этап</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Spin>


            <Modal
                title='Редактировать этап'
                open={isEditStageModalOpen.open}
                okText="Изменить"
                cancelText="Отмена"

                onOk={() => {
                    editForm.submit()
                }}
                onCancel={() => {
                    editForm.resetFields()
                    setIsEditStageModalOpen({open: false, stage: null})
                    setIsNewStageAssessments(false)
                }}
            >
                <ConfigProvider
                    theme={{
                        token: {
                            fontFamily: "Montserrat, sans-serif",
                            colorText: "#222222",
                        },
                        components: {
                            Checkbox: {
                                colorPrimary: "#4365E1",
                                fontSize: 14,
                                algorithm: true
                            }
                        }
                    }}
                >
                    <Form
                        form={editForm}
                        layout='vertical'
                        autoComplete='off'
                        requiredMark='optional'
                        onFinishFailed={onFinishFailed}
                        onFinish={(values) => {
                            if (isEditStageModalOpen?.stage?.stageAssessments?.length > 0 && (values?.stageAssessments?.length === 0 || values?.stageAssessments?.length === undefined)) {
                                isEditStageModalOpen?.stage?.stageAssessments.map((item: any) => {
                                    dispatch(removeAssessment({
                                        workspaceId: currentWorkspace?.id,
                                        stageId: isEditStageModalOpen?.stage?.id,
                                        metricId: item?.metricId
                                    }))
                                })
                            }
                            dispatch(updateStage({
                                ...values,
                                workspaceId: currentWorkspace?.id,
                                stageId: isEditStageModalOpen?.stage?.id,
                                blockStageType: isEditStageModalOpen?.stage?.blockStageType
                            }))
                                .then(() => setIsEditStageModalOpen({open: false, stage: null}))
                                .then(() => setIsNewStageAssessments(false))
                                .then(() => dispatch(getStages({workspaceId: currentWorkspace?.id})))
                            editForm.resetFields()
                        }}
                    >
                        <Form.Item
                            style={{marginTop: "12px"}}
                            label={<span className='inputLabel'>Название этапа</span>}
                            name="title"
                            validateTrigger={validationMode.title}
                            rules={[{required: true, message: 'Введите название этапа'}]}
                        >
                            <Input placeholder='Тестовое задание' maxLength={50} onBlur={onBlur('title')}/>
                        </Form.Item>

                        <Form.Item
                            label={<span className='inputLabel'>Описание этапа</span>}
                            name="description"
                            rules={[{max: 200, message: 'Превышено максимальное кол-во символов'}]}
                        >
                            <Input.TextArea placeholder='' autoSize={{minRows: 4, maxRows: 8}} showCount
                                            maxLength={200}/>
                        </Form.Item>

                        <Form.Item
                            style={{marginBottom: 0}}
                            name="hasParticipants"
                            valuePropName='checked'
                        >
                            <Checkbox>Участники</Checkbox>
                        </Form.Item>

                        <Form.Item
                            style={{marginBottom: 0}}
                            valuePropName='checked'
                            name='hasScheduledDateTime'
                        >
                            <Checkbox>Дата / время</Checkbox>
                        </Form.Item>

                        <Form.Item
                            style={{marginBottom: 0}}
                        >
                            <Checkbox checked={isNewStageAssessments} onChange={(e) => setIsNewStageAssessments(e.target.checked)}>Цифровые
                                оценки</Checkbox>
                        </Form.Item>

                        {isNewStageAssessments &&
                            <Form.List name="stageAssessments">
                                {(fields, {add, remove}) => (
                                    <div style={{marginTop: "12px"}}>
                                        {fields.map(({key, name, ...restField}) => (
                                            <Space key={key} className={styles.dynamicFormContainer}
                                                   style={{display: 'flex', marginBottom: 8, width: "100%"}}
                                                   align="baseline">
                                                <div>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'mandatoryAssessmentMode']}
                                                        validateTrigger={validationMode.mandatoryAssessmentMode}
                                                        rules={[{required: true, message: 'Выберите режим оценивания'}]}
                                                    >
                                                        <Select onBlur={onBlur('mandatoryAssessmentMode')}
                                                                style={{minWidth: "100%", width: "100%"}}
                                                                options={assessmentModeOptions}/>
                                                    </Form.Item>
                                                </div>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'metricId']}
                                                    validateTrigger={validationMode.metricId}
                                                    rules={[{required: true, message: 'Выберите метрику'}]}
                                                >
                                                    <Select onBlur={onBlur('metricId')}
                                                            style={{width: "100%"}}
                                                            options={metrics?.value?.map((metric: any) => ({
                                                                value: metric?.id,
                                                                label: metric?.name,
                                                            }))}
                                                    />
                                                </Form.Item>
                                                <MinusCircleOutlined onClick={() => remove(name)}/>
                                            </Space>
                                        ))}
                                        <Form.Item>
                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                                                Добавить запрос на оценку
                                            </Button>
                                        </Form.Item>
                                    </div>
                                )}
                            </Form.List>
                        }
                    </Form>
                </ConfigProvider>
            </Modal>

            <Modal
                title='Добавить новый этап'
                open={isNewStageModalOpen.open}
                okText="Добавить"
                cancelText="Отмена"
                width={650}
                onOk={() => {
                    createForm.submit()
                }}
                onCancel={() => {
                    createForm.resetFields()
                    setIsNewStageAssessments(false)
                    setIsNewStageModalOpen({open: false, blockStageType: null})
                }}
            >
                <ConfigProvider
                    theme={{
                        token: {
                            fontFamily: "Montserrat, sans-serif",
                            colorText: "#222222",
                        },
                        components: {
                            Checkbox: {
                                colorPrimary: "#4365E1",
                                fontSize: 14,
                                algorithm: true
                            }
                        }
                    }}
                >
                    <Form
                        form={createForm}
                        layout='vertical'
                        autoComplete='off'
                        requiredMark='optional'
                        onFinish={(values) => {
                            dispatch(createStage({
                                ...values,
                                workspaceId: currentWorkspace?.id,
                                blockStageType: isNewStageModalOpen?.blockStageType
                            }))
                                .then(() => setIsNewStageModalOpen({open: false, blockStageType: null}))
                                .then(() => setIsNewStageAssessments(false))
                                .then(() => dispatch(getStages({workspaceId: currentWorkspace?.id})))
                            createForm.resetFields()
                        }}
                    >
                        <Form.Item
                            style={{marginTop: "12px"}}
                            label={<span className='inputLabel'>Название этапа</span>}
                            name="title"
                            rules={[{required: true, message: 'Введите название этапа'}]}
                        >
                            <Input placeholder='Тестовое задание' maxLength={50}/>
                        </Form.Item>

                        <Form.Item
                            label={<span className='inputLabel'>Описание этапа</span>}
                            name="description"
                            rules={[{max: 200, message: 'Превышено максимальное кол-во символов'}]}
                        >
                            <Input.TextArea placeholder='' autoSize={{minRows: 4, maxRows: 8}} showCount
                                            maxLength={200}/>
                        </Form.Item>

                        <Form.Item
                            style={{marginBottom: 0}}
                            name="hasParticipants"
                            valuePropName='checked'
                        >
                            <Checkbox>Участники</Checkbox>
                        </Form.Item>

                        <Form.Item
                            style={{marginBottom: 0}}
                            valuePropName='checked'
                            name='hasScheduledDateTime'
                        >
                            <Checkbox>Дата / время</Checkbox>
                        </Form.Item>

                        <Form.Item
                            style={{marginBottom: 0}}
                        >
                            <Checkbox checked={isNewStageAssessments}
                                      onChange={(e) => setIsNewStageAssessments(e.target.checked)}>Цифровые
                                оценки</Checkbox>
                        </Form.Item>

                        {isNewStageAssessments &&
                            <Form.List name="stageAssessments">
                                {(fields, {add, remove}) => (
                                    <div style={{marginTop: "12px"}}>
                                        {fields.map(({key, name, ...restField}) => (
                                            <Space key={key} className={styles.dynamicFormContainer}
                                                   style={{display: 'flex', marginBottom: 8, width: "100%"}}
                                                   align="baseline">
                                                <div>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'mandatoryAssessmentMode']}
                                                        rules={[{required: true, message: 'Выберите режим оценивания'}]}
                                                    >
                                                        <Select style={{minWidth: "100%", width: "100%"}}
                                                                options={assessmentModeOptions}
                                                        />
                                                    </Form.Item>
                                                </div>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'metricId']}
                                                    rules={[{required: true, message: 'Выберите метрику'}]}
                                                >
                                                    <Select style={{width: "100%"}}
                                                            options={metrics?.map((metric: any) => ({
                                                                value: metric?.id,
                                                                label: metric?.name,
                                                            }))}
                                                    />
                                                </Form.Item>
                                                <MinusCircleOutlined onClick={() => remove(name)}/>
                                            </Space>
                                        ))}
                                        <Form.Item>
                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                                                Добавить запрос на оценку
                                            </Button>
                                        </Form.Item>
                                    </div>
                                )}
                            </Form.List>
                        }
                    </Form>
                </ConfigProvider>
            </Modal>

            <Modal
                title='Подтверждение операции'
                open={isDeleteModalOpen.open}
                okText="Да"
                cancelText="Отмена"

                onOk={() => {
                    dispatch(removeStage({
                        workspaceId: currentWorkspace?.id,
                        stageId: isDeleteModalOpen?.id
                    }))
                        .then(() => setIsDeleteModalOpen({open: false, id: null}))
                        .then(() => dispatch(getStages({workspaceId: currentWorkspace?.id})))
                }}
                onCancel={() => setIsDeleteModalOpen(() => ({open: false, id: null}))}
            >
                Вы уверены, что хотите удалить этап?
            </Modal>
        </>
    )
}

export default StagesMain
