import React, {useEffect, useState} from 'react'
import styles from '../../../styles/Settings/FunnelTemplates/CreateFunnelPage.module.scss'
import {Button, Form, Input, message, Modal, Select, Spin} from "antd";

import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {useImprovedUXValidation} from "../../../hooks/useImprovedUXValidation";
import {restrictToVerticalAxis} from "@dnd-kit/modifiers";
import {closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors} from "@dnd-kit/core";
import {SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy} from "@dnd-kit/sortable";
import SortableItem from "./SortableItem";
import {useForm} from "antd/es/form/Form";
import {getStages} from "../../../redux/actions/StagesActions";
import {getTemplateById, updateTemplate} from "../../../redux/actions/TemplatesActions";
import {useNavigate, useParams} from "react-router-dom";


const EditFunnelPage = () => {

    const {id} = useParams<{ id: string }>()
    const navigate = useNavigate()
    const {onFinishFailed, validationMode, onBlur} = useImprovedUXValidation(['name', 'description'])

    const [addForm] = useForm()
    const [mainForm] = useForm()
    const [isAddNewStageModalOpen, setIsAddNewStageModalOpen] = useState(false)
    const [hiringProcedureStages, setHiringProcedureStages] = useState([])
    const [afterSigningStages, setAfterSigningStages] = useState([])


    const dispatch = useAppDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const {currentWorkspace} = useAppSelector(state => state.workspacesReducer)
    const {stages} = useAppSelector(state => state.stagesReducer)
    const {currentTemplate, isLoading, error, success} = useAppSelector(state => state.templatesReducer)

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 8,
            }
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    function handleDragEndHiring(event: any) {
        const {active, over} = event;

        if (active.id !== over.id) {

            setHiringProcedureStages((prev: any) => {
                const oldIndex = prev.findIndex((item: any) => item.id === active.id)
                const newIndex = prev.findIndex((item: any) => item.id === over.id)

                if (oldIndex > newIndex) {
                    const copyPrev = [...prev]
                    //@ts-ignore
                    return copyPrev.toSpliced(newIndex, 0, copyPrev[oldIndex]).toSpliced(oldIndex + 1, 1).map((item: any, number) => {
                        item.sequenceNumber = number + 1
                        return item
                    })
                }
                else {
                    const copyPrev = [...prev]
                    //@ts-ignore
                    return copyPrev.toSpliced(newIndex + 1, 0, copyPrev[oldIndex]).toSpliced(oldIndex, 1).map((item: any, number) => {
                        item.sequenceNumber = number + 1
                        return item
                    })
                }

            });

        }
    }

    function handleDragEndFinish(event: any) {
        const {active, over} = event;

        if (active.id !== over.id) {

            setAfterSigningStages((prev: any) => {
                const oldIndex = prev.findIndex((item: any) => item.id === active.id)
                const newIndex = prev.findIndex((item: any) => item.id === over.id)

                if (oldIndex > newIndex) {
                    const copyPrev = [...prev]
                    //@ts-ignore
                    return copyPrev.toSpliced(newIndex, 0, copyPrev[oldIndex]).toSpliced(oldIndex + 1, 1).map((item: any, number) => {
                        item.sequenceNumber = hiringProcedureStages.length + number + 1
                        return item
                    })
                }
                else {
                    const copyPrev = [...prev]
                    //@ts-ignore
                    return copyPrev.toSpliced(newIndex + 1, 0, copyPrev[oldIndex]).toSpliced(oldIndex, 1).map((item: any, number) => {
                        item.sequenceNumber = hiringProcedureStages.length + number + 1
                        return item
                    })
                }

            });

        }
    }

    const handleChecked = (id: string, checked: boolean) => {
        setHiringProcedureStages((prev: any) => prev.map((item: any) => {
            if (item.id === id) {
                item.isWorkTaskPositionCloser = checked
            }
            else item.isWorkTaskPositionCloser = false
            return item
        }))
    }

    const removeItem = (data: any) => {
        const type = data?.blockStageType
        const id = data?.id
        switch (type) {
            case 1:
                let removeItemIndex1 = hiringProcedureStages.findIndex((item: any) => item.id === id)

                if (removeItemIndex1 === 0) {
                    //@ts-ignore
                    setHiringProcedureStages(hiringProcedureStages.filter((_: any, index: number) => index !== 0).map((item: any) => {
                        item.sequenceNumber = item.sequenceNumber - 1
                        return item
                    }))
                }

                if (removeItemIndex1 !== hiringProcedureStages.length - 1 && removeItemIndex1 !== 0) {
                    //@ts-ignore
                    setHiringProcedureStages(hiringProcedureStages.filter((_: any, index: number) => index !== removeItemIndex1).map((item: any, index: number) => {
                        if (index < removeItemIndex1) {
                            return item
                        }
                        else if (index >= removeItemIndex1) {
                            item.sequenceNumber = item.sequenceNumber - 1
                            return item
                        }
                    }))
                }

                if (removeItemIndex1 === hiringProcedureStages.length - 1) {
                    //@ts-ignore
                    setHiringProcedureStages(hiringProcedureStages.filter((_: any, index: number) => index !== removeItemIndex1))
                }
                break;
            case 2:
                let removeItemIndex2 = afterSigningStages.findIndex((item: any) => item.id === id)

                if (removeItemIndex2 === 0) {
                    //@ts-ignore
                    setAfterSigningStages(afterSigningStages.filter((_: any, index: number) => index !== 0).map((item: any) => {
                        item.sequenceNumber = item.sequenceNumber - 1
                        return item
                    }))
                }

                if (removeItemIndex2 !== afterSigningStages.length - 1 && removeItemIndex2 !== 0) {
                    //@ts-ignore
                    setAfterSigningStages(afterSigningStages.filter((_: any, index: number) => index !== removeItemIndex2).map((item: any, index: number) => {
                        if (index < removeItemIndex2) {
                            return item
                        }
                        else if (index >= removeItemIndex2) {
                            item.sequenceNumber = item.sequenceNumber - 1
                            return item
                        }
                    }))
                }

                if (removeItemIndex2 === afterSigningStages.length - 1) {
                    //@ts-ignore
                    setAfterSigningStages(afterSigningStages.filter((_: any, index: number) => index !== removeItemIndex2))
                }
                break;
            default:
                break;
        }

    }

    useEffect(() => {
        setAfterSigningStages((prev: any) => prev.map((item: any, number: number) => ({...item, sequenceNumber: hiringProcedureStages.length + number + 1})))
    }, [hiringProcedureStages]);

    useEffect(() => {
        if (error) {
            messageApi.open({
                type: 'error',
                content: error,
            });
        }

        if (success) {
            messageApi.open({
                type: 'success',
                content: success,
            });
        }
    }, [error, success])

    useEffect(() => {
        dispatch(getTemplateById({
            workspaceId: currentWorkspace?.id,
            id: id
        }))

        dispatch(getStages({workspaceId: currentWorkspace?.id}))
    }, [])

    useEffect(() => {
        mainForm.setFieldsValue({
            name: currentTemplate?.name,
            description: currentTemplate?.description
        })

        if (currentTemplate && stages?.items) {

            const hiring: any[] = []
            const finish: any[] = []

            currentTemplate?.recruitmentProcessTemplateStages?.map((item: any) => {

                if (stages?.items?.filter((stage: any) => stage?.id === item.stageId && stage?.blockStageType === 1).length > 0) {
                    //@ts-ignore
                    hiring.push({
                        ...item,
                        id: item?.stageId,
                        name: stages?.items?.filter((stage: any) => stage?.id === item.stageId)[0].title,
                        blockStageType: 1
                    })
                }

                else if (stages?.items?.filter((stage: any) => stage?.id === item.stageId && stage?.blockStageType === 2).length > 0) {
                    //@ts-ignore
                    finish.push({
                        ...item,
                        id: item?.stageId,
                        name: stages?.items?.filter((stage: any) => stage?.id === item.stageId)[0].title,
                        blockStageType: 2
                    })
                }
            })

            //@ts-ignore
            setHiringProcedureStages(hiring)
            //@ts-ignore
            setAfterSigningStages(finish)
        }
    }, [currentTemplate, stages])

    // @ts-ignore
    return (
        <>
            {contextHolder}
            <Spin spinning={isLoading} wrapperClassName={styles.spinnerWrapper}>
                <div className={styles.container}>
                    <div className={styles.titleInnerContainer}>
                        <h3>Редактирование шаблона</h3>
                    </div>

                    <div>
                        <Form
                            layout='vertical'
                            form={mainForm}
                            autoComplete='off'
                            requiredMark='optional'
                            onFinishFailed={onFinishFailed}
                            onFinish={(values) => {
                                if (hiringProcedureStages.filter((item: any) => item.isWorkTaskPositionCloser).length > 0) {
                                    dispatch(updateTemplate({
                                        ...values,
                                        workspaceId: currentWorkspace?.id,
                                        templateStages: [...hiringProcedureStages, ...afterSigningStages],
                                        recruitingProcessTemplateId: id
                                    }))
                                        .then(() => dispatch(getTemplateById({
                                            workspaceId: currentWorkspace?.id,
                                            id: id
                                        })))
                                        .then(() => navigate(-1))
                                }
                                else messageApi.info('Должен быть выбран этап, который закрывает позицию')

                            }}
                            className={styles.filterForm}
                        >
                            <div className={styles.descriptionContainer}>
                                <span className={styles.columnTitle}>Основная информация</span>

                                <div className={styles.innerFormContainer}>
                                    <Form.Item
                                        label={<span className='inputLabel'>Название шаблона</span>}
                                        name="name"
                                        validateTrigger={validationMode.name}
                                        rules={[{required: true, message: 'Введите название шаблона'}]}
                                    >
                                        <Input placeholder='Менеджер по продажам' maxLength={50} onBlur={onBlur('name')}/>
                                    </Form.Item>

                                    <Form.Item
                                        label={<span className='inputLabel'>Описание шаблона</span>}
                                        name="description"
                                        validateTrigger={validationMode.description}
                                        rules={[{required: true, message: 'Введите описание шаблона'}]}
                                    >
                                        {/*@ts-ignore*/}
                                        <Input.TextArea placeholder='' autoSize={{minRows: 5, maxRows: 9}} showCount maxLength={500}
                                                        onBlur={onBlur('description')}/>
                                    </Form.Item>

                                    <Form.Item>
                                        <Button
                                            loading={isLoading}
                                            disabled={!hiringProcedureStages.length}
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            Изменить шаблон
                                        </Button>
                                    </Form.Item>
                                </div>
                            </div>

                            <div className={styles.vacancyContainer}>
                                <span className={styles.columnTitle}>Изменение этапов</span>

                                <div>
                                    {!!hiringProcedureStages.length &&
                                        <span className='inputLabel' style={{color: "#AFB1B6"}}>Процедура найма</span>}

                                    <DndContext
                                        modifiers={[restrictToVerticalAxis]}
                                        sensors={sensors}
                                        collisionDetection={closestCenter}
                                        onDragEnd={handleDragEndHiring}
                                    >
                                        <SortableContext
                                            items={hiringProcedureStages}
                                            strategy={verticalListSortingStrategy}
                                        >
                                            {
                                                hiringProcedureStages.map((item: any) =>
                                                    <SortableItem
                                                        key={item?.id} id={item?.id} data={item}
                                                        isCheckboxes
                                                        handleChecked={handleChecked}
                                                        removeItem={removeItem}
                                                    />
                                                )
                                            }
                                        </SortableContext>
                                    </DndContext>

                                    <div style={{marginTop: "12px"}}>
                                        {!!afterSigningStages.length &&
                                            <span className='inputLabel'
                                                  style={{color: "#AFB1B6"}}>После подписания</span>}
                                        <DndContext
                                            modifiers={[restrictToVerticalAxis]}
                                            sensors={sensors}
                                            collisionDetection={closestCenter}
                                            onDragEnd={handleDragEndFinish}
                                        >
                                            <SortableContext
                                                items={afterSigningStages}
                                                strategy={verticalListSortingStrategy}
                                            >
                                                {
                                                    afterSigningStages.map((item: any) =>
                                                        <SortableItem
                                                            key={item?.id} id={item?.id} data={item}
                                                            isCheckboxes={false}
                                                            removeItem={removeItem}
                                                        />
                                                    )
                                                }
                                            </SortableContext>
                                        </DndContext>
                                    </div>

                                </div>

                                <div style={{cursor: "pointer"}} onClick={() => setIsAddNewStageModalOpen(true)}>
                                    <div className={styles.titleContainer}
                                         style={!!hiringProcedureStages.length || !!afterSigningStages.length ? {padding: "0 0 0 26px"} : {padding: "0"}}>
                                        <div className={styles.ordinalNumber} style={{fontSize: "24px"}}>+</div>
                                        <div className={styles.titleInnerContainer}>
                                            <span className={styles.title}>Добавить этап</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>

                </div>
            </Spin>


            <Modal
                title='Добавить этап'
                open={isAddNewStageModalOpen}
                okText="Добавить"
                cancelText="Отмена"

                onOk={() => {
                    addForm.submit()
                }}
                onCancel={() => {
                    addForm.resetFields()
                    setIsAddNewStageModalOpen(false)
                }}
            >
                <Form
                    form={addForm}
                    layout='vertical'
                    autoComplete='off'
                    requiredMark='optional'
                    onFinishFailed={onFinishFailed}
                    onFinish={(values) => {
                        addForm.resetFields()
                        //@ts-ignore
                        setIsAddNewStageModalOpen(false)

                        //@ts-ignore
                        setHiringProcedureStages((prev: any) => [...prev, ...values.stagesList.filter((item: any) => item.title === '1').map((item: any, number: number) => ({
                            id: item.key,
                            stageId: item.key,
                            name: item.label.props.children,
                            sequenceNumber: prev.length + 1 + number,
                            isWorkTaskPositionCloser: false,
                            blockStageType: 1
                        }))])
                        //@ts-ignore
                        setAfterSigningStages((prev: any) => [...prev, ...values.stagesList.filter((item: any) => item.title === '2').map((item: any, number: number) => ({
                            id: item.key,
                            stageId: item.key,
                            name: item.label.props.children,
                            sequenceNumber: prev.length + 1 + number,
                            isWorkTaskPositionCloser: false,
                            blockStageType: 2
                        }))])
                    }}
                >
                    <Form.Item
                        label={<span className='inputLabel'>Этапы шаблона</span>}
                        name="stagesList"
                        validateTrigger={validationMode.templateStages}
                        rules={[{required: true, message: 'Выберите этапы'}]}
                    >

                        <Select mode="multiple" labelInValue onBlur={onBlur('stagesList')} options={
                            [
                                {
                                    label: <span>Процедура найма</span>,
                                    //@ts-ignore
                                    options: stages?.items?.filter((item: any) => item.blockStageType === 1 && !hiringProcedureStages.find(el => el.id === item?.id)).map((item: any) => (
                                        {
                                            label: <span>{item?.title}</span>,
                                            title: '1',
                                            value: item?.id
                                        }
                                    ))
                                },
                                {
                                    label: <span>После подписания</span>,
                                    //@ts-ignore
                                    options: stages?.items?.filter((item: any) => item.blockStageType === 2 && !afterSigningStages.find(el => el.id === item?.id)).map((item: any) => (
                                        {
                                            label: <span>{item?.title}</span>,
                                            title: '2',
                                            value: item?.id
                                        }
                                    ))
                                }
                            ]
                        }/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default EditFunnelPage
