// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WorkspaceAfterAuthPage_container__XTfAZ {
  display: flex;
  justify-content: center;
  align-items: center;
}
.WorkspaceAfterAuthPage_container__XTfAZ .WorkspaceAfterAuthPage_logo__iUW0Q {
  margin-bottom: 32px;
}
.WorkspaceAfterAuthPage_container__XTfAZ h1 {
  margin-bottom: 42px;
  font-weight: 600;
}
.WorkspaceAfterAuthPage_container__XTfAZ .WorkspaceAfterAuthPage_containerInner__oh2\\+R {
  width: 600px;
}

.WorkspaceAfterAuthPage_modal__9bgk8 button {
  display: inline-flex;
  align-items: center;
}
.WorkspaceAfterAuthPage_modal__9bgk8 .WorkspaceAfterAuthPage_info__hyxXj {
  display: flex;
  align-items: flex-start;
  gap: 6px;
  margin-top: 8px;
}
.WorkspaceAfterAuthPage_modal__9bgk8 .WorkspaceAfterAuthPage_info__hyxXj img {
  margin-top: 2px;
}
.WorkspaceAfterAuthPage_modal__9bgk8 .WorkspaceAfterAuthPage_info__hyxXj span {
  font-size: 12px;
  color: #AFB1B6;
}

@media screen and (max-width: 1300px) {
  .WorkspaceAfterAuthPage_containerInner__oh2\\+R {
    width: 500px !important;
  }
}
@media screen and (max-width: 1100px) {
  .WorkspaceAfterAuthPage_containerInner__oh2\\+R {
    width: 450px !important;
  }
}
@media screen and (max-width: 1000px) {
  .WorkspaceAfterAuthPage_containerInner__oh2\\+R {
    width: 400px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/WorkspaceAfterAuthPage.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;AAEE;EACE,mBAAA;AAAJ;AAGE;EACE,mBAAA;EACA,gBAAA;AADJ;AAKE;EACE,YAAA;AAHJ;;AAUE;EACE,oBAAA;EACA,mBAAA;AAPJ;AAUE;EACE,aAAA;EACA,uBAAA;EACA,QAAA;EACA,eAAA;AARJ;AAUI;EACE,eAAA;AARN;AAWI;EACE,eAAA;EACA,cAAA;AATN;;AAcA;EACE;IACE,uBAAA;EAXF;AACF;AAcA;EACE;IACE,uBAAA;EAZF;AACF;AAgBA;EACE;IACE,uBAAA;EAdF;AACF","sourcesContent":[".container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n\n  .logo {\n    margin-bottom: 32px;\n  }\n\n  h1 {\n    margin-bottom: 42px;\n    font-weight: 600;\n  }\n\n\n  .containerInner {\n    width: 600px;\n  }\n\n}\n\n.modal {\n\n  button {\n    display: inline-flex;\n    align-items: center;\n  }\n\n  .info {\n    display: flex;\n    align-items: flex-start;\n    gap: 6px;\n    margin-top: 8px;\n\n    img {\n      margin-top: 2px;\n    }\n\n    span {\n      font-size: 12px;\n      color: #AFB1B6;\n    }\n  }\n}\n\n@media screen and (max-width: 1300px) {\n  .containerInner {\n    width: 500px!important;\n  }\n}\n\n@media screen and (max-width: 1100px) {\n  .containerInner {\n    width: 450px!important;\n  }\n}\n\n\n@media screen and (max-width: 1000px) {\n  .containerInner {\n    width: 400px!important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `WorkspaceAfterAuthPage_container__XTfAZ`,
	"logo": `WorkspaceAfterAuthPage_logo__iUW0Q`,
	"containerInner": `WorkspaceAfterAuthPage_containerInner__oh2+R`,
	"modal": `WorkspaceAfterAuthPage_modal__9bgk8`,
	"info": `WorkspaceAfterAuthPage_info__hyxXj`
};
export default ___CSS_LOADER_EXPORT___;
