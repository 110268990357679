import React, {useEffect} from 'react'
import styles from '../../styles/Tasks/TaskMainPanel.module.scss'
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {getTaskById} from "../../redux/actions/TasksActions";
import {formatDate} from "../../helpers";

const TaskMainPanel = (data: any) => {

    const {currentWorkspace} = useAppSelector(state => state.workspacesReducer)
    const {currentTask} = useAppSelector(state => state.tasksReducer)
    const dispatch = useAppDispatch()

    useEffect(() => {
        //@ts-ignore
        dispatch(getTaskById({workspaceId: currentWorkspace?.id, id: data?.workTaskId}))
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.filterForm}>
                <div className={styles.descriptionContainer}>
                    <span className={styles.columnTitle}>Основная информация</span>

                    <div className={styles.innerFormContainer}>
                        <div className={styles.item}>
                            <span className={styles.label}>Название задания</span>
                            <span>{currentTask?.name}</span>
                        </div>

                        <div className={styles.item}>
                            <span className={styles.label}>Текст задания</span>
                            <span>{currentTask?.description}</span>
                        </div>

                        <div className={styles.formRow}>
                            <div className={styles.item}>
                                <span className={styles.label}>Выполнить до</span>
                                <span>{formatDate(currentTask?.deadlineAtUtc)}</span>
                            </div>

                            {
                                currentTask?.vacancyHireQuantity ?
                                    <div className={styles.item}>
                                        <span className={styles.label}>Количество кандидатов</span>
                                        <span>{currentTask?.vacancyHireQuantity}</span>
                                    </div> : null
                            }
                        </div>

                        <div className={styles.item}>
                            <span className={styles.label}>Ответственный</span>
                            <span>{currentTask?.responsibleUserInfo?.lastName} {currentTask?.responsibleUserInfo?.firstName}</span>
                        </div>
                    </div>
                </div>

                <div className={styles.vacancyContainer}>
                    <span className={styles.columnTitle}>Дополнительно</span>


                    <div className={styles.item}>
                        <span className={styles.label}>Вакансия</span>
                        <span>–</span>
                    </div>

                    {/*<div className={styles.innerFormContainer}>*/}
                    {/*    <div className={styles.formRow}>*/}

                    {/*        <div className={styles.item}>*/}
                    {/*            <span className={styles.label}>Пол</span>*/}
                    {/*            <span>–</span>*/}
                    {/*        </div>*/}

                    {/*        <div className={styles.item}>*/}
                    {/*            <span className={styles.label}>Высшее образование</span>*/}
                    {/*            <span>–</span>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*    <div className={styles.formRow}>*/}
                    {/*        <div className={styles.item}>*/}
                    {/*            <span className={styles.label}>Возраст от</span>*/}
                    {/*            <span>–</span>*/}
                    {/*        </div>*/}

                    {/*        <div className={styles.item}>*/}
                    {/*            <span className={styles.label}>Возраст до</span>*/}
                    {/*            <span>–</span>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*    <div className={styles.item}>*/}
                    {/*        <span className={styles.label}>Квалификация</span>*/}
                    {/*        <span>–</span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    )
}

export default TaskMainPanel
