// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CreateVacancy_container__3dURK {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 64px 32px;
  max-width: 1312px;
  width: 100%;
}
.CreateVacancy_container__3dURK input {
  height: 38px;
}

.CreateVacancy_titleInnerContainer__o5EP3 h3 {
  font-size: 24px;
  font-weight: 600;
}

.CreateVacancy_filterForm__AbvMZ {
  display: flex;
  flex-direction: row;
  gap: 80px;
}

.CreateVacancy_descriptionContainer__6sesL {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.CreateVacancy_vacancyContainer__a3C09 {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.CreateVacancy_vacancyContainer__a3C09 div {
  margin-bottom: 0;
}

.CreateVacancy_columnTitle__-SF3n {
  font-size: 18px;
  font-weight: 600;
}

.CreateVacancy_innerFormContainer__b41ID {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.CreateVacancy_innerFormContainer__b41ID div {
  margin-bottom: 0;
}

.CreateVacancy_formRow__k8s9Q {
  display: flex;
  gap: 20px;
}
.CreateVacancy_formRow__k8s9Q div {
  width: 100%;
  margin-bottom: 0;
}

.CreateVacancy_spinnerWrapper__Uz0zW {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/styles/Vacancies/CreateVacancy.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,kBAAA;EACA,iBAAA;EACA,WAAA;AACF;AACE;EACE,YAAA;AACJ;;AAIE;EACE,eAAA;EACA,gBAAA;AADJ;;AAKA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAFF;;AAKA;EACE,UAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AAFF;;AAKA;EACE,UAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AAFF;AAIE;EACE,gBAAA;AAFJ;;AAMA;EACE,eAAA;EACA,gBAAA;AAHF;;AAMA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAHF;AAKE;EACE,gBAAA;AAHJ;;AAOA;EACE,aAAA;EACA,SAAA;AAJF;AAME;EACE,WAAA;EACA,gBAAA;AAJJ;;AAQA;EACE,WAAA;AALF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 32px;\n  padding: 64px 32px;\n  max-width: 1312px;\n  width: 100%;\n\n  input {\n    height: 38px;\n  }\n}\n\n.titleInnerContainer {\n  h3 {\n    font-size: 24px;\n    font-weight: 600;\n  }\n}\n\n.filterForm {\n  display: flex;\n  flex-direction: row;\n  gap: 80px;\n}\n\n.descriptionContainer {\n  width: 40%;\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n\n.vacancyContainer {\n  width: 60%;\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n\n  & div {\n    margin-bottom: 0;\n  }\n}\n\n.columnTitle {\n  font-size: 18px;\n  font-weight: 600;\n}\n\n.innerFormContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n\n  div {\n    margin-bottom: 0;\n  }\n}\n\n.formRow {\n  display: flex;\n  gap: 20px;\n\n  div {\n    width: 100%;\n    margin-bottom: 0;\n  }\n}\n\n.spinnerWrapper {\n  width: 100%\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CreateVacancy_container__3dURK`,
	"titleInnerContainer": `CreateVacancy_titleInnerContainer__o5EP3`,
	"filterForm": `CreateVacancy_filterForm__AbvMZ`,
	"descriptionContainer": `CreateVacancy_descriptionContainer__6sesL`,
	"vacancyContainer": `CreateVacancy_vacancyContainer__a3C09`,
	"columnTitle": `CreateVacancy_columnTitle__-SF3n`,
	"innerFormContainer": `CreateVacancy_innerFormContainer__b41ID`,
	"formRow": `CreateVacancy_formRow__k8s9Q`,
	"spinnerWrapper": `CreateVacancy_spinnerWrapper__Uz0zW`
};
export default ___CSS_LOADER_EXPORT___;
