import React, {useEffect, useState} from 'react'
import styles from '../../../styles/Settings/Departments/DepartmentsMain.module.scss'
import {
    Button,
    Dropdown,
    Empty,
    Form,
    Input,
    MenuProps,
    message,
    Modal,
    Pagination,
    Segmented,
    Select,
    Spin,
    Tag
} from "antd";
import {DeleteOutlined, DownOutlined, EditOutlined, PlusOutlined, RetweetOutlined} from "@ant-design/icons";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getAllCompanies} from "../../../redux/actions/CompaniesActions";
import {
    createDepartment,
    deleteDepartment,
    getAllDepartments,
    getArchivedDepartments,
    restoreDepartment,
    updateDepartment
} from "../../../redux/actions/DepartmentsActions";
import {useForm} from "antd/es/form/Form";
import {useImprovedUXValidation} from "../../../hooks/useImprovedUXValidation";

const DepartmentsMain = () => {

    const {onFinishFailed, validationMode, onBlur} = useImprovedUXValidation(['name', 'description', 'companyId'])
    const dispatch = useAppDispatch()
    const [createForm] = useForm()
    const [editForm] = useForm()
    const [messageApi, contextHolder] = message.useMessage();

    const {currentWorkspace} = useAppSelector(state => state.workspacesReducer)
    const {departments, isLoading, error, success} = useAppSelector(state => state.departmentsReducer)
    const {
        companies,
        isLoading: companiesIsLoading,
        error: companiesError
    } = useAppSelector(state => state.companiesReducer)

    const [selectedCompany, setSelectedCompany] = useState(null)
    const [currentTab, setCurrentTab] = useState(0)
    const [isAddNewModalOpen, setIsAddNewModalOpen] = useState(false)
    const [isEditModalOpen, setIsEditModalOpen] = useState<any>({open: false, department: null})
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<any>({open: false, department: null})
    const [isRestoreModalOpen, setIsRestoreModalOpen] = useState<any>({open: false, department: null})


    const departmentsActions: MenuProps['items'] = [
        {
            label: <span className={styles.actionItem}><EditOutlined/>Редактировать</span>,
            key: '1',
        },
        {
            label: <span className={styles.actionItem}><DeleteOutlined/>Удалить</span>,
            key: '2',
        }
    ];

    const departmentsArchiveActions: MenuProps['items'] = [
        {
            label: <span className={styles.actionItem}><RetweetOutlined/>Восстановить</span>,
            key: '3',
        },
    ];

    const handleActions = (key: any, data?: any) => {
        switch (key) {
            case '1':
                setIsEditModalOpen({open: true, department: data})
                break;
            case '2':
                setIsDeleteModalOpen({open: true, department: data})
                break;
            case '3':
                setIsRestoreModalOpen({open: true, department: data})
                break;
            default:
                break;
        }
    }

    const onTabChange = (value: any) => {
        setCurrentTab(value)

        switch (value) {
            case 0:
                dispatch(getAllDepartments({
                    workspaceId: currentWorkspace?.id,
                    companyId: selectedCompany,
                    pageNumber: 1
                }))
                break;
            case 1:
                dispatch(getArchivedDepartments({
                    workspaceId: currentWorkspace?.id,
                    companyId: selectedCompany,
                    pageNumber: 1
                }))
                break;
            default:
                break;
        }
    }

    const onPageNumberChange = (value: any) => {
        if (currentTab === 0) {
            dispatch(getAllDepartments({
                workspaceId: currentWorkspace?.id,
                companyId: selectedCompany,
                pageNumber: value
            }))
        } else {
            dispatch(getArchivedDepartments({
                workspaceId: currentWorkspace?.id,
                companyId: selectedCompany,
                pageNumber: value
            }))
        }
    }

    useEffect(() => {
        dispatch(getAllCompanies({pageNumber: 1, workspaceId: currentWorkspace?.id}))
    }, [])

    useEffect(() => {
        if (companies?.items?.length > 0) {
            setSelectedCompany(companies?.items[0]?.id)
        }
    }, [companies])

    useEffect(() => {
        if (selectedCompany && !isAddNewModalOpen) {
            dispatch(getAllDepartments({
                workspaceId: currentWorkspace?.id,
                companyId: selectedCompany,
                pageNumber: 1
            }))
        }
    }, [selectedCompany]);

    useEffect(() => {
        if (isAddNewModalOpen) {
            createForm.setFieldsValue({
                companyId: selectedCompany
            })
        }
    }, [isAddNewModalOpen]);

    useEffect(() => {
        if (isEditModalOpen?.open) {
            editForm.setFieldsValue({
                name: isEditModalOpen?.department?.name,
                description: isEditModalOpen?.department?.description,
                companyId: selectedCompany
            })
        }
    }, [isEditModalOpen?.open])

    useEffect(() => {
        if (error) {
            messageApi.open({
                type: 'error',
                content: error,
            });
        }

        if (companiesError) {
            messageApi.open({
                type: 'error',
                content: error,
            });
        }

        if (success) {
            messageApi.open({
                type: 'success',
                content: success,
            });
        }
    }, [error, success, companiesError])

    return (
        <>
            {contextHolder}
            <Spin spinning={isLoading} wrapperClassName={styles.spinnerWrapper}>
                <div className={styles.container}>
                    <div className={styles.listContainer}>
                        <div className={styles.pageTitleWrapper}>
                            <span className={styles.listHeader}>Отделы</span>
                            <Button type='primary' icon={<PlusOutlined/>}
                                    onClick={() => setIsAddNewModalOpen(true)}>Добавить
                                отдел</Button>
                        </div>

                        <div className={styles.companySelectContainer}>
                            <span>Компания</span>
                            <Select
                                variant='borderless'
                                className={styles.companySelect}
                                showSearch
                                allowClear
                                optionFilterProp="label"
                                options={companies?.items?.map((item: any) => ({
                                    value: item?.id,
                                    label: item?.name
                                }))}
                                value={selectedCompany}
                                onChange={(value) => {
                                    setSelectedCompany(value)
                                }}
                                filterOption={(input, option) =>
                                    //@ts-ignore
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            />
                        </div>

                        {
                            selectedCompany &&
                            <Segmented
                                className={styles.listTabs}
                                onChange={(value) => onTabChange(value)}
                                value={currentTab}
                                options={[
                                    {
                                        label: 'Текущие',
                                        value: 0
                                    },
                                    {
                                        label: 'Архив',
                                        value: 1
                                    }
                                ]}
                            />
                        }

                        <div className={styles.innerListContainer}>
                            {
                                departments?.items?.length > 0 ?
                                    <>
                                        {departments?.items?.map((item: any) => (
                                            <div className={styles.listItem} key={item?.id}>
                                                <div className={styles.listItemHeading}>
                                                    <div className={styles.listItemTitleContainer}>
                                                        <div>{item?.name}</div>
                                                        {item?.archivedBy &&
                                                            <Tag bordered={false} color="orange">В архиве</Tag>}
                                                    </div>

                                                    <div className={styles.listItemActionsContainer}>
                                                        <div className={styles.listItemActions}>
                                                            {/*@ts-ignore*/}
                                                            <Dropdown menu={{
                                                                items: item?.archivedBy ? departmentsArchiveActions : departmentsActions,
                                                                onClick: ({key}) => {
                                                                    handleActions(key, item)
                                                                }
                                                            }}>
                                                                <a onClick={(e) => {
                                                                    e.preventDefault()
                                                                    e.stopPropagation()

                                                                }}>
                                                                    Действия
                                                                    <DownOutlined/>
                                                                </a>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={styles.listItemBody}>
                                                    <div className={styles.listItemInfoContainer}>
                                                        <div className={styles.listItemInfoItem}>
                                                            <span>{item?.description}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                        }
                                        {
                                            departments?.totalCount > 8 && <Pagination
                                                onChange={onPageNumberChange}
                                                defaultPageSize={8}
                                                current={departments?.pageNumber}
                                                total={departments?.totalCount}
                                            />
                                        }
                                    </>
                                    :
                                    selectedCompany ?
                                        <div>
                                            <Empty description='Отделы отсутствуют'/>
                                        </div> :
                                        <div>
                                            <Empty description='Выберите компанию'/>
                                        </div>
                            }
                        </div>
                    </div>
                </div>
            </Spin>


            <Modal
                title='Добавить отдел'
                open={isAddNewModalOpen}
                okText="Добавить"
                cancelText="Отмена"

                onOk={() => {
                    createForm.submit()
                }}
                onCancel={() => setIsAddNewModalOpen(false)}
            >
                <Form
                    form={createForm}
                    layout='vertical'
                    autoComplete='off'
                    requiredMark='optional'
                    onFinishFailed={onFinishFailed}
                    onFinish={(values) => {
                        dispatch(createDepartment({
                            ...values,
                            workspaceId: currentWorkspace?.id
                        }))
                            .then(() => dispatch(getAllDepartments({
                                workspaceId: currentWorkspace?.id,
                                companyId: values?.companyId,
                                pageNumber: 1
                            })))
                            .then(() => createForm.resetFields())
                            .then(() => setSelectedCompany(values?.companyId))
                        setIsAddNewModalOpen(false)
                    }}
                >
                    <Form.Item
                        label={<span className='inputLabel'>Название отдела</span>}
                        name="name"
                        validateTrigger={validationMode.name}
                        rules={[{required: true, message: 'Введите название отдела'}]}
                    >
                        <Input placeholder='Отдел маркетинга' onBlur={onBlur('name')}/>
                    </Form.Item>

                    <Form.Item
                        label={<span className='inputLabel'>Описание отдела</span>}
                        name="description"
                    >
                        <Input.TextArea placeholder='' autoSize={{minRows: 4, maxRows: 8}} showCount maxLength={200}/>
                    </Form.Item>

                    <Form.Item
                        label={<span className='inputLabel'>Компания</span>}
                        name="companyId"
                        validateTrigger={validationMode.name}
                        rules={[{required: true, message: 'Выберите компанию'}]}
                    >
                        <Select
                            showSearch
                            allowClear
                            optionFilterProp="label"
                            className={styles.companySelect}
                            options={companies?.items?.map((item: any) => ({
                                value: item?.id,
                                label: item?.name
                            }))}
                            filterOption={(input, option) =>
                                //@ts-ignore
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                        />
                    </Form.Item>
                </Form>
            </Modal>


            <Modal
                title='Редактировать отдел'
                open={isEditModalOpen?.open}
                okText="Изменить"
                cancelText="Отмена"

                onOk={() => {
                    editForm.submit()
                }}
                onCancel={() => setIsEditModalOpen({open: false, department: null})}
            >
                <Form
                    form={editForm}
                    layout='vertical'
                    autoComplete='off'
                    requiredMark='optional'
                    onFinishFailed={onFinishFailed}
                    onFinish={(values) => {
                        dispatch(updateDepartment({
                            ...values,
                            companyId: selectedCompany,
                            workspaceId: currentWorkspace?.id,
                            departmentId: isEditModalOpen?.department?.id
                        }))
                            .then(() => dispatch(getAllDepartments({
                                workspaceId: currentWorkspace?.id,
                                companyId: selectedCompany,
                                pageNumber: 1
                            })))
                            .then(() => createForm.resetFields())
                        setIsEditModalOpen({open: false, department: null})
                    }}
                >
                    <Form.Item
                        label={<span className='inputLabel'>Название отдела</span>}
                        name="name"
                        validateTrigger={validationMode.name}
                        rules={[{required: true, message: 'Введите название отдела'}]}
                    >
                        <Input placeholder='Отдел маркетинга' onBlur={onBlur('name')}/>
                    </Form.Item>

                    <Form.Item
                        label={<span className='inputLabel'>Описание отдела</span>}
                        name="description"
                    >
                        <Input.TextArea placeholder='' autoSize={{minRows: 4, maxRows: 8}} showCount maxLength={200}/>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title='Подтверждение операции'
                open={isDeleteModalOpen.open}
                okText="Да"
                cancelText="Отмена"

                onOk={() => {
                    dispatch(deleteDepartment({
                        companyId: selectedCompany,
                        workspaceId: currentWorkspace?.id,
                        departmentId: isDeleteModalOpen?.department?.id
                    }))
                        .then(() => dispatch(getAllDepartments({
                            workspaceId: currentWorkspace?.id,
                            companyId: selectedCompany,
                            pageNumber: 1
                        })))
                    setIsDeleteModalOpen({open: false, department: null})

                }}
                onCancel={() => setIsDeleteModalOpen(() => ({open: false, department: null}))}
            >
                Вы уверены, что хотите удалить отдел?
                <br/>
                Отдел будет перенесён в архив
            </Modal>

            <Modal
                title='Подтверждение операции'
                open={isRestoreModalOpen.open}
                okText="Да"
                cancelText="Отмена"

                onOk={() => {
                    dispatch(restoreDepartment({
                        workspaceId: currentWorkspace?.id,
                        departmentId: isRestoreModalOpen?.department?.id
                    }))
                        .then(() => dispatch(getAllDepartments({
                            workspaceId: currentWorkspace?.id,
                            companyId: selectedCompany,
                            pageNumber: 1
                        })))
                        .then(() => setCurrentTab(0))
                    setIsRestoreModalOpen({open: false, department: null})

                }}
                onCancel={() => setIsRestoreModalOpen(() => ({open: false, department: null}))}
            >
                Вы уверены, что хотите восстановить отдел?
            </Modal>
        </>
    )
}

export default DepartmentsMain
