import React from "react";
import { useRouteError } from "react-router-dom";
import styles from '../styles/ErrorPage.module.css'
import {RouteError} from "../Interfaces";


const ErrorPage = () => {
    const error = useRouteError() as RouteError;

    return (
        <div id="error-page" className={styles.container}>
            <h1>Ой!</h1>
            <p>Извините, этой страницы еще не существует.</p>
            <p>
                <i>{error.statusText || error.message}</i>
            </p>
        </div>
    );
}

export default ErrorPage;
