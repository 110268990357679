// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SettingsLayout_container__DBjMN {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 64px 32px;
  max-width: 1612px;
  width: 100%;
}
.SettingsLayout_container__DBjMN .SettingsLayout_titleContainer__9I9VK {
  display: flex;
  gap: 8px;
  align-items: center;
}
.SettingsLayout_container__DBjMN .SettingsLayout_titleContainer__9I9VK h3 {
  font-size: 24px;
  font-weight: 600;
}
.SettingsLayout_container__DBjMN .SettingsLayout_innerContainer__qHJcK {
  display: flex;
  gap: 40px;
}`, "",{"version":3,"sources":["webpack://./src/styles/SettingsLayout.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,kBAAA;EACA,iBAAA;EACA,WAAA;AACF;AACE;EACE,aAAA;EACA,QAAA;EACA,mBAAA;AACJ;AACI;EACE,eAAA;EACA,gBAAA;AACN;AAGE;EACE,aAAA;EACA,SAAA;AADJ","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 32px;\n  padding: 64px 32px;\n  max-width: 1612px;\n  width: 100%;\n\n  .titleContainer {\n    display: flex;\n    gap: 8px;\n    align-items: center;\n\n    h3 {\n      font-size: 24px;\n      font-weight: 600;\n    }\n  }\n\n  .innerContainer {\n    display: flex;\n    gap: 40px;\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SettingsLayout_container__DBjMN`,
	"titleContainer": `SettingsLayout_titleContainer__9I9VK`,
	"innerContainer": `SettingsLayout_innerContainer__qHJcK`
};
export default ___CSS_LOADER_EXPORT___;
