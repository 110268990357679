import React, {useState} from 'react'
import styles from "../../styles/WorkspaceSearch.module.scss"
import {ConfigProvider, Select, SelectProps} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import {useAppSelector} from "../../hooks/redux";
import api from "../../helpers/api";

interface handleJoinRequestModal {
    handleJoinRequestModal: (open: boolean, workspaceId: string) => void
}

let timeout: ReturnType<typeof setTimeout> | null;
let currentValue: string;

const fetch = (value: string, callback: Function, token: string, handleJoinRequestModal: Function) => {
    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }
    currentValue = value;

    const search = () => {
        api.get(`${process.env.REACT_APP_API_URI_HTTPS}/api/workspaces/search?SearchTerm=${value}&PageNumber=1&PageSize=100`)
            .then((response) => {
                if (response.data.value.items.length !== 0) {
                    const data = response.data.value.items.map((el: any) => ({
                        text: <div className={styles.itemContainer} onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                        }}>
                            <div className={styles.itemInnerContainer}>
                                {/*<img src="/icon_sm_1.svg" alt="icon_sm_1"/>*/}
                                <span>{el.name}</span>
                            </div>

                            <button onClick={(e) => {
                                e.stopPropagation()
                                e.preventDefault()
                                handleJoinRequestModal(true, el.id)
                            }
                            }>Запрос на вступление
                            </button>
                        </div>,
                        value: el.id
                    }))

                    callback(data)
                } else callback([])
            })
    };
    if (value) {
        timeout = setTimeout(search, 200);
    } else {
        callback([]);
    }
};

// @ts-ignore
const SearchInput: React.FC<{ placeholder: string; handleJoinRequestModal: Function }> = (props) => {
    const [data, setData] = useState<SelectProps['options']>([]);
    const [value, setValue] = useState<string>();
    const {user} = useAppSelector(state => state.authReducer)

    const handleSearch = (newValue: string) => {
        fetch(newValue, setData, user?.access_token, props.handleJoinRequestModal);
    };

    const handleChange = (newValue: string) => {
        setValue(newValue);
    };

    return (
        <ConfigProvider
            theme={{
                token: {
                    fontFamily: "Montserrat, sans-serif",
                    colorText: "#222222",
                },
                components: {
                    Select: {
                        controlHeight: 48,
                        borderRadius: 12,
                        fontSize: 16
                    }
                }
            }}
        >
            <Select
                showSearch
                value={value}
                placeholder={props.placeholder}
                defaultActiveFirstOption={false}
                suffixIcon={<SearchOutlined width={24} height={24} style={{color: "#AFB1B6", marginInlineEnd: "8px"}}/>}
                filterOption={false}
                onSearch={handleSearch}
                onChange={handleChange}
                notFoundContent={null}
                options={(data || []).map((d) => ({
                    value: d.value,
                    label: d.text,
                }))}
            />
        </ConfigProvider>
    );
}


const WorkspaceSearch = ({handleJoinRequestModal}: handleJoinRequestModal) => {
    return (
        <>
            <div className={styles.container}>
                <h2>Поиск рабочих пространств</h2>
                <SearchInput placeholder="Введите название рабочего пространства"
                             handleJoinRequestModal={handleJoinRequestModal}/>
            </div>
        </>
    )
}

export default WorkspaceSearch;
