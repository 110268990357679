import React, {useEffect, useState} from 'react'
import styles from '../../../styles/Tasks/TasksMain.module.scss'
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getParticipants, getRequests, processRequest, revokeRequest} from "../../../redux/actions/ParticipantsActions";
import {Dropdown, Empty, MenuProps, message, Modal, Pagination, Spin} from "antd";
import {formatDate, getTaskStatus} from "../../../helpers";
import {CloseOutlined, DownOutlined, PlusOutlined} from "@ant-design/icons";

const RequestsList = () => {

    const dispatch = useAppDispatch()
    const [messageApi, contextHolder] = message.useMessage();
    const {currentWorkspace} = useAppSelector(state => state.workspacesReducer)
    const {requests, error, success, isLoading} = useAppSelector(state => state.participantsReducer)
    const [isApproveRequestModalOpen, setIsApproveRequestModalOpen] = useState({open: false, id: null})
    const [isDenyRequestModalOpen, setIsDenyRequestModalOpen] = useState({open: false, id: null})

    const requestsActions: MenuProps['items'] = [
        {
            label: <span className={styles.actionItem}><PlusOutlined/>Принять</span>,
            key: '1',
        },
        {
            label: <span className={styles.actionItem}><CloseOutlined/>Отклонить</span>,
            key: '2',
        },
    ];

    const handleActions = (key: any, id: any) => {
        switch (key) {
            case '1':
                setIsApproveRequestModalOpen({open: true, id: id})
                break;
            case '2':
                setIsDenyRequestModalOpen({open: true, id: id})
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        dispatch(getRequests({workspaceId: currentWorkspace?.id}))
    }, []);

    useEffect(() => {
        if (error) {
            messageApi.open({
                type: 'error',
                content: error,
            });
        }

        if (success) {
            messageApi.open({
                type: 'success',
                content: success,
            });
        }
    }, [error, success])

    return (
        <>
            {contextHolder}
            <Spin spinning={isLoading} wrapperClassName={styles.tasksListSpinner}>
                <div className={styles.listContainer}>
                    <div className={styles.listInnerContainer}>
                        {requests?.items?.filter((item: any) => item.processedStatus === null).length !== 0 ?
                            <>
                                {requests?.items?.map((item: any) => {
                                    if (item.processedStatus === null) {
                                        const {id, comment, processedStatus, createdAtUtc, processedBy}: any = item
                                        return (
                                            <div className={styles.listItem} key={id}>
                                                <div className={styles.listItemHeading}>
                                                    <div className={styles.listItemTitleContainer}>
                                                        {processedBy?.user?.fullName?.last ?
                                                            <div>{processedBy?.user?.fullName?.last && '–'} {processedBy?.user?.first && '–'} {processedBy?.user?.middle && '–'}</div> :
                                                            <div>{id}</div>
                                                        }
                                                        {getTaskStatus(processedStatus)}
                                                    </div>

                                                    <div className={styles.listItemActionsContainer}>
                                                        <div className={styles.listItemDate}>
                                                            Создано: {formatDate(createdAtUtc)}
                                                        </div>

                                                        <div className={styles.listItemActions}>
                                                            {/*@ts-ignore*/}
                                                            <Dropdown menu={{
                                                                items: requestsActions, onClick: ({key}) => {
                                                                    handleActions(key, id)
                                                                }
                                                            }}>
                                                                <a onClick={(e) => {
                                                                    e.preventDefault()
                                                                    e.stopPropagation()

                                                                }}>
                                                                    Действия
                                                                    <DownOutlined/>
                                                                </a>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={styles.listItemBody}>
                                                    <div className={styles.listItemInfoContainer}>

                                                        {processedBy?.user?.phone?.number &&
                                                            <div className={styles.listItemInfoItem}>
                                                                <img src="/phone_icon.svg" alt="Phone"/>
                                                                <span>{processedBy?.user?.phone?.number}</span>
                                                            </div>
                                                        }

                                                        {processedBy?.user?.emailAddress?.address &&
                                                            <div className={styles.listItemInfoItem}>
                                                                <img src="/mail_icon.svg" alt="Mail"/>
                                                                <span>{processedBy?.user?.emailAddress?.address}</span>
                                                            </div>
                                                        }


                                                        {comment &&
                                                            <div className={styles.listItemInfoItem}>
                                                                <img src="/vacancy_icon.svg" alt="Comment"/>
                                                                <span>{comment}</span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                                }
                                <Pagination
                                    defaultPageSize={8}
                                    pageSizeOptions={[8, 16, 40, 80]}
                                    showSizeChanger
                                    // onChange={onPageNumberChange}
                                    // onShowSizeChange={onShowSizeChange}
                                    defaultCurrent={1}
                                    // current={currentPage}
                                    total={requests?.totalCount}/>
                            </>
                            :
                            <div>
                                <Empty description='Запросы отсутствуют'/>
                            </div>
                        }
                    </div>
                </div>
            </Spin>

            <Modal
                title='Подтверждение операции'
                open={isApproveRequestModalOpen.open}
                okText="Да"
                cancelText="Отмена"

                onOk={() => {
                    dispatch(processRequest({
                        requestId: isApproveRequestModalOpen.id,
                        processedStatus: 0,
                        roleName: 'admin'
                    }))
                        .then(() => dispatch(getRequests({workspaceId: currentWorkspace?.id})))
                        .then(() => dispatch(getParticipants({workspaceId: currentWorkspace?.id, pageNumber: 1})))
                        .then(() => setIsApproveRequestModalOpen(() => ({open: false, id: null})))
                }}
                onCancel={() => setIsApproveRequestModalOpen(() => ({open: false, id: null}))}
            >
                Вы уверены, что хотите принять запрос участника на добавление в рабочее пространство?
            </Modal>

            <Modal
                title='Подтверждение операции'
                open={isDenyRequestModalOpen.open}
                okText="Да"
                cancelText="Отмена"

                onOk={() => {
                    dispatch(revokeRequest({
                        requestId: isDenyRequestModalOpen.id
                    }))
                        .then(() => dispatch(getRequests({workspaceId: currentWorkspace?.id})))
                        .then(() => setIsDenyRequestModalOpen(() => ({open: false, id: null})))
                }}
                onCancel={() => setIsDenyRequestModalOpen(() => ({open: false, id: null}))}
            >
                Вы уверены, что хотите отклонить запрос участника на добавление в рабочее пространство?
            </Modal>
        </>
    )
}

export default RequestsList
