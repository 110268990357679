import React, {useEffect, useState} from 'react'
import styles from "../../../styles/Settings/FunnelTemplates/FunnelTemplatesPage.module.scss"
import {Button, Checkbox, message, Modal, Spin} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getTemplateById, getTemplates, removeTemplate} from "../../../redux/actions/TemplatesActions";
import {getStages} from "../../../redux/actions/StagesActions";
import {DeleteOutlined} from "@ant-design/icons";


const FunnelTemplatesPage = () => {

    const {id} = useParams<{ id: string }>()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [messageApi, contextHolder] = message.useMessage();
    const [isDeleteFunnelModalOpen, setIsDeleteFunnelModalOpen] = useState(false)

    const {currentWorkspace} = useAppSelector(state => state.workspacesReducer)
    const {stages} = useAppSelector(state => state.stagesReducer)
    const {currentTemplate, isLoading, error, success} = useAppSelector(state => state.templatesReducer)


    useEffect(() => {
        dispatch(getTemplateById({workspaceId: currentWorkspace?.id, id: id}))
        dispatch(getStages({workspaceId: currentWorkspace?.id}))
    }, [])

    useEffect(() => {
        if (error) {
            messageApi.open({
                type: 'error',
                content: error,
            });
        }

        if (success) {
            messageApi.open({
                type: 'success',
                content: success,
            });
        }
    }, [error, success])


    return (
        <>
            {contextHolder}
            <Spin spinning={isLoading} wrapperClassName={styles.spinnerWrapper}>
                <div className={styles.container}>
                    <div className={styles.titleInnerContainer}>
                        <h3>{currentTemplate?.name}</h3>

                        <div className={styles.actionsContainer}>
                            <Button icon={<img src="/edit_icon.svg" alt="edit"/>}
                                    onClick={() => navigate(`/settings/templates/edit/${currentTemplate?.id}`)}>Редактировать</Button>
                            <Button icon={<DeleteOutlined/>}
                                    onClick={() => setIsDeleteFunnelModalOpen(true)}>Удалить</Button>
                        </div>
                    </div>

                    <div>
                        <div className={styles.filterForm}>
                            <div className={styles.descriptionContainer}>
                                <span className={styles.columnTitle}>Основная информация</span>

                                <div className={styles.innerFormContainer}>
                                    <div className={styles.item}>
                                        <span className={styles.label}>Название шаблона</span>
                                        <span>{currentTemplate?.name}</span>
                                    </div>

                                    <div className={styles.item}>
                                        <span className={styles.label}>Описание шаблона</span>
                                        <span>{currentTemplate?.description}</span>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.vacancyContainer}>
                                <span className={styles.columnTitle}>Этапы шаблона</span>


                                <div>
                                    <span className='inputLabel' style={{color: "#AFB1B6"}}>Процедура найма</span>
                                    {

                                        currentTemplate?.recruitmentProcessTemplateStages?.map((item: any) => {
                                                if (stages?.items?.filter((stage: any) => stage?.id === item.stageId && stage?.blockStageType === 1).length > 0) {
                                                    return (
                                                        <div className={styles.listItem} key={item?.stageId}>
                                                            <div className={styles.itemTitleContainer}>
                                                                <div className={styles.itemTitleInnerContainer}>
                                                                    <div
                                                                        className={styles.ordinalNumber}>{item?.sequenceNumber}</div>
                                                                    <span className={styles.title}>
                                                                        {
                                                                            stages?.items?.filter((stage: any) => stage.id === item?.stageId)[0]?.title
                                                                        }
                                                                    </span>
                                                                </div>

                                                                <div className={styles.itemActions}>
                                                                    <Checkbox disabled
                                                                              checked={item?.isWorkTaskPositionCloser}>Закрывает
                                                                        позицию</Checkbox>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            }
                                        )
                                    }

                                    <span className='inputLabel' style={{color: "#AFB1B6"}}>После подписания</span>
                                    {

                                        currentTemplate?.recruitmentProcessTemplateStages?.map((item: any) => {
                                                if (stages?.items?.filter((stage: any) => stage?.id === item.stageId && stage?.blockStageType === 2).length > 0) {
                                                    return (
                                                        <div className={styles.listItem} key={item?.stageId}>
                                                            <div className={styles.itemTitleContainer}>
                                                                <div className={styles.itemTitleInnerContainer}>
                                                                    <div
                                                                        className={styles.ordinalNumber}>{item?.sequenceNumber}</div>
                                                                    <span className={styles.title}>
                                                                        {
                                                                            stages?.items?.filter((stage: any) => stage.id === item?.stageId)[0]?.title
                                                                        }
                                                                    </span>
                                                                </div>

                                                                <div className={styles.itemActions}>
                                                                    <Checkbox disabled
                                                                              checked={item?.isWorkTaskPositionCloser}>Закрывает
                                                                        позицию</Checkbox>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            }
                                        )
                                    }
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </Spin>

            <Modal
                title='Подтверждение операции'
                open={isDeleteFunnelModalOpen}
                okText="Да"
                cancelText="Отмена"

                onOk={() => {
                    dispatch(removeTemplate({
                        workspaceId: currentWorkspace?.id,
                        recruitingProcessTemplateId: currentTemplate?.id
                    }))
                        .then(() => dispatch(getTemplates({workspaceId: currentWorkspace?.id})))
                        .then(() => setIsDeleteFunnelModalOpen(false))
                        .then(() => navigate('/settings/templates'))
                }}
                onCancel={() => setIsDeleteFunnelModalOpen(false)}
            >
                Вы уверены, что хотите удалить шаблон?
            </Modal>
        </>
    )
}

export default FunnelTemplatesPage
