import React, {useEffect, useState} from 'react'
import styles from '../../../styles/Settings/Groups/GroupsPage.module.scss'
import {useNavigate, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {Button, Input, message, Modal, Select, Spin} from "antd";
import {
    addParticipants,
    changeSenior,
    deleteGroup,
    getGroupById,
    removeParticipants,
    renameGroup
} from "../../../redux/actions/GroupsActions";
import {getGroupStatus} from "../../../helpers";
import {IParticipant} from "../../models/IParticipant";
import {getParticipants} from "../../../redux/actions/ParticipantsActions";
import {CloseOutlined, DeleteOutlined, PlusOutlined, SaveOutlined} from '@ant-design/icons';


const GroupsPage = () => {

    const {id} = useParams<{ id: string }>()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [messageApi, contextHolder] = message.useMessage();

    const {currentGroup, error, success, isLoading} = useAppSelector(state => state.groupsReducer)
    const {currentWorkspace} = useAppSelector(state => state.workspacesReducer)
    const {participants} = useAppSelector(state => state.participantsReducer)

    const [nameInputValue, setNameInputValue] = useState('')
    const [isNameEdit, setIsNameEdit] = useState(false)
    const [seniorSelectValue, setSeniorSelectValue] = useState('')
    const [isSeniorEdit, setIsSeniorEdit] = useState(false)
    const [isAddNewParticipant, setIsAddNewParticipant] = useState(false)
    const [newParticipant, setNewParticipant] = useState(null)

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

    useEffect(() => {
        if (currentWorkspace?.id) {
            //@ts-ignore
            dispatch(getParticipants({workspaceId: currentWorkspace?.id, pageNumber: 1}))
            dispatch(getGroupById(id))
                .then(() => setNameInputValue(() => (currentGroup?.name)))
                .then(() => setSeniorSelectValue(() => currentGroup?.senior?.user?.id))
        }
    }, []);

    useEffect(() => {
        if (error) {
            messageApi.open({
                type: 'error',
                content: error,
            });
        }

        if (success) {
            messageApi.open({
                type: 'success',
                content: success,
            });
        }
    }, [error, success])

    // @ts-ignore
    // @ts-ignore
    return (
        <>
            {contextHolder}
            <Spin spinning={isLoading} wrapperClassName={styles.spinnerWrapper}>
                <div className={styles.container}>
                    <div className={styles.titleContainer}>
                        <div className={styles.titleInnerContainer}>
                            <img src="/group_icon.svg" alt="Иконка группы"/>
                            <h3>{currentGroup?.name}</h3>
                            {getGroupStatus(currentGroup?.isArchived)}
                        </div>

                        {!currentGroup?.isArchived &&
                            <div className={styles.actionsContainer}>
                                <Button icon={<DeleteOutlined/>}
                                        onClick={() => setIsDeleteModalOpen(true)}
                                >Удалить</Button>
                            </div>
                        }
                    </div>

                    <div>
                        <div className={styles.filterForm}>
                            <div className={styles.descriptionContainer}>
                                <span className={styles.columnTitle}>Основная информация</span>

                                <div className={styles.innerFormContainer}>
                                    <div className={styles.item}>
                                            <span className={styles.label}>
                                                Название группы
                                                {!currentGroup?.isArchived && !isNameEdit &&
                                                    <img src="/edit_icon.svg" alt="edit" className={styles.editIcon}
                                                         onClick={() => setIsNameEdit(true)}/>
                                                }
                                                {isNameEdit ?
                                                    <>
                                                        <SaveOutlined style={{fontSize: "18px", color: "#4365E1"}}
                                                                      onClick={() => {
                                                                          dispatch(renameGroup({
                                                                              groupId: id,
                                                                              workspaceId: currentWorkspace?.id,
                                                                              name: nameInputValue
                                                                          }))
                                                                              .then(() => dispatch(getGroupById(id)))
                                                                              .then(() => setIsNameEdit(false))
                                                                      }}/>
                                                        <CloseOutlined style={{fontSize: "18px", color: "red"}}
                                                                       onClick={() => {
                                                                           setIsNameEdit(false)
                                                                       }}/>
                                                    </> :
                                                    null
                                                }
                                            </span>
                                        {
                                            isNameEdit ?
                                                <Input value={nameInputValue}
                                                       defaultValue={currentGroup?.name}
                                                       style={{maxWidth: "350px"}}
                                                    //@ts-ignore
                                                       onChange={(e) => setNameInputValue(e.target.value)}

                                                />
                                                :
                                                <div>
                                                    <span>{currentGroup?.name}</span>
                                                </div>
                                        }
                                    </div>

                                    <div className={styles.item}>
                                            <span className={styles.label}>
                                                Руководитель
                                                {!currentGroup?.isArchived && !isSeniorEdit &&
                                                    <img src="/edit_icon.svg" alt="edit" className={styles.editIcon}
                                                         onClick={() => setIsSeniorEdit(true)}/>
                                                }
                                                {
                                                    isSeniorEdit ?
                                                        <>
                                                            <SaveOutlined style={{fontSize: "18px", color: "#4365E1"}}
                                                                          onClick={() => {
                                                                              dispatch(changeSenior({
                                                                                  groupId: id,
                                                                                  workspaceId: currentWorkspace?.id,
                                                                                  seniorId: seniorSelectValue
                                                                              }))
                                                                                  .then(() => dispatch(getGroupById(id)))
                                                                                  .then(() => setIsSeniorEdit(false))
                                                                          }}
                                                            />
                                                            <CloseOutlined style={{fontSize: "18px", color: "red"}}
                                                                           onClick={() => {
                                                                               setIsSeniorEdit(false)
                                                                           }}
                                                            />
                                                        </>
                                                        :
                                                        null
                                                }
                                            </span>
                                        {
                                            isSeniorEdit ?
                                                <>
                                                    <Select
                                                        style={{maxWidth: "350px"}}
                                                        defaultValue={currentGroup?.senior?.userId}
                                                        onChange={(value) => setSeniorSelectValue(value)}
                                                        options={participants?.items?.map((item: IParticipant) => (
                                                            {
                                                                value: item.userId,
                                                                label:
                                                                    <span>{`${item?.user?.fullName?.last} ${item?.user?.fullName?.first}`}</span>
                                                            }
                                                        ))}/>
                                                </> :
                                                <div>
                                                    <span> {currentGroup?.senior?.user?.fullName?.last} {currentGroup?.senior?.user?.fullName?.first}</span>
                                                </div>
                                        }
                                    </div>

                                    <div className={styles.item}>
                                            <span className={styles.label}>
                                                Участники
                                                {!currentGroup?.isArchived &&
                                                    <PlusOutlined
                                                        onClick={() => setIsAddNewParticipant(prev => !prev)}/>
                                                }
                                            </span>
                                        {
                                            currentGroup?.participants?.map((item: any) => (
                                                <div className={styles.participantItem}>
                                                    <span>{item?.user?.fullName?.last} {item?.user?.fullName?.first}</span>
                                                    {!currentGroup?.isArchived &&
                                                        <CloseOutlined onClick={() => {
                                                            dispatch(removeParticipants({
                                                                groupId: id,
                                                                workspaceId: currentWorkspace?.id,
                                                                participants: [
                                                                    item?.userId
                                                                ]
                                                            }))
                                                                .then(() => dispatch(getGroupById(id)))
                                                        }}/>
                                                    }
                                                </div>
                                            ))
                                        }
                                        {
                                            isAddNewParticipant &&
                                            <div className={styles.participantItem}>
                                                <Select
                                                    style={{maxWidth: "350px", width: "350px"}}
                                                    onChange={(value) => setNewParticipant(value)}
                                                    options={participants?.items?.map((item: IParticipant) => (
                                                        {
                                                            value: item.userId,
                                                            label:
                                                                <span>{`${item?.user?.fullName?.last} ${item?.user?.fullName?.first}`}</span>
                                                        }
                                                    ))}/>

                                                <SaveOutlined style={{fontSize: "18px", color: "#4365E1"}}
                                                              onClick={() => {
                                                                  dispatch(addParticipants({
                                                                      groupId: id,
                                                                      workspaceId: currentWorkspace?.id,
                                                                      participants: [
                                                                          newParticipant
                                                                      ]
                                                                  }))
                                                                      .then(() => dispatch(getGroupById(id)))
                                                                      .then(() => setIsAddNewParticipant(false))
                                                              }}
                                                />
                                                <CloseOutlined style={{fontSize: "18px", color: "red"}}
                                                               onClick={() => {
                                                                   setIsAddNewParticipant(false)
                                                               }}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Spin>


            <Modal
                title='Подтверждение операции'
                open={isDeleteModalOpen}
                okText="Да"
                cancelText="Отмена"

                onOk={() => {
                    dispatch(deleteGroup({
                        workspaceId: currentWorkspace?.id,
                        groupId: id,
                    }))
                        .then(() => setIsDeleteModalOpen(false))
                        .then(() => navigate(-1))
                }}
                onCancel={() => setIsDeleteModalOpen(false)}
            >
                Вы уверены, что хотите удалить группу?
            </Modal>
        </>
    )
}


export default GroupsPage
