import {createSlice} from "@reduxjs/toolkit";

interface GroupsSlice {
    groups: any;
    currentGroup: any;
    isLoading: boolean;
    error: any;
    success: any;
}

const initialState: GroupsSlice = {
    groups: [],
    currentGroup: null,
    isLoading: false,
    error: null,
    success: null,
}

export const groupsSlice = createSlice({
    name: "groups",
    initialState,
    reducers: {
        isLoading: (state) => {
            state.isLoading = true
            state.error = null
            state.success = null
            state.groups = null
        },
        isFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload
        },
        isSuccess: (state, action) => {
            state.isLoading = false
            state.success = action.payload
        },
        setGroups: (state, action) => {
            state.isLoading = false
            state.groups = action.payload
        },
        setCurrentGroup: (state, action) => {
            state.isLoading = false
            state.currentGroup = action.payload
        }
    }
})

export default groupsSlice.reducer
