import {AppDispatch} from "../index";
import {getErrorMessage} from "../../helpers";
import {candidatesSlice} from "../reducers/candidatesSlice";
import {ICandidate} from "../../Components/models/ICandidate";
import api from "../../helpers/api";

export const createCandidate = (data: ICandidate) => async (dispatch: AppDispatch) => {
    try {
        dispatch(candidatesSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/candidates/create`, data)

        if (response.data.succeeded) {
            dispatch(candidatesSlice.actions.isSuccess("Кандидат успешно добавлен"))
        } else {
            dispatch(candidatesSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(candidatesSlice.actions.isFailure(message))
    }
}

export const updateCandidate = (data: ICandidate) => async (dispatch: AppDispatch) => {
    try {
        dispatch(candidatesSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/candidates/update`, data)

        if (response.data.succeeded) {
            dispatch(candidatesSlice.actions.isSuccess("Кандидат успешно добавлен"))
        } else {
            dispatch(candidatesSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(candidatesSlice.actions.isFailure(message))
    }
}

export const getAllCandidates = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(candidatesSlice.actions.isLoading())

        const response = await api.get(`${process.env.REACT_APP_API_URI_HTTPS}/api/candidates`,
            {
                // @ts-ignore
                params: new URLSearchParams(data)
            })

        if (response.data.succeeded) {
            dispatch(candidatesSlice.actions.setCandidates(response.data.value))
        } else {
            dispatch(candidatesSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(candidatesSlice.actions.isFailure(message))
    }
}

export const getCandidateById = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(candidatesSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/candidates/${data?.id}?workspaceId=${data?.workspaceId}`, {WorkspaceId: data?.workspaceId})

        if (response.data.succeeded) {
            dispatch(candidatesSlice.actions.setCandidateDetails(response.data.value))
        } else {
            dispatch(candidatesSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(candidatesSlice.actions.isFailure(message))
    }
}

export const getCandidatesSearch = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(candidatesSlice.actions.isLoading())

        const response = await api.get(`${process.env.REACT_APP_API_URI_HTTPS}/api/candidates/search`, data)

        if (response.data.succeeded) {
            dispatch(candidatesSlice.actions.setCandidates(response.data.value))
        } else {
            dispatch(candidatesSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(candidatesSlice.actions.isFailure(message))
    }
}
