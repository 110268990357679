import {IWorkspace} from "../../Components/models/IWorkspace";
import {IWorkspaceJoinRequest} from "../../Components/models/IWorkspaceJoinRequest";
import {createSlice} from "@reduxjs/toolkit";

interface WorkpacesSlice {
    workspaces: IWorkspace[];
    joinRequests: IWorkspaceJoinRequest[];
    currentWorkspace: any;
    isLoading: boolean;
    error: any;
    success: any;
}

const initialState: WorkpacesSlice = {
    workspaces: [],
    joinRequests: [],
    //@ts-ignore
    currentWorkspace: JSON.parse(localStorage.getItem("currentWorkspace") || null),
    isLoading: false,
    error: null,
    success: null
}

export const workspacesSlice = createSlice({
    name: "workspaces",
    initialState,
    reducers: {
        isLoading: (state) => {
            state.isLoading = true
            state.error = null
        },
        isFailure: (state, action) => {
            state.isLoading = false
            state.error = action.payload
        },
        isSuccess: (state, action) => {
            state.isLoading = false
            state.success = action.payload
        },
        myWorkspacesSuccess: (state, action) => {
            state.isLoading = false
            state.workspaces = action.payload
        },
        getJoinRequestsSuccess: (state, action) => {
            state.isLoading = false
            state.joinRequests = action.payload
        },
        setCurrentWorkspace: (state, action) => {
            state.isLoading = false;
            state.currentWorkspace = action.payload;
            localStorage.setItem("currentWorkspace", JSON.stringify(action.payload))

        },
        removeCurrentWorkspace: (state) => {
            localStorage.removeItem("currentWorkspace")
            state.currentWorkspace = null
        }
    }
})

export default workspacesSlice.reducer
