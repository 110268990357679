import {AppDispatch} from "../index";
import {candidatesSlice} from "../reducers/candidatesSlice";
import {getErrorMessage} from "../../helpers";
import {companiesSlice} from "../reducers/companiesSlice";
import api from "../../helpers/api";

export const getAllCompanies = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(companiesSlice.actions.isLoading())

        const response = await api.get(`${process.env.REACT_APP_API_URI_HTTPS}/api/Companies?WorkspaceId=${data.workspaceId}&PageNumber=${data.pageNumber}&PageSize=100`)

        if (response.data.succeeded) {
            dispatch(companiesSlice.actions.setCompanies(response.data.value))
        } else {
            dispatch(companiesSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(candidatesSlice.actions.isFailure(message))
    }
}

export const getAllCompaniesWithPagination = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(companiesSlice.actions.isLoading())

        const response = await api.get(`${process.env.REACT_APP_API_URI_HTTPS}/api/Companies?WorkspaceId=${data.workspaceId}&PageNumber=${data.pageNumber}&PageSize=8`)

        if (response.data.succeeded) {
            dispatch(companiesSlice.actions.setCompanies(response.data.value))
        } else {
            dispatch(companiesSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(candidatesSlice.actions.isFailure(message))
    }
}

export const createCompany = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(companiesSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/Companies/create`, data)

        if (response.data.succeeded) {
            dispatch(companiesSlice.actions.isSuccess("Компания успешно добавлена"))
        } else {
            dispatch(companiesSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(candidatesSlice.actions.isFailure(message))
    }
}

export const updateCompany = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(companiesSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/Companies/update`, data)

        if (response.data.succeeded) {
            dispatch(companiesSlice.actions.isSuccess("Компания успешно изменена"))
        } else {
            dispatch(companiesSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(candidatesSlice.actions.isFailure(message))
    }
}

export const archiveCompany = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(companiesSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/Companies/archive`, data)

        if (response.data.succeeded) {
            dispatch(companiesSlice.actions.isSuccess("Компания успешно архивирована"))
        } else {
            dispatch(companiesSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(candidatesSlice.actions.isFailure(message))
    }
}
