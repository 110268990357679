import React, {useEffect, useState} from 'react'
import styles from '../../styles/Tasks/TaskPage.module.scss'
import {useNavigate, useParams} from "react-router-dom";
import {Button, message, Modal, Spin, Tabs} from "antd";
import TaskMainPanel from "./TaskMainPanel";
import TaskCandidatesPanel from "./TaskCandidatesPanel";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {archiveTask, cancelTask, completeTask, getTaskById} from "../../redux/actions/TasksActions";
import {getTaskStatus} from "../../helpers";
import {CheckOutlined, CloseOutlined, EditOutlined} from "@ant-design/icons";
import {removeRecruitment} from "../../redux/actions/RecruitmentActions";

const TaskPage = () => {

    const {id} = useParams<{ id: string }>()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [messageApi, contextHolder] = message.useMessage();
    const [isArchiveModalOpen, setIsArchiveModalOpen] = useState({open: false, id: null})
    const [isCompleteModalOpen, setIsCompleteModalOpen] = useState({open: false, id: null})
    const [isCancelModalOpen, setIsCancelModalOpen] = useState({open: false, id: null})

    const [currentTab, setCurrentTab] = useState('1')

    const {currentTask, error, success, isLoading} = useAppSelector(state => state.tasksReducer)
    const {currentWorkspace} = useAppSelector(state => state.workspacesReducer)

    useEffect(() => {
        if (currentWorkspace?.id) {
            //@ts-ignore
            dispatch(getTaskById({workspaceId: currentWorkspace.id, id: id}))
        }
    }, []);

    useEffect(() => {
        if (error) {
            messageApi.open({
                type: 'error',
                content: error,
            });
        }

        if (success) {
            messageApi.open({
                type: 'success',
                content: success,
            });
        }
    }, [error, success])

    return (
        <>
            {contextHolder}
            <Spin spinning={isLoading} wrapperClassName={styles.tasksListSpinner}>
                <div className={styles.container}>
                    <div className={styles.titleContainer}>
                        <div className={styles.titleInnerContainer}>
                            <img src="/tasks_icon.svg" alt="Иконка задачи"/>
                            <h3>{currentTask?.name}</h3>
                            {getTaskStatus(currentTask?.status)}
                        </div>

                        {
                            currentTab === '1' ?
                                currentTask?.status === 1 &&
                                <div className={styles.actionsContainer}>
                                    <Button icon={<EditOutlined/>}
                                            onClick={() => navigate(`/tasks/edit/${id}`)}>Редактировать</Button>
                                    <Button icon={<img src="/archive_icon.svg" alt="edit"/>}
                                            onClick={() => setIsArchiveModalOpen(() => ({
                                                open: true,
                                                id: currentTask?.id
                                            }))}>Архивировать</Button>
                                    <Button icon={<CloseOutlined/>}
                                            onClick={() => setIsCancelModalOpen(() => ({
                                                open: true,
                                                id: currentTask?.id
                                            }))}>Отменить</Button>
                                    <Button icon={<CheckOutlined/>} onClick={() => setIsCompleteModalOpen(() => ({
                                        open: true,
                                        id: currentTask?.id
                                    }))}>Завершить</Button>
                                </div> : null

                        }

                    </div>

                    <Tabs defaultActiveKey="1" onChange={(key) => setCurrentTab(key)} items={[
                        {
                            key: '1',
                            label: 'Сводная информация',
                            //@ts-ignore
                            children: <TaskMainPanel workTaskId={id}/>,
                        },
                        {
                            key: '2',
                            label: 'Рекрутинг',
                            children: <TaskCandidatesPanel workTaskId={id}/>
                        }
                    ]}/>
                </div>
            </Spin>

            <Modal
                title='Подтверждение операции'
                open={isArchiveModalOpen.open}
                okText="Да"
                cancelText="Отмена"

                onOk={() => {
                    dispatch(archiveTask({
                        workspaceId: currentWorkspace?.id,
                        workTaskId: id
                    }))
                        .then(() => setIsArchiveModalOpen(() => ({open: false, id: null})))
                        .then(() => navigate(-1))
                }}
                onCancel={() => setIsArchiveModalOpen(() => ({open: false, id: null}))}
            >
                Вы уверены, что хотите архивировать задание?
            </Modal>

            <Modal
                title='Подтверждение операции'
                open={isCompleteModalOpen.open}
                okText="Да"
                cancelText="Отмена"

                onOk={() => {
                    dispatch(completeTask({
                        workspaceId: currentWorkspace?.id,
                        workTaskId: id
                    }))
                        .then(() => setIsCompleteModalOpen(() => ({open: false, id: null})))
                        .then(() => navigate(-1))
                }}
                onCancel={() => setIsCompleteModalOpen(() => ({open: false, id: null}))}
            >
                Вы уверены, что хотите завершить задание?
            </Modal>

            <Modal
                title='Подтверждение операции'
                open={isCancelModalOpen.open}
                okText="Да"
                cancelText="Отмена"

                onOk={() => {
                    dispatch(cancelTask({
                        workspaceId: currentWorkspace?.id,
                        workTaskId: id
                    }))
                        .then(() => setIsCancelModalOpen(() => ({open: false, id: null})))
                        .then(() => navigate(-1))
                }}
                onCancel={() => setIsCancelModalOpen(() => ({open: false, id: null}))}
            >
                Вы уверены, что хотите отменить задание?
            </Modal>
        </>
    )
}

export default TaskPage
