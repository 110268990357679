import React, {useEffect, useState} from 'react'
import styles from '../../styles/Tasks/TasksMain.module.scss'
import {Button, Dropdown, Empty, MenuProps, message, Modal, Pagination, PaginationProps, Segmented, Spin} from "antd";
import {
    AppstoreOutlined,
    BarsOutlined,
    CheckOutlined,
    CloseOutlined,
    DownOutlined,
    EditOutlined,
    FilterOutlined,
    PlusOutlined
} from "@ant-design/icons";
import {Link, useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {archiveTask, completeTask, getAllTasks, getFilteredTasks} from "../../redux/actions/TasksActions";
import {formatDate, getTaskStatus, isEmpty} from "../../helpers";
import {getParticipants} from "../../redux/actions/ParticipantsActions";
import TasksFilter from "./TasksFilter";
import {ITask} from "../models/ITask";

const TasksMain = () => {

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [showFilter, setShowFilter] = useState(true)
    const [messageApi, contextHolder] = message.useMessage();

    const [filterData, setFilterData] = useState({})
    const [currentPage, setCurrentPage] = useState(1)
    const [currentPageSize, setCurrentPageSize] = useState(8)
    const [currentTab, setCurrentTab] = useState(0)
    const [isArchiveModalOpen, setIsArchiveModalOpen] = useState({open: false, id: null})
    const [isCompleteModalOpen, setIsCompleteModalOpen] = useState({open: false, id: null})
    const [isCancelModalOpen, setIsCancelModalOpen] = useState({open: false, id: null})

    const {currentWorkspace} = useAppSelector(state => state.workspacesReducer)
    const {tasks, error, success, isLoading} = useAppSelector(state => state.tasksReducer);

    const taskActions: MenuProps['items'] = [
        {
            label: <span className={styles.actionItem}><EditOutlined/>Редактировать</span>,
            key: '1',
        },
        {
            label: <span className={styles.actionItem}><img src="/archive_icon.svg" alt="edit"/>Архивировать</span>,
            key: '2',
        },
        {
            label: <span className={styles.actionItem}><CheckOutlined/>Завершить</span>,
            key: '3',
        },
        {
            label: <span className={styles.actionItem}><CloseOutlined/>Отменить</span>,
            key: '4',
        },
    ];
    const tabsOptions = [
        {label: 'Открытые', value: '0', icon: <BarsOutlined/>},
        {label: 'Неактивные', value: '1', icon: <AppstoreOutlined/>},
    ]

    useEffect(() => {
        getTasks({
            status: 0,
            pageNumber: 1,
            pageSize: 8
        })

        dispatch(getParticipants({workspaceId: currentWorkspace?.id, pageNumber: 1}))
    }, [])

    useEffect(() => {
        if (error) {
            messageApi.open({
                type: 'error',
                content: error,
            });
        }

        if (success) {
            messageApi.open({
                type: 'success',
                content: success,
            });
        }
    }, [error, success])

    useEffect(() => {
        getTasks({
            status: currentTab,
            pageNumber: 1,
            pageSize: 8
        })
        setCurrentPage(1)
        setCurrentPageSize(8)
    }, [filterData])

    const getTasks = (data: any) => {
        if (!isEmpty(filterData)) {
            const params = {
                WorkspaceId: currentWorkspace?.id,
                IsFinished: !!parseInt(data.status),
                PageNumber: data.pageNumber,
                PageSize: data.pageSize,
                ...filterData
            }
            dispatch(getFilteredTasks(params))
        } else {
            const params = {
                workspaceId: currentWorkspace?.id,
                status: parseInt(data.status),
                pageNumber: data.pageNumber,
                pageSize: data.pageSize
            }
            dispatch(getAllTasks(params))
        }
    }

    const onTabChange = (value: string) => {
        setCurrentTab(parseInt(value))

        getTasks({
            status: value,
            pageNumber: 1,
            pageSize: currentPageSize
        })
    }


    // TODO: FIX sizeChange
    const onShowSizeChange: PaginationProps['onShowSizeChange'] = (current, pageSize) => {

        setCurrentPage(1)
        getTasks({
            status: currentTab,
            pageNumber: 1,
            pageSize: pageSize
        })

        setCurrentPageSize(pageSize)

    };

    const onPageNumberChange: PaginationProps['onChange'] = (page) => {
        getTasks({
            status: currentTab,
            pageNumber: page,
            pageSize: currentPageSize
        })

        setCurrentPage(page)
    };

    const handleActions = (key: any, id: any) => {
        switch (key) {
            case '1':
                navigate(`/tasks/edit/${id}`)
                break;
            case '2':
                setIsArchiveModalOpen({open: true, id: id})
                break;
            case '3':
                setIsCompleteModalOpen({open: true, id: id})
                break;
            case '4':
                setIsCancelModalOpen({open: true, id: id})
                break;
            default:
                break;
        }
    }

    const handleFilterChange = (data: any) => {
        setFilterData(() => (data))
    }

    return (
        <>
            {contextHolder}
            <div className={styles.container}>
                <div className={styles.titleContainer}>
                    <div className={styles.titleInnerContainer}>
                        <img src="/tasks_icon.svg" alt="Задания"/>
                        <h3>Задания</h3>
                    </div>

                    <div className={styles.buttonsContainer}>
                        <Button icon={<FilterOutlined/>} onClick={() => setShowFilter(prev => !prev)}/>
                        <Button type='primary' icon={<PlusOutlined/>} onClick={() => navigate('/tasks/create')}>Создать
                            задание</Button>
                    </div>
                </div>

                <div className={styles.innerContainer}>
                    <TasksFilter showFilter={showFilter}
                                 currentPageSize={currentPageSize}
                                 onPageChange={(page: number) => setCurrentPage(page)}
                                 onTabChange={(tab: number) => setCurrentTab(tab)}
                                 filterData={filterData}
                                 onFilterChange={handleFilterChange}
                    />

                    <Spin spinning={isLoading} wrapperClassName={styles.tasksListSpinner}>
                        <div className={styles.listContainer}>
                            <Segmented
                                className={styles.listTabs}
                                onChange={(value) => onTabChange(value)}
                                value={currentTab.toString()}
                                options={tabsOptions}
                            />

                            <div className={styles.listInnerContainer}>
                                {tasks?.items?.length !== 0 ?
                                    <>
                                        {tasks?.items?.map((task: ITask) => {
                                            const {
                                                id,
                                                name,
                                                status,
                                                deadlineAtUtc,
                                                vacancyId,
                                                responsibleUserId,
                                                vacancyInfo
                                            }: ITask = task
                                            return (
                                                <div className={styles.listItem} key={id}>
                                                    <div className={styles.listItemHeading}>
                                                        <div className={styles.listItemTitleContainer}
                                                             onClick={() => navigate(`/tasks/${id}`)}>
                                                            <div>{name}</div>
                                                            {getTaskStatus(status)}
                                                        </div>

                                                        <div className={styles.listItemActionsContainer}>
                                                            <div className={styles.listItemDate}>
                                                                Срок выполнения: {formatDate(deadlineAtUtc)}
                                                            </div>

                                                            {status === 1 &&
                                                                <div className={styles.listItemActions}>
                                                                    {/*@ts-ignore*/}
                                                                    <Dropdown menu={{
                                                                        items: taskActions, onClick: ({key}) => {
                                                                            handleActions(key, id)
                                                                        }
                                                                    }}>
                                                                        <a onClick={(e) => {
                                                                            e.preventDefault()
                                                                            e.stopPropagation()

                                                                        }}>
                                                                            Действия
                                                                            <DownOutlined/>
                                                                        </a>
                                                                    </Dropdown>
                                                                </div>}
                                                        </div>
                                                    </div>

                                                    <div className={styles.listItemBody}>
                                                        <div className={styles.listItemInfoContainer}>
                                                            {vacancyInfo &&
                                                                <div className={styles.listItemInfoItem}>
                                                                    <img src="/vacancy_icon.svg" alt="Vacancy"/>
                                                                    <Link to={`/vacancies/${vacancyInfo?.id}`}>{vacancyInfo?.name}</Link>
                                                                </div>}

                                                            {/*<div className={styles.listItemInfoItem}>*/}
                                                            {/*    <img src="/profile_icon.svg" alt="Vacancy"/>*/}
                                                            {/*    /!*<span>{task?.responsibleUserId}</span>*!/*/}
                                                            {/*    <span>{task?.responsibleUserInfo?.lastName} {task?.responsibleUserInfo?.firstName}</span>*/}
                                                            {/*</div>*/}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                        <Pagination
                                            defaultPageSize={8}
                                            pageSizeOptions={[8, 16, 40, 80]}
                                            showSizeChanger
                                            onChange={onPageNumberChange}
                                            onShowSizeChange={onShowSizeChange}
                                            defaultCurrent={1}
                                            current={currentPage}
                                            total={tasks?.totalCount}/>
                                    </>
                                    :
                                    <div>
                                        <Empty description='Задания отсутствуют'/>
                                    </div>
                                }
                            </div>
                        </div>
                    </Spin>
                </div>
            </div>

            <Modal
                title='Подтверждение операции'
                open={isArchiveModalOpen.open}
                okText="Да"
                cancelText="Отмена"

                onOk={() => {
                    dispatch(archiveTask({
                        workspaceId: currentWorkspace?.id,
                        workTaskId: isArchiveModalOpen.id
                    }))
                        .then(() => {
                            if (!isEmpty(filterData)) {
                                dispatch(getFilteredTasks({
                                    WorkspaceId: currentWorkspace?.id,
                                    IsFinished: false,
                                    PageNumber: 1,
                                    PageSize: currentPageSize,
                                    ...filterData
                                }))
                                setCurrentPage(1)
                            } else {
                                dispatch(getAllTasks({
                                    workspaceId: currentWorkspace?.id,
                                    status: 0,
                                    pageNumber: 1,
                                    pageSize: currentPageSize
                                }))
                                setCurrentPage(1)
                            }

                            setIsArchiveModalOpen(() => ({open: false, id: null}))
                        })
                }}
                onCancel={() => setIsArchiveModalOpen(() => ({open: false, id: null}))}
            >
                Вы уверены, что хотите архивировать задание?
            </Modal>

            <Modal
                title='Подтверждение операции'
                open={isCompleteModalOpen.open}
                okText="Да"
                cancelText="Отмена"

                onOk={() => {
                    dispatch(completeTask({
                        workspaceId: currentWorkspace?.id,
                        workTaskId: isCompleteModalOpen.id
                    }))
                        .then(() => {
                            if (!isEmpty(filterData)) {
                                dispatch(getFilteredTasks({
                                    WorkspaceId: currentWorkspace?.id,
                                    IsFinished: false,
                                    PageNumber: 1,
                                    PageSize: currentPageSize,
                                    ...filterData
                                }))
                                setCurrentPage(1)
                            } else {
                                dispatch(getAllTasks({
                                    workspaceId: currentWorkspace?.id,
                                    status: 0,
                                    pageNumber: 1,
                                    pageSize: currentPageSize
                                }))
                                setCurrentPage(1)
                            }

                            setIsCompleteModalOpen(() => ({open: false, id: null}))
                        })
                }}
                onCancel={() => setIsCompleteModalOpen(() => ({open: false, id: null}))}
            >
                Вы уверены, что хотите завершить задание?
            </Modal>

            <Modal
                title='Подтверждение операции'
                open={isCancelModalOpen.open}
                okText="Да"
                cancelText="Отмена"

                onOk={() => {
                    dispatch(completeTask({
                        workspaceId: currentWorkspace?.id,
                        workTaskId: isCancelModalOpen.id
                    }))
                        .then(() => {
                            if (!isEmpty(filterData)) {
                                dispatch(getFilteredTasks({
                                    WorkspaceId: currentWorkspace?.id,
                                    IsFinished: false,
                                    PageNumber: 1,
                                    PageSize: currentPageSize,
                                    ...filterData
                                }))
                                setCurrentPage(1)
                            } else {
                                dispatch(getAllTasks({
                                    workspaceId: currentWorkspace?.id,
                                    status: 0,
                                    pageNumber: 1,
                                    pageSize: currentPageSize
                                }))
                                setCurrentPage(1)
                            }

                            setIsCancelModalOpen(() => ({open: false, id: null}))
                        })
                }}
                onCancel={() => setIsCancelModalOpen(() => ({open: false, id: null}))}
            >
                Вы уверены, что хотите отменить задание?
            </Modal>

        </>
    )
}

{/*<div className={styles.listItemFunnelContainer}>*/
}
{/*    <span className={styles.funnelTitle}>Воронка 1</span>*/
}

{/*    <div className={styles.funnelStagesContainer}>*/
}
{/*        <div className={styles.funnelStage}>*/
}
{/*            <div className={styles.funnelStageCount}>3</div>*/
}
{/*            <div className={styles.funnelStageName}>Откликнулся</div>*/
}
{/*        </div>*/
}

{/*        <div className={styles.funnelStage}>*/
}
{/*            <div className={styles.funnelStageCount}>1</div>*/
}
{/*            <div className={styles.funnelStageName}>Созвон</div>*/
}
{/*        </div>*/
}

{/*        <div className={styles.funnelStage}>*/
}
{/*            <div className={styles.funnelStageCount}>1</div>*/
}
{/*            <div className={styles.funnelStageName}>Тестовое задание</div>*/
}
{/*        </div>*/
}

{/*        <div className={styles.funnelStage}>*/
}
{/*            <div className={styles.funnelStageCount}>1</div>*/
}
{/*            <div className={styles.funnelStageName}>Собеседование с HR-специалистом*/
}
{/*            </div>*/
}
{/*        </div>*/
}

{/*        <div className={styles.funnelStage}>*/
}
{/*            <div className={styles.funnelStageCount}>1</div>*/
}
{/*            <div className={styles.funnelStageName}>Оффер отправлен</div>*/
}
{/*        </div>*/
}

{/*        <div className={styles.funnelStage}>*/
}
{/*            <div className={styles.funnelStageCount}>–</div>*/
}
{/*            <div className={styles.funnelStageName}>Оформлен</div>*/
}
{/*        </div>*/
}
{/*    </div>*/
}
{/*</div>*/
}

export default TasksMain
