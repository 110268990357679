import {AppDispatch} from "../index";
import {getErrorMessage} from "../../helpers";
import {stagesSlice} from "../reducers/stagesSlice";
import api from "../../helpers/api";

export const getStages = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(stagesSlice.actions.isLoading())

        const response = await api.get(`${process.env.REACT_APP_API_URI_HTTPS}/api/stages?&WorkspaceId=${data.workspaceId}&PageNumber=1&PageSize=100`)

        if (response.data.succeeded) {
            dispatch(stagesSlice.actions.getStages(response.data.value))
        } else {
            dispatch(stagesSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(stagesSlice.actions.isFailure(message))
    }
}

export const getStagesById = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(stagesSlice.actions.isLoading())

        const response = await api.get(`${process.env.REACT_APP_API_URI_HTTPS}/api/stages/details?&WorkspaceId=${data.workspaceId}&StageId=${data.stageId}`)

        if (response.data.succeeded) {
            dispatch(stagesSlice.actions.isLoadingFinish())
            return response.data.value
        } else {
            dispatch(stagesSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(stagesSlice.actions.isFailure(message))
    }
}

export const getMetrics = () => async (dispatch: AppDispatch) => {
    try {
        dispatch(stagesSlice.actions.isLoading())

        const response = await api.get(`${process.env.REACT_APP_API_URI_HTTPS}/api/metrics`)

        if (response.data) {
            dispatch(stagesSlice.actions.getMetrics(response.data))
        } else {
            dispatch(stagesSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(stagesSlice.actions.isFailure(message))
    }
}

export const createStage = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(stagesSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/stages/create`, data)

        if (response.data.succeeded) {
            dispatch(stagesSlice.actions.isSuccess('Этап успешно добавлен'))
        } else {
            dispatch(stagesSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(stagesSlice.actions.isFailure(message))
    }
}

export const updateStage = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(stagesSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/stages/update`, data)

        if (response.data.succeeded) {
            dispatch(stagesSlice.actions.isSuccess('Этап успешно изменён'))
        } else {
            dispatch(stagesSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(stagesSlice.actions.isFailure(message))
    }
}

export const removeStage = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(stagesSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/stages/remove`, data)

        if (response.data.succeeded) {
            dispatch(stagesSlice.actions.isSuccess('Этап успешно удалён'))
        } else {
            dispatch(stagesSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(stagesSlice.actions.isFailure(message))
    }
}

export const createAssessment = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(stagesSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/stages/assessments/create`, data)

        if (response.data.succeeded) {
            dispatch(stagesSlice.actions.isSuccess('Запрос оценки успешно добавлен'))
        } else {
            dispatch(stagesSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(stagesSlice.actions.isFailure(message))
    }
}

export const updateAssessment = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(stagesSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/stages/assessments/update`, data)

        if (response.data.succeeded) {
            dispatch(stagesSlice.actions.isSuccess('Запрос оценки успешно изменён'))
        } else {
            dispatch(stagesSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(stagesSlice.actions.isFailure(message))
    }
}

export const removeAssessment = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(stagesSlice.actions.isLoading())

        const response = await api.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/stages/assessments/remove`, data)

        if (response.data.succeeded) {
            dispatch(stagesSlice.actions.isLoadingFinish())
        } else {
            dispatch(stagesSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error) {
        const message = getErrorMessage(error)
        dispatch(stagesSlice.actions.isFailure(message))
    }
}
