import React, {useState} from 'react'
import styles from '../../../styles/Settings/Members.module.scss'
import {Button, Form, Input, Modal, Tabs} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {useForm} from "antd/es/form/Form";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getInvites, inviteNewUser} from "../../../redux/actions/ParticipantsActions";
import MembersList from "./MembersList";
import RequestsList from "./RequestsList";
import {useImprovedUXValidation} from "../../../hooks/useImprovedUXValidation";
import InvitesList from "./InvitesList";

const Members = () => {

    const {onFinishFailed, validationMode, onBlur} = useImprovedUXValidation(['email'])
    const dispatch = useAppDispatch();
    const [addNewMemberForm] = useForm()

    const [isAddNewMemberOpen, setIsAddNewMemberOpen] = useState(false);
    const {currentWorkspace} = useAppSelector(state => state.workspacesReducer)


    return (
        <>
            <div className={styles.container}>

                <div className={styles.buttonContainer}>
                    <span className={styles.listHeader}>Участники</span>
                    <Button icon={<PlusOutlined/>} onClick={() => setIsAddNewMemberOpen(prev => !prev)} type='primary'>Добавить
                        нового участника</Button>
                </div>

                <Tabs defaultActiveKey="1" items={[
                    {
                        key: '1',
                        label: 'Пользователи',
                        //@ts-ignore
                        children: <MembersList/>,
                    },
                    {
                        key: '2',
                        label: 'Запросы на доступ',
                        children: <RequestsList/>
                    },
                    {
                        key: '3',
                        label: 'Приглашения',
                        children: <InvitesList/>
                    }
                ]}/>
            </div>

            <Modal title="Пригласить участника"
                   className={styles.modal}
                   open={isAddNewMemberOpen}
                   zIndex={1053}
                   closeIcon={false}
                   styles={{
                       header: {
                           marginBottom: '12px'
                       },
                       footer: {
                           display: 'flex',
                           justifyContent: 'space-between'
                       }
                   }}
                   footer={[
                       <Button key="back" onClick={() => setIsAddNewMemberOpen(prev => !prev)}>
                           Отменить
                       </Button>,
                       <Button type="primary" key="ok" onClick={() => {
                           addNewMemberForm.submit()
                       }}>
                           Пригласить
                       </Button>,
                   ]}
                   onCancel={() => setIsAddNewMemberOpen(prev => !prev)}
                   onOk={() => addNewMemberForm.submit()}
            >
                <Form
                    form={addNewMemberForm}
                    requiredMark='optional'
                    layout='vertical'
                    autoComplete='off'
                    onFinishFailed={onFinishFailed}
                    onFinish={(values) => {
                        dispatch(inviteNewUser({...values, workspaceId: currentWorkspace?.id}))
                            .then(() => dispatch(getInvites({workspaceId: currentWorkspace?.id})))
                        addNewMemberForm.resetFields()
                        setIsAddNewMemberOpen(prev => !prev)
                    }}
                    style={{marginBottom: "16px"}}
                >
                    <Form.Item
                        label={<span className='inputLabel'>Почта</span>}
                        name="email"
                        validateTrigger={validationMode.email}
                        rules={[{required: true, type: 'email', message: 'Введите адрес электронной почты'}]}
                    >
                        <Input placeholder="ivanov_ivan@gmail.com" onBlur={onBlur('email')}/>
                    </Form.Item>


                    <Form.Item
                        label={<span className='inputLabel'>Комментарий</span>}
                        name="comment"
                    >
                        <Input.TextArea/>
                    </Form.Item>

                </Form>
            </Modal>
        </>


    )
}

export default Members
