// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProfileMain_spinnerWrapper__7rTun {
  width: 100%;
}

.ProfileMain_wrapper__ltjX3 {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.ProfileMain_container__1A0V0 {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 64px 32px;
  max-width: 1612px;
  width: 100%;
}

.ProfileMain_username__nBR-M {
  font-size: 20px;
  font-weight: 600;
}

.ProfileMain_titleContainer__AeYhh {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ProfileMain_titleContainer__AeYhh .ProfileMain_titleInnerContainer__3xjS7 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
.ProfileMain_titleContainer__AeYhh .ProfileMain_titleInnerContainer__3xjS7 img {
  width: 28px;
  height: 28px;
}
.ProfileMain_titleContainer__AeYhh .ProfileMain_titleInnerContainer__3xjS7 h3 {
  font-size: 24px;
  font-weight: 600;
}
.ProfileMain_titleContainer__AeYhh .ProfileMain_buttonsContainer__Zvwp4 {
  display: flex;
  gap: 12px;
}

.ProfileMain_innerContainer__D78hB {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.ProfileMain_additionalData__WBPxy {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.ProfileMain_item__PaM5i {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.ProfileMain_item__PaM5i .ProfileMain_label__mFgcv {
  color: #AFB1B6;
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/styles/Profile/ProfileMain.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,kBAAA;EACA,iBAAA;EACA,WAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,QAAA;EACA,mBAAA;AACJ;AACI;EACE,WAAA;EACA,YAAA;AACN;AAEI;EACE,eAAA;EACA,gBAAA;AAAN;AAIE;EACE,aAAA;EACA,SAAA;AAFJ;;AAMA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAHF;;AAMA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAHF;;AAMA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AAHF;AAKE;EACE,cAAA;EACA,eAAA;AAHJ","sourcesContent":[".spinnerWrapper {\n  width: 100%;\n}\n\n.wrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 32px;\n}\n\n.container {\n  display: flex;\n  flex-direction: column;\n  gap: 32px;\n  padding: 64px 32px;\n  max-width: 1612px;\n  width: 100%;\n}\n\n.username {\n  font-size: 20px;\n  font-weight: 600;\n}\n\n.titleContainer {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n\n  .titleInnerContainer {\n    display: flex;\n    flex-direction: row;\n    gap: 8px;\n    align-items: center;\n\n    img {\n      width: 28px;\n      height: 28px;\n    }\n\n    h3 {\n      font-size: 24px;\n      font-weight: 600;\n    }\n  }\n\n  .buttonsContainer {\n    display: flex;\n    gap: 12px;\n  }\n}\n\n.innerContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n\n.additionalData {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n}\n\n.item {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n\n  .label {\n    color: #AFB1B6;\n    font-size: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinnerWrapper": `ProfileMain_spinnerWrapper__7rTun`,
	"wrapper": `ProfileMain_wrapper__ltjX3`,
	"container": `ProfileMain_container__1A0V0`,
	"username": `ProfileMain_username__nBR-M`,
	"titleContainer": `ProfileMain_titleContainer__AeYhh`,
	"titleInnerContainer": `ProfileMain_titleInnerContainer__3xjS7`,
	"buttonsContainer": `ProfileMain_buttonsContainer__Zvwp4`,
	"innerContainer": `ProfileMain_innerContainer__D78hB`,
	"additionalData": `ProfileMain_additionalData__WBPxy`,
	"item": `ProfileMain_item__PaM5i`,
	"label": `ProfileMain_label__mFgcv`
};
export default ___CSS_LOADER_EXPORT___;
