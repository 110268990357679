import React, {useEffect, useState} from 'react'
import styles from '../../../styles/Settings/Members.module.scss'
import {Dropdown, Empty, MenuProps, message, Modal, Pagination, Spin} from "antd";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getParticipants, removeUserFromWorkspace} from "../../../redux/actions/ParticipantsActions";
import {CloseOutlined, DownOutlined} from "@ant-design/icons";
import {getAllGroups} from "../../../redux/actions/GroupsActions";

const MembersList = () => {

    const dispatch = useAppDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const {participants, error, success, isLoading} = useAppSelector(state => state.participantsReducer);
    const {currentWorkspace} = useAppSelector(state => state.workspacesReducer)

    const [isRemoveUserModal, setIsRemoveUserModal] = useState({open: false, id: null})

    useEffect(() => {
        if (error) {
            messageApi.open({
                type: 'error',
                content: error,
            });
        }

        if (success) {
            messageApi.open({
                type: 'success',
                content: success,
            });
        }
    }, [error, success])

    useEffect(() => {
        dispatch(getParticipants({workspaceId: currentWorkspace?.id, pageNumber: 1}))
    }, []);

    const membersActions: MenuProps['items'] = [
        {
            label: <span className={styles.actionItem}><CloseOutlined/>Удалить</span>,
            key: '1',
        }
    ];

    const onPageNumberChange = (value: any) => {
        dispatch(getParticipants({workspaceId: currentWorkspace?.id, pageNumber: value}))
    }

    const handleActions = (key: any, id: any) => {
        switch (key) {
            case '1':
                setIsRemoveUserModal({open: true, id: id})
                break;
            default:
                break;
        }
    }

    return (
        <>
            {contextHolder}
            <Spin spinning={isLoading} wrapperClassName={styles.tasksListSpinner}>
                <div className={styles.listContainer}>
                    <div className={styles.listInnerContainer}>
                        {participants?.items?.length !== 0 ?
                            <>
                                {participants?.items?.map((item: any) => {
                                    return (
                                        <div className={styles.listItem} key={item?.id}>
                                            <div className={styles.listItemInner}>
                                                <div className={styles.profileContainer}>
                                                    <div className={styles.profileInner}>
                                                        <span
                                                            className={styles.profileName}>{item?.user?.fullName?.last} {item?.user?.fullName?.first} {item?.user?.fullName?.middle}</span>
                                                        <span className={styles.profileRole}>-</span>
                                                    </div>
                                                </div>

                                                <div className={styles.infoContainer}>
                                                    <div className={styles.infoColumn}>
                                                        <span className={styles.infoColumnTitle}>Телефон</span>
                                                        <span>{item?.user?.phone?.number}</span>
                                                    </div>

                                                    <div className={styles.infoColumn}>
                                                        <span className={styles.infoColumnTitle}>Почта</span>
                                                        <span>{item?.user?.emailAddress?.address}</span>
                                                    </div>


                                                    <div className={styles.infoColumn}>
                                                        <span className={styles.infoColumnTitle}>Группа</span>
                                                        <span>{item?.group?.name}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={styles.listItemActions}>
                                                {/*@ts-ignore*/}
                                                <Dropdown menu={{
                                                    items: membersActions, onClick: ({key}) => {
                                                        handleActions(key, item?.userId)
                                                    }
                                                }}>
                                                    <a onClick={(e) => {
                                                        e.preventDefault()
                                                        e.stopPropagation()

                                                    }}>
                                                        Действия
                                                        <DownOutlined/>
                                                    </a>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    )
                                })
                                }
                                {
                                    participants?.totalCount > 8 &&
                                    <Pagination
                                        onChange={onPageNumberChange}
                                        defaultPageSize={8}
                                        current={participants?.pageNumber}
                                        total={participants?.totalCount}
                                    />
                                }
                            </>
                            :
                            <div>
                                <Empty description='Участники отсутствуют'/>
                            </div>
                        }
                    </div>
                </div>
            </Spin>

            <Modal
                title='Подтверждение операции'
                open={isRemoveUserModal.open}
                okText="Да"
                cancelText="Отмена"

                onOk={() => {
                    dispatch(removeUserFromWorkspace({
                        userId: isRemoveUserModal.id,
                        workspaceId: currentWorkspace?.id
                    }))
                        .then(() => dispatch(getParticipants({workspaceId: currentWorkspace?.id, pageNumber: 1})))
                        .then(() => setIsRemoveUserModal(() => ({open: false, id: null})))
                }}
                onCancel={() => setIsRemoveUserModal(() => ({open: false, id: null}))}
            >
                Вы уверены, что хотите удалить участника из рабочего пространства?
            </Modal>
        </>
    )
}

export default MembersList
