import React, {useEffect} from 'react'
import styles from "../../styles/MyJoinRequestsList.module.scss";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {revokeJoinRequest} from "../../redux/actions/WorkspaceActions";
import {message} from "antd";

const MyJoinRequestsList = () => {

    const [messageApi, contextHolder] = message.useMessage();

    const {joinRequests, error, success} = useAppSelector(state => state.workspacesReducer);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (error) {
            messageApi.open({
                type: 'error',
                content: error,
            });
        }
        if (success) {
            messageApi.open({
                type: 'success',
                content: success,
            });
        }
    }, [error, success])

    return (
        <>
            {contextHolder}
            {
                joinRequests?.filter((item: any) => item?.processedStatus === null).length !== 0 &&
                <>
                    <div className={styles.container}>
                        <h2>Мои запросы на вступление</h2>


                        <div className={styles.itemsContainer}>
                            {
                                joinRequests.map((req: any) => {
                                    if (req?.processedStatus === null) {
                                        return (
                                            <div key={req?.id} className={styles.itemContainer}>
                                                <div className={styles.itemInnerContainer}>
                                                    <img src="/icon_sm_1.svg" alt="icon_sm_1"/>
                                                    <span>{req?.workspace?.name}</span>
                                                </div>

                                                <button onClick={(e) => {
                                                    dispatch(revokeJoinRequest(req?.id))
                                                }
                                                }>Отменить запрос
                                                </button>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>

                </>
            }

        </>
    )
}

export default MyJoinRequestsList
