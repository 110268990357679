import React, {useEffect} from 'react'
import {Spin} from "antd";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {authSlice} from "../../redux/reducers/authSlice";
import {setCredentials} from "../../redux/actions/AuthActions";

const SignInCallback = () => {

    const [searchParams] = useSearchParams();
    const {user, isLoading, error} = useAppSelector(state => state.authReducer)
    const dispatch = useAppDispatch();
    const navigate = useNavigate()

    useEffect(() => {
        if (user?.access_token) {
            navigate('/selectWorkspace')
        }
    }, [user])

    useEffect(() => {
        if (error) {
            navigate('/login')
        }
    }, [error])

    useEffect(() => {
        const code = searchParams.get("code")
        if (code) {
            dispatch(setCredentials(code))
        }
    }, [])
    return (
        <Spin spinning={isLoading} fullscreen />
    )
}

export default SignInCallback
