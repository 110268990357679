// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginPage_container__-K1bI {
  display: flex;
  justify-content: center;
  align-items: center;
}
.LoginPage_container__-K1bI .LoginPage_logo__P1HwV {
  margin-bottom: 32px;
}
.LoginPage_container__-K1bI h1 {
  margin-bottom: 42px;
  font-weight: 600;
}
.LoginPage_container__-K1bI .LoginPage_containerInner__foaO2 {
  width: 440px;
}
.LoginPage_container__-K1bI .LoginPage_loginButton__9v627 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.LoginPage_checkboxContainer__ZXnIF {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 18px;
}
.LoginPage_checkboxContainer__ZXnIF a {
  line-height: 24px;
}
.LoginPage_checkboxContainer__ZXnIF .LoginPage_checkbox__OcSHJ {
  width: 20px !important;
  height: 20px !important;
}

.LoginPage_registrationContainer__pc3XP {
  display: flex;
  gap: 4px;
  justify-content: center;
  margin-bottom: 32px;
}
.LoginPage_registrationContainer__pc3XP span {
  color: var(--text-gray);
}

@media screen and (max-width: 1000px) {
  .LoginPage_containerInner__foaO2 {
    width: 300px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/Auth/LoginPage.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;AACI;EACI,mBAAA;AACR;AAEI;EACI,mBAAA;EACA,gBAAA;AAAR;AAGI;EACI,YAAA;AADR;AAII;EACI,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAFR;;AAMA;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;AAHJ;AAKI;EACI,iBAAA;AAHR;AAMI;EACI,sBAAA;EACA,uBAAA;AAJR;;AAQA;EACI,aAAA;EACA,QAAA;EACA,uBAAA;EACA,mBAAA;AALJ;AAOI;EACI,uBAAA;AALR;;AAUA;EACI;IACI,uBAAA;EAPN;AACF","sourcesContent":[".container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    .logo {\n        margin-bottom: 32px;\n    }\n\n    h1 {\n        margin-bottom: 42px;\n        font-weight: 600;\n    }\n\n    .containerInner {\n        width: 440px;\n    }\n\n    .loginButton {\n        width: 100%;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n    }\n}\n\n.checkboxContainer {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    margin-bottom: 18px;\n\n    a {\n        line-height: 24px;\n    }\n\n    .checkbox {\n        width: 20px!important;\n        height: 20px!important;\n    }\n}\n\n.registrationContainer {\n    display: flex;\n    gap: 4px;\n    justify-content: center;\n    margin-bottom: 32px;\n\n    span {\n        color: var(--text-gray);\n    }\n}\n\n\n@media screen and (max-width: 1000px) {\n    .containerInner {\n        width: 300px!important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `LoginPage_container__-K1bI`,
	"logo": `LoginPage_logo__P1HwV`,
	"containerInner": `LoginPage_containerInner__foaO2`,
	"loginButton": `LoginPage_loginButton__9v627`,
	"checkboxContainer": `LoginPage_checkboxContainer__ZXnIF`,
	"checkbox": `LoginPage_checkbox__OcSHJ`,
	"registrationContainer": `LoginPage_registrationContainer__pc3XP`
};
export default ___CSS_LOADER_EXPORT___;
