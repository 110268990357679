import {createSlice} from "@reduxjs/toolkit";

interface StagesSlice {
    stages: any;
    metrics: any;
    isLoading: boolean;
    error: any;
    success: any;
}

const initialState: StagesSlice = {
    stages: [],
    metrics: [],
    isLoading: false,
    error: null,
    success: null
}

export const stagesSlice = createSlice({
    name: "stages",
    initialState,
    reducers: {
        isLoading: (state) => {
            state.isLoading = true
            state.error = null
            state.success = null
        },
        isLoadingFinish: (state) => {
          state.isLoading = false
        },
        isFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload
        },
        isSuccess: (state, action) => {
            state.isLoading = false
            state.success = action.payload
        },
        getStages: (state, action) => {
            state.isLoading = false
            state.stages = action.payload
        },
        getMetrics: (state, action) => {
            state.isLoading = false
            state.metrics = action.payload
        }
    }
})

export default stagesSlice.reducer
