import React, {useEffect} from 'react'
import styles from '../../styles/Tasks/TasksMain.module.scss'
import {Button, DatePicker, Form, Select} from "antd";
import {IParticipant} from "../models/IParticipant";
import {useForm} from "antd/es/form/Form";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {isEmpty} from "../../helpers";
import {getAllTasks} from "../../redux/actions/TasksActions";
import {getParticipants, getParticipantsList} from "../../redux/actions/ParticipantsActions";

const TasksFilter = ({...props}) => {

    const [filterForm] = useForm()
    const dispatch = useAppDispatch()

    const {currentWorkspace} = useAppSelector(state => state.workspacesReducer)
    const {participants} = useAppSelector(state => state.participantsReducer)

    useEffect(() => {
        dispatch(getParticipantsList({workspaceId: currentWorkspace?.id}))
    }, []);

    const onFilterSearch = (values: any) => {
        const filterParams = Object.fromEntries(Object.entries(values)
            .filter(([_, v]) => v !== undefined && v !== null)
        )
        // @ts-ignore
        if (!isEmpty(filterParams)) {
            const str = Object.fromEntries(Object.entries(filterParams)
                // @ts-ignore
                .map(([_, v]) => {
                    if (_.includes('Utc')) {
                        // @ts-ignore
                        return [_, v.toISOString()]
                    }
                    // @ts-ignore
                    else return [_, v.toString()]
                })
            )
            props.onFilterChange(str)
        }
    }

    const onFilterFormReset = () => {
        filterForm.resetFields()
        props.onFilterChange({})
        dispatch(getAllTasks({
            workspaceId: currentWorkspace?.id,
            status: 0,
            pageNumber: 1,
            pageSize: props.currentPageSize
        }))

        props.onPageChange(1)
        props.onTabChange(0)
    }


    return (
        <div className={styles.filterContainer + ` ${!props.showFilter ? styles.filterActive : ''}`}>
            <span className={styles.filterTitle}>Фильтры</span>

            <Form
                form={filterForm}
                layout='vertical'
                autoComplete='off'
                onFinish={(values) => onFilterSearch(values)}
                className={styles.filterForm}
            >
                <Form.Item
                    label={<span className='inputLabel'>Ответственный</span>}
                    name="responsibleUserId"
                >
                    <Select
                        showSearch
                        allowClear
                        optionFilterProp="label"
                        options={participants?.items?.map((item: IParticipant) => (
                        {
                            value: item.userId,
                            label: `${item?.user?.fullName?.last} ${item?.user?.fullName?.first}`
                        }))}
                        filterOption={(input, option) =>
                            //@ts-ignore
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                    />
                </Form.Item>

                <Form.Item
                    label={<span className='inputLabel'>Дата закрытия от</span>}
                    name="deadlineFromUtc"
                >
                    <DatePicker showTime style={{width: "100%"}} allowClear={false}/>
                </Form.Item>

                <Form.Item
                    label={<span className='inputLabel'>Дата закрытия до</span>}
                    name="deadlineToUtc"
                >
                    <DatePicker showTime style={{width: "100%"}} allowClear={false}/>
                </Form.Item>

                <div className={styles.formRow}>
                    <Form.Item>
                        <Button
                            disabled={isEmpty(props.filterData)}
                            onClick={() => onFilterFormReset()}
                        >
                            Сбросить
                        </Button>
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className={styles.loginButton}
                        >
                            Применить
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </div>
    )
}

export default TasksFilter
