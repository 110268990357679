import React from 'react'
import {Outlet} from "react-router-dom";
import {ConfigProvider} from "antd";
import Header from "./Header";
import styles from '../../styles/MainLayout.module.scss'

const MainLayout = () => {
    return (
        <ConfigProvider
            theme={{
                token: {
                    fontFamily: "Montserrat"
                }
            }}
        >
            <div className={styles.container}>
                <Header/>
                <Outlet />
            </div>
        </ConfigProvider>
    )
}

export default MainLayout
